import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import Inputs from "../../../components/forms/Inputs";
import { InputHandler } from "../../../services/EventHandlers";
import { useAxios } from "../../../contexts/AxiosContext";  
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useToast } from "../../../contexts/ToastContext";
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';

const SuperAdminPromotions: React.FC<any> = () => {
  const {showToast} = useToast();
  const axiosService = useAxios();
  const [visible, setVisible] = useState(false);
  const durationOptions:any = [
    {value: 'once', label: 'Once'},
    {value: 'forever', label: 'Forever'},
    {value: 'repeating', label: 'Repeating'}
  ];
  const [productOptions, setProductOptions] = useState<any[]>([]);
  const [coupons, setCoupons] = useState<any[]>([]);
  const [internalCoupons, setInternalCoupons] = useState<any[]>([]);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [nodes, setNodes] = useState([]);
  const defaultPromoValue = {
    name: 'New Promotion' + (new Date).toLocaleString(),
    amount: 1,
    amount_type: '14 Days Trial Period',
    validity: null,
    redeem_by: null,
    coupon_id: '',
    code: '',
    max_redemptions: 1,
    duration: 'once',
    applies_to: null,
    notify_users: false,
    email_template: ''
  };
  const [promoDetails, setPromoDetails] = useState<any>(defaultPromoValue);
  const submitPromotion = () => {
    var copyDetails = JSON.parse(JSON.stringify(promoDetails));
    copyDetails.redeem_by = moment(promoDetails.redeem_by).format('YYYY-MM-DD');

    axiosService.api.post('/api/superadmin/promotion/create', copyDetails)
      .then((response:any) => {
        if(response.data.status){
          setRefreshTable(true);

          showToast({
            severity: 'success',
            summary: 'Created!',
            detail: response.data.message
          });

          setVisible(false);
          setPromoDetails(defaultPromoValue);
        }
      }).catch((e:any) => {
        console.log();
        showToast({
          severity: 'error',
          summary: 'Existing Promo!',
          detail: e.response.data.message
        });
      });
  };

  const initStripePrices = () => {
    axiosService.api.get('/api/stripe/list-products')
      .then((response:any) => {
        const products = response.data.products.data;

        if(products != null){
          setProductOptions(products.map((item:any) => ({value: item.id, label: item.name})));
        }
      });
  };

  const confirmDeleteCoupon = (couponId:string) => {
    confirmDialog({
        message: 'Are you sure you want to delete this coupon?',
        header: 'Delete Coupon',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'bg-purple rounded-lg',
        acceptLabel: 'Proceed',
        accept: () => deleteCoupon(couponId)
    });
  };

  const deleteCoupon = (couponId:string) => {
    showToast({
      severity: 'warn',
      summary: 'Processing!',
      detail: 'Waiting for coupon to be deleted.'
    });

    axiosService.api.delete(`/api/stripe/delete-coupon/${couponId}`)
      .then((response:any) => {
        if(response.data.status){
          setRefreshTable(true);

          showToast({
            severity: 'success',
            summary: 'Deleted!',
            detail: response.data.message
          });
        }
      });
  };

  const initCoupons = () => {
    axiosService.api.get('/api/stripe/list-coupons')
      .then((response:any) => {
        if(response.data.coupons){
          let mappedCoupons = response.data.coupons.data.map((item:any) => ({
            key: item.id,
            data: {
              coupon_id: item.id,
              name: item.name,
              amount_off: item.amount_off * 0.01,
              percent_off: item.percent_off,
              max_redemptions: item.max_redemptions,
              times_redeemed: item.times_redeemed,
              redeem_by: item.redeem_by,
              actions: (
                <>
                  <div className="flex gap-3">
                    <i className="pi pi-trash" onClick={() => (confirmDeleteCoupon(item.id))} style={{ color: 'red' }}></i>
                    <i className="pi pi-bell" onClick={undefined} style={{ color: 'slateblue' }}></i>
                  </div>
                </>
              )
            }
          }));
          setCoupons(mappedCoupons);
        }
      });
  };

  const deleteInternalCoupon = (id:any) => {
    axiosService.api.delete(`/api/superadmin/internal-coupon/delete/${id}`).then((response:any) => {
      showToast({
        severity: 'success',
        summary: 'Deleted!',
        detail: response.data.message
      });

      initInternalPromos();
    })
  };

  const confirmDeleteInternalCoupon = (id:any) => {
    confirmDialog({
      message: 'Are you sure you want to delete this coupon?',
      header: 'Delete Coupon',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'bg-purple rounded-lg',
      acceptLabel: 'Proceed',
      accept: () => deleteInternalCoupon(id)
    });
  };

  const initInternalPromos = () => {
    axiosService.api.get('/api/superadmin/promotions/internal')
      .then((response:any) => {
        console.log("response::", response.data.promotions);

        let mappedCoupons = response.data.promotions.map((item:any) => ({
          key: item.id,
          data: {
            code: item.code,
            name: item.name,
            amount: item.amount,
            amount_type: item.amount_type,
            max_redemptions: item.max_redemptions,
            times_redeemed: item.times_redeemed,
            redeem_by: item.redeem_by,
            actions: (
              <>
                <div className="flex gap-3">
                  <i className="pi pi-trash" onClick={() => confirmDeleteInternalCoupon(item.id)} style={{ color: 'red' }}></i>
                  {/* <i className="pi pi-bell" onClick={undefined} style={{ color: 'slateblue' }}></i> */}
                </div>
              </>
            )
          }
        }));
        setInternalCoupons(mappedCoupons);
        console.log("response::initInternalPromos::", response.data.promotions);
      });
  }

  useEffect(() => {
    if(refreshTable){
      initCoupons();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    initStripePrices();
    initCoupons();
    initInternalPromos();
  }, []);

  return (
    <>
      <ConfirmDialog />
      <Dialog header="Create Promotion" visible={visible} onHide={() => setVisible(false)}
        style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="col-span-2">
            <Inputs value={promoDetails.name} onChange={(e:any) => InputHandler(setPromoDetails, 'name')(e)}  label={"Promotion Name"} type={"text"} />
          </div>
          <div>
            <div>
              <Inputs value={promoDetails.amount} onChange={(e:any) => InputHandler(setPromoDetails, 'amount')(e)} label={"Amount"} type={"text"} />
            </div>
            <div className="flex gap-3">
                <div>
                  <Inputs 
                    dataId={'14 Days Trial Period'} 
                    value={promoDetails.amount_type} 
                    onChange={(e:any) => (setPromoDetails((prevValues:any) => ({...prevValues, amount_type: e.value})))} 
                    label={'14 Days Trial Period'} 
                    type={"radio"} 
                  />
                </div>
                <div>
                  <Inputs 
                    dataId={'fixed'} 
                    value={promoDetails.amount_type} 
                    onChange={(e:any) => (setPromoDetails((prevValues:any) => ({...prevValues, amount_type: e.value})))} 
                    label={'fixed'} 
                    type={"radio"} 
                  />
                </div>
                <div>
                  <Inputs 
                    dataId={'percentage'} 
                    value={promoDetails.amount_type} 
                    onChange={(e:any) => (setPromoDetails((prevValues:any) => ({...prevValues, amount_type: e.value})))} 
                    label={'percentage'} 
                    type={"radio"} 
                  />
                </div>
            </div>
          </div>
          <div>
            <Inputs 
              value={promoDetails.redeem_by} 
              onChange={(e:any) => setPromoDetails((prevValues:any) => ({
                ...prevValues,
                redeem_by: e.value
              }))} 
              label={"Redeem By"} 
              type={"calendar"} />
          </div>
          <div>
            <Inputs value={promoDetails.coupon_id} disabled={promoDetails.amount_type == "14 Days Trial Period" ? true : false} onChange={(e:any) => InputHandler(setPromoDetails, 'coupon_id')(e)} label={"Coupon ID"} type={"text"} />
          </div>
          <div>
            <Inputs value={promoDetails.code} onChange={(e:any) => InputHandler(setPromoDetails, 'code')(e)} label={"Code"} type={"text"} />
          </div>
          <div>
            <Inputs value={promoDetails.max_redemptions} onChange={(e:any) => InputHandler(setPromoDetails, 'max_redemptions')(e)} label={"Max Redemptions"} type={"number"} />
          </div>
          <div>
            <Inputs value={promoDetails.duration} onChange={(e:any) => setPromoDetails(((prevValues:any) => ({...prevValues, duration: e.value})))} options={durationOptions} label={"Duration"} type={"dropdown"} />
          </div>
          <div>
            <Inputs value={promoDetails.applies_to}
             onChange={(e:any) => {
              setPromoDetails((prevValues:any) => ({
                ...prevValues,
                applies_to: e.value
              }));
             }} 
              options={productOptions} label={"Applies To"} type={"multiselect"} />
          </div>
          <div className="col-span-2">
            <Inputs value={promoDetails.notify_users} onChange={undefined}  label={"Notify Users"} type={"checkbox"} />
          </div>
          {/* <div>
            <Inputs value={promoDetails.coupon_id} onChange={(e:any) => InputHandler(setPromoDetails, 'coupon_id')(e)}  label={"Coupon ID"} type={"text"} />
          </div> */}
          <div className="col-span-2 mt-10 flex justify-center">
            <Button label="Create Promotion" 
              onClick={submitPromotion} 
              className="bg-purple rounded-lg border-purple" 
            />
          </div>
        </div>
      </Dialog>
      <div className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Promotions</h1>
          <Button onClick={() => setVisible(true)} label="Create Promotion" icon="pi pi-plus" className="bg-purple rounded-lg border-purple ring-0" />
        </div>

      </div>
      <div className="panel bg-white rounded-lg p-5 mt-5">
        <TabView>
          <TabPanel header="Stripe Coupons">
            <TreeTable value={coupons} paginator rows={25} rowsPerPageOptions={[25, 50, 75, 100]} tableStyle={{ minWidth: '50rem' }}>
              <Column field="coupon_id" header="Coupon ID"></Column>
              <Column field="name" header="Name"></Column>
              <Column field="amount_off" header="Amount Off"></Column>
              <Column field="percent_off" header="Percent Off"></Column>
              <Column field="max_redemptions" header="Max Redemptions"></Column>
              <Column field="times_redeemed" header="Times Redeemed"></Column>
              <Column field="redeem_by" header="Redeem By"></Column>
              <Column field="actions" header="Actions"></Column>
            </TreeTable>
          </TabPanel>
          <TabPanel header="Internal Coupons">
            <TreeTable 
              value={internalCoupons.map(coupon => ({ ...coupon, key: coupon.id }))} // Ensure each row has a unique key
              paginator 
              rows={25} 
              rowsPerPageOptions={[25, 50, 75, 100]} 
              tableStyle={{ minWidth: '50rem' }}
            >
              {/* Hidden Column for ID */}
              <Column field="id" header="ID" style={{ display: 'none' }} /> 
              <Column field="code" header="Code"></Column>
              <Column field="name" header="Name"></Column>
              <Column field="amount" header="Amount"></Column>
              <Column field="amount_type" header="Type"></Column>
              <Column field="max_redemptions" header="Max Redemptions"></Column>
              <Column field="redeem_by" header="Redeem By"></Column>
              <Column field="actions" header="Actions"></Column>
            </TreeTable>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default SuperAdminPromotions;