import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import React, { useEffect, useState } from "react"
import ComposeMail from "../../../pages/mailbox/ComposeMail"
import { useAxios } from "../../../contexts/AxiosContext"

interface Props {
  visible: boolean
  onHide?: any
  data?: any
  setData?: any
  callback?: any
}

const SendMailModal: React.FC<Props> = ({ 
  visible = false, onHide, data, setData, callback = null
}) => { 

  const axiosService = useAxios();


  const footerContent = () => {
    return null;
    // return <div >
    //   <Button label="Cancel" onClick={onHide} className="p-button-text text-red-500" />
    //   <Button label={type == "add" ? "Create Agent" : "Save Agent"} className="bg-purple rounded-lg border-purple ring-0" onClick={onSubmit} autoFocus />
    // </div>
  }

  const [mailboxes, setMailboxes] = useState<any>([]);
  const getMailboxes = () => {
    console.log(data);
    const newMailbox = { id: '2147483647', email: 'SectorSift (Default)' };
    setMailboxes([newMailbox]);
    axiosService.api.get('/api/mailboxes/all')
    .then((response:any) => {
      const modifiedData = response.data.map((item: any) => ({
        ...item,
        email: `${item.email} (${item.type})`
      }));
      
      setMailboxes((prevMailboxes: any) => [...modifiedData, ...prevMailboxes]);
      })
    .catch((error:any) => {
      console.log('Error fetching data:', error);
    });
  }

  useEffect(() => {
    getMailboxes();
  }, []);

  return(
    <Dialog className="lg:w-1/3 w-[95%]" header="Compose Mail" draggable={false} resizable={false} visible={visible} onHide={onHide} footer={footerContent}>
      <ComposeMail mailboxes={mailboxes} data={data} callback={callback}/> 
    </Dialog>
  );
}


export default SendMailModal