import React, { useEffect } from 'react';
import AppRouter from "./app/router/AppRouter";
import { useAuth } from "./app/contexts/AuthContext"; // Import the useAuth hook
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-phone-number-input/style.css'
import { PrimeReactProvider } from 'primereact/api';
import { twMerge } from 'tailwind-merge';
import { ToastProvider } from './app/contexts/ToastContext';

function App() {
    
  // Use the authentication context
  const { state, dispatch } = useAuth();

  // Handlers for login and logout
  const handleLogin = () => {
    dispatch({ type: 'login' });
  };

  const handleLogout = () => {
    dispatch({ type: 'logout' });
  };
  useEffect(() => {
  // Ybug script
    const ybugScript = document.createElement('script');
    ybugScript.type = 'text/javascript';
    ybugScript.async = true;
    ybugScript.src = 'https://widget.ybug.io/button/8z60k7wwxfyk6yd9cqz4.js';
    document.body.appendChild(ybugScript);

    // Google Tag Manager script
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5JG99ZW5');
    `;
    document.head.appendChild(gtmScript);

    // Google Tag Manager noscript
    const gtmNoscript = document.createElement('noscript');
    gtmNoscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5JG99ZW5"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(gtmNoscript);
    
    console.log('JavaScript code executed');
  }, []);

  
  return (
    <PrimeReactProvider value={{ ripple: true, unstyled: false, pt: {}, ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge } }}>
      <ToastProvider>
        <AppRouter />
      </ToastProvider>
    </PrimeReactProvider>
  );
}

export default App;
