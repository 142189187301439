import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import SendMailModal from "../mailbox/SendMailModal";
import { useToast } from "../../../contexts/ToastContext";

interface Props {
  visible: boolean;
  onHide?: any;
  data: any;
  setData: any;
  onSubmit: any;
  callback?: any
  isSubmitting?:any
}

const LeadModal: React.FC<Props> = ({ 
  visible = false, onHide, data, setData, onSubmit, callback = null, isSubmitting
}) => { 
  const { showToast } = useToast();
  const footerContent = () => {
    return <div >
      <Button label="Cancel" disabled={(isSubmitting) ? true : false} onClick={onHide} className="p-button-text text-red-500" />
      <Button label="Update Lead" icon={(isSubmitting) ? "pi pi-spin pi-spinner" : null} disabled={(isSubmitting) ? true : false} iconPos="right" className="bg-purple rounded-lg border-purple ring-0" onClick={onSubmit} autoFocus />
    </div>
  }
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleMailToClient = (type: string) => {
    let subject = "";
    let body = "";
    let to = data.main_contact && data.main_contact.email || '';
    
    if(to === ''){
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'This lead doesn\'t have a Main Contact email address.'
      });
    }

    if (type === "first") {
      subject = data.first_email_subject;
      body = data.first_email_template;
    }

    if (type === "followup") {
      subject = data.follow_up_email_subject;
      body = data.follow_up_email_template;
    }

    const mailtoLink = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const sendmail = setTimeout(() => {
      window.open(mailtoLink, '_blank');
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const [mailModalData, setMailModalData] = useState<any>();
  const [mailModalShow, setMailModalShow] = useState(false); 
  const handleSendMail = (type: string) => {
    let subject = "";
    let body = "";
    let to = data.main_contact && data.main_contact.email || '';
    let other_contacts = data.contacts.filter((contact: any) => contact.unlocked == 1 && contact.email != data.main_contact.email).map((contact: any) => contact.email);
    const updatedTo = [
      to, 
      ...other_contacts
    ];
    if(to === ''){
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'This lead doesn\'t have a Main Contact email address.'
      });
    }
    
    if (type === "first") {
      subject = data.first_email_subject;
      body = data.first_email_template;
    }

    if (type === "followup") {
      subject = data.follow_up_email_subject;
      body = data.follow_up_email_template;
    }

    setMailModalData({
      subject: subject,
      body: body,
      to: updatedTo,
      lead_id: data.id,
      type: type
    });
    const sendmail = setTimeout(() => {
      setMailModalShow(true);
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const handleCallback = () => {
    setMailModalShow(false);
    if(callback) {
      callback();
    }
  }

  return(<>
    <Dialog className="lg:w-1/2 w-[95%]" header={"Update Lead - " + data.company_name} draggable={false} resizable={false} visible={visible} onHide={onHide} footer={footerContent}>
      <form className="mb-5">
        <TabView pt={{ nav: { className: 'bg-purple-light rounded-tr-lg rounded-tl-lg' } }} activeIndex={activeIndex} onTabChange={(e: any) => { setActiveIndex(e.index) }}>
          <TabPanel header="Company Details" pt={{ headerTitle: {className: (activeIndex == 0 ? 'text-purple' : 'text-label')}, headerAction: { className: `rounded-tl-lg font-normal border-purple-highlight ${activeIndex == 0 ? 'border-b-purple' : 'bg-purple-light'}` } }}>
            <div className="grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <label htmlFor="company_name">Company Name</label>
                <InputText value={data && data.company_name} onChange={setData} id="company_name" name="company_name" className="rounded-lg w-full" />
              </div> 
              <div className="flex flex-col gap-2">
                <label htmlFor="website">Website URL</label>
                <InputText value={data && data.website} onChange={setData} id="website" name="website" className="rounded-lg w-full" />
              </div> 
              <div className="flex flex-col gap-2 lg:col-span-2">
                <label htmlFor="biography">Biography</label>
                <InputTextarea rows={5} value={data && data.biography} onChange={setData} id="biography" name="biography" className="rounded-lg w-full" />
              </div> 
            </div>
          </TabPanel>
          <TabPanel header="First Email Template" pt={{ headerTitle: {className: (activeIndex == 1 ? 'text-purple' : 'text-label') }, headerAction: { className: `font-normal border-purple-highlight ${activeIndex == 1 ? 'border-b-purple' : 'bg-purple-light'}` } }}>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex flex-col gap-2">
                <label htmlFor="first_email_subject">First Email Subject</label>
                <InputText value={data && data.first_email_subject} onChange={setData} id="first_email_subject" name="first_email_subject" className="rounded-lg w-full" />
              </div> 
              
              <div className="flex flex-col gap-2">
                <label htmlFor="first_email_template">First Email Template</label>
                <InputTextarea value={data && data.first_email_template} name="first_email_template" onChange={setData} className="rounded-lg w-full" style={{ height: '200px' }} />
              </div> 

              <div className="flex gap-2 justify-end">
                <Button type="button" label="Use in Mail Client" icon="pi pi-envelope" className="border-purple bg-transparent text-purple hover:bg-purple hover:text-white transition-all rounded-lg" onClick={() => handleMailToClient('first')} autoFocus />
                <Button type="button" label="Use in Mailbox" className="bg-purple text-white rounded-lg" icon="pi pi-envelope" onClick={() => handleSendMail('first')} tooltip="Send Mail"></Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Follow-up Email Template" pt={{ headerTitle: {className: (activeIndex == 2 ? 'text-purple' : 'text-label')}, headerAction: { className: `font-normal border-purple-highlight ${activeIndex == 2 ? 'border-b-purple' : 'bg-purple-light'}` } }}>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex flex-col gap-2">
                <label htmlFor="follow_up_email_subject">Follow-up Email Subject</label>
                <InputText value={data && data.follow_up_email_subject} onChange={setData} id="follow_up_email_subject" name="follow_up_email_subject" className="rounded-lg w-full" />
              </div> 
              
              <div className="flex flex-col gap-2">
                <label htmlFor="follow_up_email_template">Follow-up Email Template</label>
                <InputTextarea value={data && data.follow_up_email_template} name="follow_up_email_template" onChange={setData} className="rounded-lg w-full" style={{ height: '200px' }} />
              </div> 

              <div className="flex gap-2 justify-end">
                <Button type="button" label="Use in Mail Client" icon="pi pi-envelope transition-all" className="border-purple bg-transparent text-purple hover:bg-purple hover:text-white transition-all rounded-lg" onClick={() => handleMailToClient('first')} autoFocus />
                <Button type="button" label="Use in Mailbox" className="bg-purple text-white rounded-lg" icon="pi pi-envelope" onClick={() => handleSendMail('followup')}></Button>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </form>
    </Dialog>
    <SendMailModal visible={mailModalShow} data={mailModalData} onHide={() => setMailModalShow(false)} callback={handleCallback}/>
  </>);
}

export default LeadModal;