import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useRef, useState } from "react";
import { AmericanExpress, Mastercard, Paypal, UnionPay, Visa } from "../../../components/Icons/ImgPath";
import { Button } from "primereact/button";
import IconMap from "../../../components/Icons/IconMaps";
import BillingPaymentModal from "../../../components/modals/settings/billings/BillingPaymentModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAxios } from "../../../contexts/AxiosContext";
import moment from "moment";
import { Skeleton } from "primereact/skeleton";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { useToast } from "../../../contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { classNames } from "primereact/utils";

const Billing: React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "pk_test_51OozSiA3fbpnwooicZTrPG0HMz2AFu6qYCJ0dSLLO3gCLgYCQ9SlxGdl1epNlRY0zjbzFcJWPYSKfJ3Sag157vY100274BSdqm");
  const [activeIndex, setActiveIndex] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState<any>();
  const [billingHistory, setBillingHistory] = useState<any>();
  const {showToast} = useToast();
  const navigate = useNavigate();
  const menu:any = useRef(null);
  const [pmID, setpmID] = useState(0);

  const confirmedCancel = (susbcriptionID:any, redirect?:any) => {
    return axiosService.api.get(`/api/subscription/cancel/${susbcriptionID}`)
      .then((response:any) => {
        showToast({
          severity: 'success',
          summary: 'Canceled Subscription!',
          detail: response.data.message
        });

        if(redirect){
          navigate(redirect);
        }else{
          setLoadingState(false);
          getSubscriptions();
        }
      }).catch((error:any) => {
        showToast({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message
        });
        setLoadingState(false);
      });;
  }

  const cancelSubscription = (currentSubscription:any) => {
    if(currentSubscription == null || currentSubscription.subscription_id == undefined || currentSubscription.subscription_id == null){
      return null;
    }
    confirmDialog({
      message: 'Are you sure you want to cancel your subscription?',
      header: 'Cancel Subscription',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => {
        setLoadingState(true);
        confirmedCancel(currentSubscription.subscription_id);
      }
    });
  };

  const upgradeSubscription = (currentSubscription:any) => {
    if(currentSubscription == null || currentSubscription.subscription_id == undefined || currentSubscription.subscription_id == null){
      return null;
    }
    confirmDialog({
      message: `We will cancel your current subscription while preserving your remaining credits. Afterwards, you will be redirected to the subscription page to select a new plan. Would you like to continue?`,
      header: 'Upgrade Subscription',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      style: {
        width: '50%',
      },
      accept: () => {
        setLoadingState(true);
        confirmedCancel(currentSubscription.subscription_id, '/subscription');
      }
    });
  }

  const address = (data: string, isFirst = 1) => {
    return !data ? '' : (isFirst === 1 ? ' ' : '') + data ;
  };

  const getCardBrand = (cardBrand: any) => {
    let brand;
    switch(cardBrand){
      case 'visa': 
        brand = Visa;
      break;
      case 'american_express': 
        brand = AmericanExpress;
      break;
      case 'mastercard': 
        brand = Mastercard;
      break;
      case 'unionpay': 
        brand = UnionPay;
      break;
      default:
        brand = Visa;
      break;
    }

    return brand;
  }

  const deletePaymentMethod:any = (pmID:any) => {
    axiosService.api.get(`/api/subscription/remove/payment-method/${pmID}`)
      .then((response:any) => {
        showToast({
          severity: 'success',
          summary: 'Payment Method Removed!',
          detail: response.data.message
        });
        getPaymentMethods();
        setLoadingState(false);
      }).catch((error:any) => {
        showToast({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message
        });
        setLoadingState(false);
      });
  };

  const items = [
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      className: 'text-red-500',
      command: () => {
        confirmDialog({
          message: 'Are you sure you want to remove this payment method?',
          header: 'Payment Method',
          icon: 'pi pi-exclamation-triangle',
          defaultFocus: 'accept',
          accept: () => {
            setLoadingState(true);
            deletePaymentMethod(pmID);
          }
        });
      }
    }
  ];

  const tabPanels: any = [
    {
      title: 'Payment Method',
      content: (
        <> 
          <p className="text-sm py-4">Add / Remove current method of payment.</p>
          <div className="grid lg:grid-cols-4 gap-5">
            {
              !paymentMethods && (
                <>
                  <Skeleton height="330px" className="rounded-lg"/>
                  <Skeleton height="330px" className="rounded-lg"/>                
                </>
              )
            }
            {
              paymentMethods && paymentMethods.length > 0 && paymentMethods.map((paymentMethod: any, index: any) => {
                return (
                  <div className="border rounded-lg flex flex-col gap-3 p-10" key={index}>
                    <div className="flex justify-between items-center">
                      <img src={getCardBrand(paymentMethod.card.brand)} alt={paymentMethod.card.brand} className="h-[25px]"/>
                      <i
                        className="pi pi-ellipsis-v"
                        onClick={(event) => {
                          menu.current.toggle(event)
                          setpmID(paymentMethod.id);
                        }}
                        style={{ cursor: 'pointer' }}
                      ></i>
                      <Menu model={items} popup ref={menu} id="popup_menu" />
                    </div>
                    <div>
                      <p className="text-3xl my-5">{paymentMethod.card.brand?.toUpperCase()} •••• {paymentMethod.card.last4} Exp {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</p>
                      <p className="text-sm">Expires {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year.toString().slice(-2)}</p>
                      <p className="text-sm">{address(paymentMethod.billing_details.address.line1) + address(paymentMethod.billing_details.address.line2) + address(paymentMethod.billing_details.address.city) + address(paymentMethod.billing_details.address.state) + address(paymentMethod.billing_details.address.country) }</p>
                      <p className="text-sm my-5">LAST USED: { moment.unix(paymentMethod.created).format("MM/DD/YYYY") }</p>
                      {
                        paymentMethod.is_default && <span className="rounded-lg bg-blue-500 px-4 py-2 text-white">Current Payment</span>
                      }
                    </div>
                    
                  </div>
                )
              })
            }
          
            <Button label="Add Payment Method" 
              className="ring-0 border-gray-200 bg-transparent text-label border-2 border-dashed rounded-lg flex gap-3 p-10 min-h-[330px] justify-center hover:border-blue-500 hover:text-blue-500" 
              icon="pi pi-plus"
              pt={{
                label: { className: 'flex items-center flex-col' }
              }}
              onClick={() => setVisible(true)}
            />
          </div>
        </>
      )
    },
    {
      title: 'Billing Information',
      content: (
        <> 
          <p className="text-sm py-4">View and manage your current payment setup</p>
          {
            !currentSubscription || currentSubscription.length < 1 ? 
              <div className="flex flex-col items-center justify-center">
                <Button label="Subscribe to a Plan" 
                  className="mb-4" 
                  icon="pi pi-verified"
                  iconPos="right"
                  style={{ backgroundColor: '#48c404', color: '#fff', borderColor: '#48c404'}}
                  onClick={() => navigate('/subscription')}
                />
                <p className="text-center">No subscriptions yet.</p>
              </div>
            : 
            (
              <>
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-xl">Plan Setup</h1>
                  <div className="flex items-center gap-3">
                    <Button className="bg-transparent border-none ring-0 text-label " onClick={(e:any) => cancelSubscription(currentSubscription)} label="Cancel Subscription" icon={IconMap('CancelBadge')}
                      pt={{
                        label: { className: 'text-sm ml-3 font-semibold' }
                      }}
                    />
                    <Button className="bg-gray-100 rounded-lg border-none ring-0 text-label" onClick={(e:any) => upgradeSubscription(currentSubscription)} label="Upgrade this Plan" icon={IconMap('CheckBadge')}
                      pt={{
                        label: { className: 'text-sm ml-3 font-[500]' }
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-5 relative">
                  <div className="flex my-5 gap-3 items-center">
                    <div className="relative w-10 h-10">
                      <div className="absolute inset-0 flex justify-center items-center">
                        <div className="w-10 h-10 rounded-full bg-[#E7E7E7]"></div>
                      </div>
                      <div className="absolute inset-0 flex justify-center items-center">
                        <div className={`doughnut-1`}></div>
                      </div>
                      <div className="absolute inset-0 flex justify-center items-center">
                        <div className={`w-4 h-4 rounded-full bg-white`}></div>
                      </div>
                    </div>
                    <h1 className="text-3xl font-bold">{currentSubscription && currentSubscription?.items && currentSubscription?.items[0].product.name}</h1>
                  </div>
                  <div className="grid lg:grid-cols-8 gap-4 border-b pb-5">
                    <div className="flex flex-col gap-2">
                      <label className="text-label text-sm font-bold">Monthly Price</label>
                      <p>${Number((currentSubscription && currentSubscription?.items && currentSubscription?.items[0].price_unit_amount) ? (currentSubscription?.items[0].price_unit_amount/100) : 0).toFixed(2)} + tax</p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-label text-sm font-bold">Next Payment</label>
                      <p>{currentSubscription && currentSubscription?.next_payment_date}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-label text-sm font-bold">Payment</label>
                      <div className="flex gap-3 items-center">
                        <img src={getCardBrand(currentSubscription && currentSubscription?.payment_method?.card?.brand)} alt={currentSubscription && currentSubscription?.payment_method?.card?.brand}/>
                        <p className="text-sm tracking-wider">{currentSubscription && currentSubscription?.payment_method?.card?.brand?.toUpperCase()} •••• {currentSubscription && currentSubscription?.payment_method?.card?.last4}</p>
                      </div>
                    </div>
                  </div>

                  <div className="grid lg:grid-cols-3 gap-4 pt-5">
                    <div className="rounded-lg border p-5 flex flex-col gap-5">
                      <div className="flex items-center justify-between">
                        <div className="flex gap-3 items-center">
                          <img src={getCardBrand(currentSubscription && currentSubscription?.payment_method?.card?.brand)} alt={currentSubscription && currentSubscription?.payment_method?.card?.brand}/>
                          <p className="text-sm tracking-[3px] font-bold">{currentSubscription && currentSubscription?.payment_method?.card?.brand?.toUpperCase()} •••• {currentSubscription && currentSubscription?.payment_method?.card?.last4}</p>
                        </div>
                        <p className="text-sm font-bold tracking-wide">Expires {currentSubscription && currentSubscription?.payment_method?.card?.exp_month}/{currentSubscription && currentSubscription?.payment_method?.card?.exp_year}</p>
                      </div>
                      <div className="flex flex-col gap-1 pb-4 border-b">
                        <h1 className="font-bold tracking-wide">{currentSubscription && currentSubscription?.payment_method?.billing_details?.name || ''}</h1>
                        <p className="text-xs">{
                            address(currentSubscription && currentSubscription?.payment_method?.billing_details && currentSubscription?.payment_method?.billing_details?.address.line1 || '') 
                          + address(currentSubscription && currentSubscription?.payment_method?.billing_details && currentSubscription?.payment_method?.billing_details?.address.line2  || '') 
                          + address(currentSubscription && currentSubscription?.payment_method?.billing_details && currentSubscription?.payment_method?.billing_details?.address.city  || '') 
                          + address(currentSubscription && currentSubscription?.payment_method?.billing_details && currentSubscription?.payment_method?.billing_details?.address.state  || '') 
                          + address(currentSubscription && currentSubscription?.payment_method?.billing_details && currentSubscription?.payment_method.billing_details.address.country  || '') }
                        </p>
                      </div>
                      <div className="flex justify-between items-center cursor-pointer">
                        <p className="text-blue-500 text-sm font-bold">Update Payment Method</p>
                        <i className="pi pi-chevron-right text-sm text-blue-500"></i>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </>
            )
          }
          
        </>
      )
    },
    {
      title: 'Billing History',
      content: (
        <> 
          <p className="text-sm py-4">Show records of your recent and past transactions</p>
          <div className="flex flex-col">

            {
              billingHistory && billingHistory.length > 0 && billingHistory.map((history: any, index: number) => {
                return (
                  <div key={index} className="border-t p-2 flex flex-col gap-1 pb-5">
                    <div className="flex justify-between items-center">
                      <h1 className="font-bold text-sm">{history.invoice_date}</h1>
                      <div className="flex my-2 gap-3 items-center px-5 py-1 bg-gray-100 rounded-xl">
                        <div className="relative w-5 h-5">
                          <div className="absolute inset-0 flex justify-center items-center">
                            <div className="w-5 h-5 rounded-full bg-[#E7E7E7]"></div>
                          </div>
                          <div className="absolute inset-0 flex justify-center items-center">
                            <div className={`doughnut-1`} style={{width: '1.25rem', height: '1.25rem'}}></div>
                          </div>
                          <div className="absolute inset-0 flex justify-center items-center">
                            <div className={`w-2 h-2 rounded-full bg-white`}></div>
                          </div>
                        </div>
                        <h1 className="font-bold">{history.products[0].product_name}</h1>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-4 gap-4">
                      <div className="flex flex-col gap-2">
                        <h1 className="font-bold text-label tracking-wide">Subscription Dating</h1>
                        <p>{history.subscription_dates}</p>
                      </div>
                      <div className="flex flex-col gap-2">
                        <h1 className="font-bold text-label tracking-wide">Transaction ID</h1>
                        <p>{history.transaction_id}</p>
                      </div>
                      <div className="flex flex-col gap-2">
                        <h1 className="font-bold text-label tracking-wide">Payment</h1>
                        {
                          history.payment_method ? 
                          <div className="flex gap-3">
                            <img src={getCardBrand(history?.payment_method?.card.brand)} alt={history?.payment_method?.card.brand}/>
                            <p className="tracking-[3px]">{history?.payment_method?.card.brand?.toUpperCase()} •••• {history?.payment_method?.card.last4}</p>
                          </div>
                          : 'N/A'
                        }
                        
                      </div>
                      <div className="flex flex-col gap-2">
                        <h1 className="font-bold text-label tracking-wide">Payment (tax included)</h1>
                        <p>$ {Number(history.payment).toFixed(2)}</p>
                      </div>
                    </div>
                  </div> 
                )
              })
            }
            {/* <div className="border-t p-2 flex flex-col gap-1 pb-5">
              <div className="flex justify-between items-center">
                <h1 className="font-bold text-sm">June 8, 2024</h1>
                <div className="flex my-2 gap-3 items-center px-5 py-1 bg-gray-100 rounded-xl">
                  <div className="relative w-5 h-5">
                    <div className="absolute inset-0 flex justify-center items-center">
                      <div className="w-5 h-5 rounded-full bg-[#E7E7E7]"></div>
                    </div>
                    <div className="absolute inset-0 flex justify-center items-center">
                      <div className={`doughnut-1`} style={{width: '1.25rem', height: '1.25rem'}}></div>
                    </div>
                    <div className="absolute inset-0 flex justify-center items-center">
                      <div className={`w-2 h-2 rounded-full bg-white`}></div>
                    </div>
                  </div>
                  <h1 className="font-bold">Starter</h1>
                </div>
              </div>
              <div className="grid lg:grid-cols-4 gap-4">
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Subscription Dating</h1>
                  <p>Jun 8, 2024 - Jul 8, 2024</p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Transaction ID</h1>
                  <p>VTS3247789</p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Payment</h1>
                  <div className="flex gap-3">
                    <img src={Paypal} alt="Paypal"/>
                    <p className="tracking-[3px]">a••••@s••••.ie</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Payment (tax included)</h1>
                  <p>$ 33.00</p>
                </div>
              </div>
            </div>
            <div className="border-t p-2 flex flex-col gap-1 pb-5">
              <div className="flex justify-between items-center">
                <h1 className="font-bold text-sm">June 8, 2024</h1>
                <div className="flex my-2 gap-3 items-center px-5 py-1 bg-gray-100 rounded-xl">
                  <div className="relative w-5 h-5">
                    <div className="absolute inset-0 flex justify-center items-center">
                      <div className="w-5 h-5 rounded-full bg-[#E7E7E7]"></div>
                    </div>
                    <div className="absolute inset-0 flex justify-center items-center">
                      <div className={`doughnut-1`} style={{width: '1.25rem', height: '1.25rem'}}></div>
                    </div>
                    <div className="absolute inset-0 flex justify-center items-center">
                      <div className={`w-2 h-2 rounded-full bg-white`}></div>
                    </div>
                  </div>
                  <h1 className="font-bold">Starter</h1>
                </div>
              </div>
              <div className="grid lg:grid-cols-4 gap-4">
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Subscription Dating</h1>
                  <p>Jun 8, 2024 - Jul 8, 2024</p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Transaction ID</h1>
                  <p>VTS3247789</p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Payment</h1>
                  <div className="flex gap-3">
                    <img src={Visa} alt="Visa"/>
                    <p className="tracking-[3px]">**** <span>3320</span></p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-label tracking-wide">Payment (tax included)</h1>
                  <p>$ 33.00</p>
                </div>
              </div>
            </div> */}
          </div>
        </>
      )
    },
  ];

  const handleSuccess = () => {
    getPaymentMethods();
    setVisible(false);
  }

  const getPaymentMethods = () => {
    axiosService.api.get('/api/payment-method').then((response: any) => {
      setPaymentMethods(response.data);
    }).catch((error: any) => {
      console.log(error);
    });
  }

  const getSubscriptions = () => {
    axiosService.api.get('/api/payment-method/subscriptions').then((response: any) => {
      setCurrentSubscription(response.data);
    }).catch((error: any) => {
      console.log(error);
    });
  }

  const getBillingHistory = () => {
    axiosService.api.get('/api/payment-method/billing-history').then((response: any) => {
      setBillingHistory(response.data);
    }).catch((error: any) => {
      console.log(error);
    });
  }

  useEffect(() => {
    getPaymentMethods();
    getSubscriptions();
    getBillingHistory();
  }, []);

  return (
    <>
      <ConfirmDialog />
      <div id="billing-settings" className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Billing</h1>
        </div>
      </div>
      <div className="panel relative bg-white rounded-lg p-5 min-h-[45rem]">
        <TabView pt={{ nav: { className: 'border-b-purple-highlight rounded-tr-lg rounded-tl-lg' } }} activeIndex={activeIndex} onTabChange={(e: any) => { setActiveIndex(e.index) }}>
          {
            tabPanels.map((e: any, index: number) => {
              return (
                <TabPanel key={index} header={e.title} pt={{ headerTitle: {className: 'font-normal ' + (activeIndex == index ? 'text-purple' : 'text-label')}, headerAction: { className: `${index == 0 ? 'rounded-tl-lg' : ''} border-purple-highlight ${activeIndex == index ? 'border-b-purple' : 'bg-white'}` } }}>
                  {e.content}
                </TabPanel>
              );
            })
          }

        </TabView>

        <Elements stripe={stripePromise}>
          <BillingPaymentModal visible={visible} onHide={() => setVisible(false)} onSuccess={handleSuccess} />
        </Elements>
      </div>
    </>
  )
}

export default Billing;