import { useEffect, useState } from "react";
import { useAxios } from "../../contexts/AxiosContext";
import { useLocation } from "react-router-dom";
import SuperLayout from "../../components/layout/SuperLayout";
import SuperAdminAccounts from "./pages/SuperAdminAccounts";
import SuperAdminPrompts from "./pages/SuperAdminPrompts";
import SuperAdminSandbox from "./pages/SuperAdminSandbox";
import SuperAdminPromotions from "./pages/SuperAdminPromotions";

const SuperAdminMain:React.FC<any> = ({ setLoadingState }) => {
  const axiosService = useAxios();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const currentPage = new URLSearchParams(location.search).get("page") || "";

  const renderPage = () => {
    switch (currentPage) {
      // case 'dashboard': 
      //   return <Dashboard axiosService={axiosService} setLoadingState={setLoadingState} />
      // case 'leads': 
      //   return <Leads axiosService={axiosService} setLoadingState={setLoadingState} />
      // case 'agents': 
      //   return <Agents axiosService={axiosService} setLoadingState={setLoadingState} />
      // case 'mailbox': 
      //   return <Mailbox axiosService={axiosService} setLoadingState={setLoadingState}  />
      // case 'emails': 
      //   return <Inbox axiosService={axiosService} setLoadingState={setLoadingState}  />
      case 'sandbox':
        return <SuperAdminSandbox axiosService={axiosService} setLoadingState={setLoadingState}  />;
      case 'superadmin-agents': 
        return <SuperAdminPrompts axiosService={axiosService} setLoadingState={setLoadingState}  /> // All about agents
      case 'superadmin-promotions':
        return <SuperAdminPromotions axiosService={axiosService} setLoadingState={setLoadingState} />
      default:
        return <SuperAdminAccounts axiosService={axiosService} setLoadingState={setLoadingState} />
        return (
          <div className="h-full w-full flex justify-center items-center">
            This service is still under development and will be available soon!
          </div>
        );
    }
  };

  useEffect(() => {
    setLoadingState(false);
  }, []);
  
  return (
    <>
       {isLoading && (
        <div className='absolute w-full h-full bg-gray-100 bg-opacity-80 z-[999] flex justify-center items-center'>
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      <SuperLayout children={renderPage()} axiosService={axiosService} />
    </>
  );
};

export default SuperAdminMain;