import { Button } from "primereact/button";
import IconMap from "../Icons/IconMaps";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";

interface Props {
  onSuccess?: any
  onError?: any
  label: any
  loading?: any
  scope?: any
  className?: any,
  setLoading?: any
}

const GoogleButton: React.FC<Props> = ({
  onSuccess, onError, label = "Gmail", loading = false, setLoading,
  scope = 'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly', className = 'border-2 border-purple text-purple bg-transparent font-bold flex gap-2 items-center'
}) => {

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      onSuccess(codeResponse);
    },
    onError: () => {
      onError();
      setLoading(false);
    },
    flow: 'auth-code',
    scope: scope
  });

  const handleClick = () => {
    setLoading(true);
    googleLogin();
  };

  return(
    <Button 
      loading={loading}
      className={className}
      icon={IconMap('FcGoogle')} 
      label={label} 
      onClick={handleClick}
    />
  )
}

export default GoogleButton;