import React, { useEffect, useState } from "react";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IconMap from "../../../components/Icons/IconMaps";
import Inputs from "../../../components/forms/Inputs";
import { Tooltip } from "primereact/tooltip";
import { InputHandler } from "../../../services/EventHandlers";
import { useAxios } from "../../../contexts/AxiosContext";
import { useToast } from "../../../contexts/ToastContext";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";

const CompanySettings: React.FC<any> = ({setLoadingState, originalCredInfo}) => {

  const axiosService = useAxios();
  const { showToast } = useToast();
  const [credInfo, setCredInfo] = useState({
    company_name: "",
    role: "",
    website: "",
    company_description: "",
    target_description: "",
    calendly_link: "",
    linkedin_url: "",
    profile_picture: "",
  });

  const handleUpdateCompany = async () =>{
    setLoadingState(true)
    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });
    const dataToSend: any = {
      company_name: credInfo.company_name,
      role: credInfo.role,
      website: credInfo.website,
      company_description: credInfo.company_description,
      target_description: credInfo.target_description,
      calendly_link: credInfo.calendly_link,
      linkedin_url: credInfo.linkedin_url,
    };
    const response = await axiosService.api.post('/api/company/save', dataToSend);
    setLoadingState(false);
    if (response.data.status) {
      showToast({
        severity: 'success',
        summary: 'Saved!',
        detail: response.data.message
      });
    } else {
      showToast({
        severity: 'error',
        summary: 'Error!',
        detail: response.data.message || 'Failed to update company information.'
      });
    }
  }

  const confirmSubmit = () => {
    confirmDialog({
      message: 'Are you sure you want to save your profile?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => {
        handleUpdateCompany()
      },
    });
  };

  const initCompany = () => {
    axiosService.api.get('/api/company/get')
      .then((response:any) => {

        // console.log(" company response::", response)
        if(response.data.company != null){
          setCredInfo({
            company_name: response.data.company.company_name,
            role: response.data.company.role,
            website: response.data.company.website,
            company_description: response.data.company.business_description,
            target_description: response.data.company.target_description,
            calendly_link: response.data.company.calendly_link,
            linkedin_url: response.data.company.linkedin_url,
            profile_picture: response.data.company.profile_picture
          });
        }
      });
  };

  const handleFileUpload = async (event: any) => {
    if (!event.target.files || event.target.files.length === 0) {
      showToast({
        severity: 'error',
        summary: 'No File Selected',
        detail: 'Please select a file to upload.',
      });
      return;
    }
  
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('profile_picture', file);
  
    try {
      showToast({
        severity: 'info',
        summary: 'Uploading',
        detail: 'Please wait...',
      });
  
      setLoadingState(true);
  
      const response = await axiosService.api.post('/api/company/upload-profile-picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.data.status) {
        showToast({
          severity: 'success',
          summary: 'Upload Successful!',
          detail: 'Company profile picture has been uploaded successfully.',
        });

        setCredInfo((prevValues: any) => ({
          ...prevValues,
          profile_picture: response.data.profile_picture_url,
        }));

        initCompany();
      } else {
        showToast({
          severity: 'error',
          summary: 'Upload Failed',
          detail: response.data.message || 'Failed to upload company profile picture.',
        });
      }
    } catch (error: any) {
      showToast({
        severity: 'error',
        summary: 'Upload Failed',
        detail: `${error.response.data.message}`,
      });
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    initCompany();
  }, [])
 
  return (
  <>
    <div id="profile-settings" className="flex flex-col gap-3">
      <div className="title flex justify-between items-center">
        <h1 className="text-2xl">Company Profile Settings</h1>
      </div>
    </div>
    <div className="panel relative bg-white rounded-lg m-5 p-5 min-h-[50rem]">
      <div className="flex flex-col items-center justify-center">
        <div className="mt-[5rem] p-5 min-h-[40rem] z-0 w-[75%] sm:w-full">
          <div className="flex justify-center items-center">
            <div className="flex flex-col md:flex-row items-center justify-center relative">
              <div className="flex items-center justify-center mt-[-40px] w-[130px] h-[130px] z-1 border-[10px] border-[#fff] rounded-[50%] shadow-md overflow-hidden">
                <Avatar pt={{image: {className: "object-contain"}}} color="white" image={credInfo.profile_picture} imageAlt="Company profile picture" icon="pi pi-user" size="xlarge" shape="square" style={{ backgroundColor: '#CDCECF', width:'130px', height:'130px' }} />
              </div>
              <div className="absolute bottom-[25px] right-[25px] transform translate-x-1/2 translate-y-1/2">
                <label htmlFor="upload" className="flex items-center justify-center bg-[#E4E6EB] h-[36px] w-[36px] rounded-[100%] hover:bg-[#BDBDBD] cursor-pointer">
                  {IconMap('IoCamera')}
                </label>
                <input id="upload" type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-[3rem] mt-[5rem]">
            <Inputs label={<>Website Address {IconMap('FaQuestionCircle','business-site ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-site" content={"Your company's website link"}/></>} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.website} placeholder={'example.com'} onChange={InputHandler(setCredInfo, 'website')} className=""  />
            <Inputs label={<>Company Name {IconMap('FaQuestionCircle','business-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-name" content={"The company name you represent as part of the signature/footer of the outgoing emails"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.company_name} placeholder={'Example Company Name'} onChange={InputHandler(setCredInfo, 'company_name')} className="" />
            <Inputs label={<>Calendly Link (Optional){IconMap('FaQuestionCircle','business-calendlylink ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-calendlylink" content={"A meeting link using Calendly to add as part of the e-mail generation"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.calendly_link} placeholder={'https://calendly.com/username'} onChange={InputHandler(setCredInfo, 'calendly_link')} className="" />
            <Inputs label={<>Role (Optional){IconMap('FaQuestionCircle','business-role ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-role" content={"Your role in the organization reaching out to the leads"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.role} placeholder={'Your Role'} onChange={InputHandler(setCredInfo, 'role')} className=""/>
            <Inputs label={<>LinkedIn URL (Optional){IconMap('FaQuestionCircle','business-linkedin ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-linkedin" content={"Your LinkedIn Profile to be added as part of the outgoing email and/or signature"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.linkedin_url} placeholder={'https://www.linkedin.com/in/username'} onChange={InputHandler(setCredInfo, 'linkedin_url')} className="" />
          </div>
          <Inputs label={<>Business Description {IconMap('FaQuestionCircle','business-desc ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-desc" content={"What is your business for?"}/> </>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={credInfo.company_description} onChange={InputHandler(setCredInfo, 'company_description')} className={"rounded-[8px] md:col-span-2 mt-4 mb-8"} />
          <Inputs label={<>Target Description {IconMap('FaQuestionCircle','business-target ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-target" content={"Define your ideal customer, target market, and product offering"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={credInfo.target_description} onChange={InputHandler(setCredInfo, 'target_description')} className={"rounded-[8px] md:col-span-2 mb-8"}/>
          <div className="mt-[5rem] flex justify-end">
            <Button label="Save" className="bg-purple rounded-lg border-purple ring-0 mr-2" onClick={confirmSubmit} />
          </div>
        </div>
      </div>
    </div>

    <ConfirmDialog/>
  </>
  );
};

export default CompanySettings;
