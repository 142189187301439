import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import IconMap from "../Icons/IconMaps";
import { Sidebar } from "primereact/sidebar";
import { NoResultFound } from "../Icons/ImgPath";
import { FilterMatchMode } from "primereact/api";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

interface BulkOption {
  value: string;
  name: string;
  onSubmit: Function;
  icon?: any
}

interface Props {
  checkbox?: boolean;
  actions?: boolean;
  header?:any;
  actionsClicked?: any,
  action_types?: {
    view?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
  customActions?: any;
  actionsWidth?: any;
  columns: any;
  data: any;
  expandableRow?: boolean;
  onRowExpand?: any;
  onRowCollapse?: any;
  rowExpansionTemplate?: any;
  paginator?: any;
  hasOptions?: boolean;
  bulkOptions?: BulkOption[];
  searchOnly?: Boolean;
}

const Table: React.FC<Props> = ({ 
  checkbox = false, columns, data,
    actions = false, action_types = {
      view: true,
      edit: false,
      delete: false,
    }, customActions = null, actionsWidth = '3rem',
    expandableRow = false, onRowExpand, onRowCollapse, rowExpansionTemplate,
    paginator = true,
    actionsClicked,
    hasOptions = false,
    bulkOptions,
    searchOnly = false,
    header
  }) => {
 
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [bulkItem, setBulkItem] = useState<any>();

  const [selectAll, setSelectAll] = useState(false);

  const renderActions = (data: any) => {
    // console.log(data)
    return (
      <div className="flex justify-center">
        { action_types.view && <Button className="text-purple ring-0" icon="pi pi-eye" rounded text aria-label="View" onClick={(e) => actionsClicked && actionsClicked(data.id, 'view') }/> }
        { action_types.edit && <Button className="text-blue-500 ring-0" icon="pi pi-pencil" rounded text aria-label="Edit" onClick={(e) => actionsClicked && actionsClicked(data.id, 'edit') }/> }
        { action_types.delete && <Button className="text-red-500 ring-0" icon="pi pi-trash" rounded text aria-label="Trash" onClick={(e) => actionsClicked && actionsClicked(data.id, 'trash') }/> }
      </div>
    );
  }

  const renderTemplate = (value: any, template: any, rowData: any) => {
    return(
      template(value, rowData)
    )
  } 

  const itemsPerPage = [
    { value: '10', name: '10'},
    { value: '50', name: '50'},
    { value: '100', name: '100'},
  ]
  const viewOptions = [
    { value: 'grid', name: IconMap('FaColumns')},
    { value: 'table', name: IconMap('FaTable')},
  ];
  const [pageItem, setPageItem] = useState<any>(itemsPerPage[0]);
  const [viewOption, setViewOption] = useState(viewOptions[0]);
  const [filterVisible, setFilterVisible] = useState(false);

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e:any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onBulkSelectItem = (e: any) => {
    setBulkItem(e);
  }

  const optionTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="flex gap-4 items-center">
          {option.icon && option.icon}
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const optionsTemplate = (option: any) => {
    return (
      <div className="flex gap-4 items-center">
        {option.icon && option.icon}
        <div>{option.name}</div>
      </div>
    );
  };

  const confirmBulkSubmit = (bulkOption: any, data: any) => {
    confirmDialog({
      tagKey: 'lazyTableKey',
      message: `Are you sure you want to "${bulkOption.name}" the selected data?`,
      header: 'Bulk Selection Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'bg-purple rounded-lg',
      acceptLabel: 'Proceed',
      accept: () => {bulkOption.onSubmit(data); handleBulkAccept();}
    });
  };

  const handleBulkSubmit = () => {
    if(bulkItem){
      const bulkOption: any = bulkOptions && bulkOptions.filter((e: any) => e.value === bulkItem.value)[0];
      confirmBulkSubmit(bulkOption, selectedData);
    }
  }

  const handleBulkAccept = () => {
    setBulkItem(undefined);
    setSelectedData([]);
    setSelectAll(false);
  }

  // useEffect(() => {
  //   // fetch api then set the data

  //   console.log('bulkOptions ::: ', bulkOptions)
  // }, [bulkOptions]);

  return (
  <>
    {
      hasOptions &&
      <div className="mb-4 flex flex-col md:flex-row gap-2 justify-between items-center">
        <div className="rounded-lg border border-gray w-full md:w-1/3 flex items-center">
          <i className="pi pi-search p-2 text-label"></i>
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search items..." className="border-none bg-transparent ring-0 w-full" />
        </div>
        {
          selectedData && (bulkOptions && bulkOptions?.length > 0) && selectedData.length > 0 && (
            <div className="w-full lg:w-2/4 flex items-center gap-4">
              <div className="rounded-lg border border-gray w-full">
                <Dropdown placeholder="Select Action" valueTemplate={optionTemplate} itemTemplate={optionsTemplate}  value={bulkItem?.value} onChange={onBulkSelectItem} options={bulkOptions} optionLabel="name" className="border-none ring-0 w-full bg-transparent" />
              </div>
              <Button onClick={handleBulkSubmit} disabled={!bulkItem} label="Submit" className="bg-purple rounded-lg border-purple w-[130px]" />
            </div>
          )
        }
        {searchOnly? <div className="flex w-full md:auto justify-end gap-2 flex-wrap"></div> :
        <div className="flex w-full md:auto justify-end gap-2 flex-wrap">
          <div className="flex justify-center gap-2">
            <div className="flex items-center gap-2">
              <label className="text-label">Items per page</label>
              <div className="rounded-lg border border-gray">
                <Dropdown value={pageItem.value} onChange={(e:any) => {setPageItem(e)}} options={itemsPerPage} optionLabel="name" className="border-none ring-0 w-full bg-transparent" />
              </div>
            </div>
            <div className="flex items-center p-2 rounded-lg border border-gray">
              <div>
                {IconMap('FaColumns', 'text-purple')}
              </div>
            </div>
          </div>
          <div>
            <Button className="bg-transparent text-black rounded-lg border-gray w-full md:auto" iconPos="right" label="Filter" icon="pi pi-filter" onClick={() => setFilterVisible(true)} />
            <Sidebar visible={filterVisible} position="right" onHide={() => setFilterVisible(false)}>
              <h2>Filter Here</h2>
            </Sidebar>
          </div>
        </div>
        }
      </div>
    }
    <DataTable value={data} paginator={paginator} rows={(paginator ? pageItem.value : undefined)} filters={filters}
      emptyMessage={(<>
        <div className="flex flex-col items-center gap-4 text-center justify-center">
          <img src={NoResultFound} alt="No Results Found"/> 
          <p className="text-3xl">No Results Found</p>
        </div>
      </>)}
      selectionMode={!checkbox ? null : 'checkbox'} rowHover={true} selection={selectedData} onSelectionChange={(e: any) => setSelectedData(e.value)}
       dataKey="id"
      onRowToggle={(e:any) => setExpandedRows(e.data)} expandedRows={expandedRows} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}
    >
      {checkbox && <Column headerClassName="bg-purple-light rounded-tl-lg rounded-bl-lg border-none" selectionMode="multiple" headerStyle={{ width: '3rem' }} />}
      {
        columns.map((col:any, i:any) => {
          const column1 = (<Column 
            key={i} 
            headerClassName={`${col.headerClassName? col.headerClassName:''} bg-purple-light ${(i==0 && !checkbox ? 'rounded-tl-lg rounded-bl-lg' : '' )} ${(i == (columns.length-1) && !actions)? 'rounded-tr-lg rounded-br-lg' : ''} ${ expandableRow && col.has_expander ? '' : 'border-white border'} font-normal text-black`}
            field={col.field} 
            header={col.header}
            body={(data) => col.hasTemplate ? renderTemplate(data[col.field], col.template, data) : data[col.field]}
          />)
          const column2 = (<Column 
            key={`${i}-expander`} 
            headerClassName={`bg-purple-light ${ expandableRow && col.has_expander ? '' : 'border-white border'} font-normal text-black`}
            expander={true} 
            style={{ width: '2rem' }} 
          />)
          if (col.has_expander && expandableRow) {
            return [column1, column2];
          }
          return column1;
        })
      }
      {actions && !customActions && <Column headerClassName="bg-purple-light rounded-tr-lg rounded-br-lg border-none font-normal text-black" header={'Actions'} headerStyle={{ width: actionsWidth }} body={renderActions}/>}
      {actions && customActions && <Column headerClassName="bg-purple-light rounded-tr-lg rounded-br-lg border-none font-normal text-black" header={'Actions'} headerStyle={{ width: actionsWidth }} body={customActions}/>}
    </DataTable>
  </>
  );
}

export default Table;
