import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useState } from "react";
import LazyTable from "../../../components/tables/LazyTable";
import Table from "../../../components/tables/Table";
import { Button } from "primereact/button";
import TeamMemberModal from "../../../components/modals/settings/teammembers/TeamMemberModal";
import { useAxios } from "../../../contexts/AxiosContext";
import { useToast } from "../../../contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import { template } from "lodash";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _ from 'lodash';


const TeamMembers: React.FC<any> = ({ setLoadingState }) => {
  const navigate = useNavigate();
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  
  const [errors, setErrors] = useState({ firstname: '', lastname: '', email: '' });
  
  

  const nameTemplate = (data: any, rowData: any) => {
    return rowData.colleague_user.name;
  }
  const emailTemplate = (data: any, rowData: any) => {
    return rowData.colleague_user.email;
  }

  const [refreshTeamTable, setRefreshTeamTable] = useState(false);
  const teamTableColumns = [
    { field: 'name', header: 'Member Name', template: nameTemplate, hasTemplate: true},
    { field: 'email', header: 'Email Address', template: emailTemplate, hasTemplate: true},
    { field: 'role.name', header: 'Role' },
  ]
  const teamTableData = [
    {name: 'John Doe', email: 'johndoe@example.com', role: 'Administrator'}
  ];
  const handleTeamTableActionsClicked = async(id: any, type: any) => {

    
    switch( type ){
      case 'trash':
        const v = await withReactContent(Swal).fire({
          title: "Are you sure to delete?",
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          showCancelButton: true
        });
        if(v.isConfirmed){
          await axiosService.api.delete(`/api/colleagues/delete/${id}`);
          withReactContent(Swal).fire({
            icon: "success",
            title: "Successfully deleted."
          });    
          setRefreshTeamTable( true );
          setRefreshInvitesTable( true );          
        }  
        
        break;
      case 'edit':
        handleShowModal('edit');
        
        let colleague = await axiosService.api.get(`/api/colleagues/get-one/${id}`);
        
        if( _.get(colleague.data, 'id', '') === '' ){
          handleOnHide();
          withReactContent(Swal).fire({
            icon: "error",
            title: "Unable to edit.",
            text: JSON.stringify( colleague )
          });         
          return;
        }
        
        setData({
          id: _.get(colleague.data, 'id', ''),
          firstname:  _.get(colleague.data, 'firstname', ''),
          lastname:  _.get(colleague.data, 'lastname', ''),
          email:  _.get(colleague.data, 'email', ''),
          role: _.get(colleague.data, 'role_id', ''),
        });
        
        
        break;
        case 'change_role': 
          let colleague_user = await axiosService.api.get(`/api/colleagues/get-one/${id}`);
        
          if( _.get(colleague_user.data, 'id', '') === '' ){
            handleOnHide();
            withReactContent(Swal).fire({
              icon: "error",
              title: "Unable to edit.",
              text: JSON.stringify( colleague_user )
            });         
            return;
          }
        
          setData({
            id: _.get(colleague_user.data, 'id', ''),
            firstname:  _.get(colleague_user.data, 'firstname', ''),
            lastname:  _.get(colleague_user.data, 'lastname', ''),
            email:  _.get(colleague_user.data, 'email', ''),
            role: _.get(colleague_user.data, 'role_id', ''),
          });
          
          handleShowModal('edit');

        break;
    }
    

    
  }
  
  
  const refreshTables = () =>{
    setRefreshTeamTable( true );
    setRefreshInvitesTable( true );  
  }
  
  

  const [refreshInvitesTable, setRefreshInvitesTable] = useState(false);

  const invitesTableColumns = [
    { field: 'name', header: 'Member Name', template: nameTemplate, hasTemplate: true},
    { field: 'email', header: 'Email Address', template: emailTemplate, hasTemplate: true},
    { field: 'role.name', header: 'Role' },
    { field: 'status', header: 'Status' },
  ]
  const invitesTableData = [
    {name: 'John Doe', email: 'johndoe@example.com', role: 'Administrator'}
  ];
  const handleInvitesTableActionsClicked = async(id: any, type: any) => {
    handleTeamTableActionsClicked(id, type);
  }

  const myColleaguesActions = (data: any) => {
    return <>
      <div className="flex justify-center gap-1">
        <Button className="text-purple ring-0" icon="pi pi-user" rounded text aria-label="Change Role" onClick={(e) => handleTeamTableActionsClicked(data.id, 'change_role') }/>
      </div>
    </>
  }

  const tabPanels: any = [
    {
      title: 'List of Team Members',
      content: (
        <> 
          {/* <Table data={[]}
            hasOptions={true} checkbox={true} columns={teamTableColumns}
            actions={true} action_types={{edit: true, delete: true}} actionsClicked={handleTeamTableActionsClicked}
          /> */}
          <LazyTable
            api={'/api/colleagues/my_colleagues'} refreshTable={refreshTeamTable} setRefreshTable={setRefreshTeamTable}
            hasOptions={true} checkbox={true} columns={teamTableColumns}
            actions={true} customActions={myColleaguesActions}
          />
        </>
      )
    },
    {
      title: 'Invitations Sent',
      content: (
        <>
          {/* <Table data={[]}
            hasOptions={true} checkbox={true} columns={invitesTableColumns}
            actions={false}
          /> */}
          <LazyTable
            api={'/api/colleagues/my_invites'} refreshTable={refreshInvitesTable} setRefreshTable={setRefreshInvitesTable}
            hasOptions={true} checkbox={true} columns={invitesTableColumns}
            actions={true} customActions={myColleaguesActions}
            // actions={true} action_types={{edit: true, delete: true}} actionsClicked={handleInvitesTableActionsClicked}
          />
        </>
      )
    }
  ];

  const teamMember = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
  }

  const [data, setData] = useState(teamMember);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('add');

  const handleOnSubmit = () => {
    handleOnHide();
  };

  const inviteMember = () => {

  };

  const handleOnHide = () => {
    setVisible(false);
    setData(teamMember);
  }

  const handleShowModal = (type: string) => {
    setErrors( { firstname: '', lastname: '', email: '' } );
    setType(type);
    setVisible(true);
    // setData(type === 'edit' ? teamMember : data);
    if(type !== "edit"){
      setData(teamMember)
    }
  };

  const checkCompany = () => {
    axiosService.api.get('/api/company/get')
      .then((response:any) => {
        if(response.data.company == null){
          showToast({
            severity: 'warn',
            summary: 'No Company!',
            detail: "Please create a company first before inviting new team members."
          });

          navigate(`/main?page=settings/company`);
        }
      });
  };

  const [roles, setRoles] = useState([]);
  const getRoles = () => {
    axiosService.api.get('/api/roles/available')
      .then((response:any) => {
        setRoles(response.data);
      });
  }

  useEffect(() => {
    checkCompany();
    getRoles();
  }, []);

  return (
    <>
      <div id="team-members" className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Team Members</h1>
          <Button onClick={() => handleShowModal('add')} label="Add Team Member" icon="pi pi-user-plus" className="add-member bg-purple rounded-lg border-purple" />
        </div>

        <div className="panel bg-white rounded-lg p-5">
          <TabView pt={{ nav: { className: 'border-b-purple-highlight rounded-tr-lg rounded-tl-lg' } }} activeIndex={activeIndex} onTabChange={(e: any) => { setActiveIndex(e.index) }}>
            {
              tabPanels.map((e: any, index: number) => {
                return (
                  <TabPanel key={index} header={e.title} pt={{ headerTitle: {className: 'font-normal ' + (activeIndex == index ? 'text-purple' : 'text-label')}, headerAction: { className: `${index == 0 ? 'rounded-tl-lg' : ''} border-purple-highlight ${activeIndex == index ? 'border-b-purple' : 'bg-white'}` } }}>
                    {e.content}
                  </TabPanel>
                );
              })
            }

          </TabView>
        </div> 
        <TeamMemberModal data={data} setData={setData} visible={visible} type={type} onSubmit={inviteMember} onHide={handleOnHide} errors={errors} setErrors={setErrors} refreshTables={refreshTables} roles={roles}/>
      </div>
    </>
  )
}

export default TeamMembers;
