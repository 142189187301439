import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { Avatar } from "primereact/avatar";
import IconMap from "../../../components/Icons/IconMaps";
import { Button } from "primereact/button";
import Inputs from "../../../components/forms/Inputs";
import { InputHandler } from "../../../services/EventHandlers";
import { useAxios } from "../../../contexts/AxiosContext";
import { useToast } from "../../../contexts/ToastContext";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ProfileSettings: React.FC<any> = ({setLoadingState, credInfo,setCredInfo,originalCredInfo}) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [activeIndex, setActiveIndex] = useState(0);
  const [passwordValidation, setPasswordValidation] = useState({
    newPasswordLengthValid: true,
    newPasswordSpecialCharValid: true,
    newPasswordUpperCaseValid: true,
    newPasswordNumberValid: true,
  });
  const handleUpdatePersonal = async () => {
    try {
      showToast({
        severity: 'info',
        summary: 'Processing',
        detail: 'Please wait...'
      });

      setLoadingState(true);

      const dataToSend: any = {
        firstname: credInfo.firstname,
        lastname: credInfo.lastname,
        email: credInfo.email,
        phone: credInfo.phone ?? '',
      };

      const isProfileDataChanged = Object.keys(dataToSend).some(key => {
        const valueInCredInfo = originalCredInfo[key];
        const valueInDataToSend = dataToSend[key];
        const isEqual = valueInCredInfo === valueInDataToSend;
        return !isEqual;
      });

      if (!isProfileDataChanged) {
        showToast({
          severity: 'error',
          summary: 'No Changes!',
          detail: 'No changes were made to your profile.'
        });
        setLoadingState(false);
        return;
      }

      for (const key in dataToSend) {
        if (dataToSend.hasOwnProperty(key) && dataToSend[key].length === 0) {
          showToast({
            severity: 'error',
            summary: 'Missing Fields!',
            detail: `${key} is empty`
          });
          setLoadingState(false);
          return;
        }
      }

      const response = await axiosService.api.post('/api/user/update', dataToSend);
      if (response.data.status){
        showToast({
          severity: 'success',
          summary: 'Profile Updated!',
          detail: 'Your profile has been updated successfully.'
        });
  
        setTimeout(() => {
          window.location.reload();
        }, 2000);

      } else {
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: response.data.message || 'Failed to update profile information.'
        });
      }
    } catch (error: any) {
      showToast({
        severity: 'error',
        summary: 'Update Failed!',
        detail: `${error.response.data.message}`
      });
      console.log(error);
      setLoadingState(false);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      showToast({      
        severity: 'info',      
        summary: 'Processing',      
        detail: 'Please wait...'    });
      const dataToSend = {
        current_password: credInfo.current_password,
        new_password: credInfo.new_password,
        password_confirmation: credInfo.password_confirmation,
      };

      if (!credInfo.new_password) {
        showToast({
          severity: 'error',
          summary: 'No Changes!',
          detail: 'No changes were made to your password.'
        });
        setLoadingState(false);
        return;
      }


      if(!validatePasswordInputs()){
        showToast({
          severity: 'error',
          summary: 'Invalid Password!',
          detail: 'Password must meet required format'
        });
        setLoadingState(false);
        return;
      }

      const response = await axiosService.api.post('/api/user/change-password', dataToSend);

      showToast({
        severity: response.data.status ? 'success' : 'error',
        summary: response.data.status ? 'Password Updated!' : 'Failed!',
        detail: response.data.status ? 'Your password has been updated successfully.' : response.data.message
      });

      if(response.data.status){
        setTimeout(() => {
          window.location.reload();
        }, 2000);  
      }
      
    } catch (error: any) {
      showToast({
        severity: 'error',
        summary: 'Password Update Failed!',
        detail: `${error.response.data.message}`
      });
    }
  };

  const confirmSubmit = () => {
    console.log('clicked');
    confirmDialog({
      message: 'Are you sure you want to update your profile?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => {
        if(activeIndex === 0){
          handleUpdatePersonal();
        }else{
          handleUpdatePassword();
        }
      },
    });
  };

  const validatePasswordInputs = () => {
    const newPasswordLengthValid = credInfo.new_password.length >= 8;
    const newPasswordSpecialCharValid = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(credInfo.new_password);
    const newPasswordUpperCaseValid = /[A-Z]/.test(credInfo.new_password);
    const newPasswordNumberValid = /\d/.test(credInfo.new_password);
    
    setPasswordValidation({
      newPasswordLengthValid,
      newPasswordSpecialCharValid,
      newPasswordUpperCaseValid,
      newPasswordNumberValid,
    });
    
    return (
      newPasswordLengthValid &&
      newPasswordSpecialCharValid &&
      newPasswordUpperCaseValid &&
      newPasswordNumberValid
    );
  };
  
  const handlePhoneChange = (newValue: any) => {
    setCredInfo((prevValues: any) => ({
      ...prevValues,
      phone: newValue
    }));
  };


  const handleFileUpload = async (event: any) => {
    if (!event.target.files || event.target.files.length === 0) {
      showToast({
        severity: 'error',
        summary: 'No File Selected',
        detail: 'Please select a file to upload.',
      });
      return;
    }
  
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('profile_picture', file);
  
    try {
      showToast({
        severity: 'info',
        summary: 'Uploading',
        detail: 'Please wait...',
      });
  
      setLoadingState(true);
  
      const response = await axiosService.api.post('/api/user/upload-profile-picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.data.status) {
        showToast({
          severity: 'success',
          summary: 'Upload Successful!',
          detail: 'Profile picture has been uploaded successfully.',
        });
  
        setCredInfo((prevValues: any) => ({
          ...prevValues,
          profile_picture: response.data.profile_picture_url,
        }));
  
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        showToast({
          severity: 'error',
          summary: 'Upload Failed',
          detail: response.data.message || 'Failed to upload profile picture.',
        });
      }
    } catch (error: any) {
      showToast({
        severity: 'error',
        summary: 'Upload Failed',
        detail: `${error.response.data.message}`,
      });
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    // refreshToken();
    validatePasswordInputs();
  }, [credInfo.new_password]);

  return (
    <>
      <div id="profile-settings" className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Personal Profile Settings</h1>
        </div>

        <div className="panel bg-white rounded-lg p-5 min-h-[50rem]">
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Profile Details" className="flex justify-center">
              <div className="mt-[5rem] p-5 min-h-[40rem] z-0 w-[75%] sm:w-full">
                <div className="flex justify-center items-center">
                  <div className="flex flex-col md:flex-row items-center justify-center relative">
                    <div className="flex items-center justify-center mt-[-40px] w-[130px] h-[130px] z-1 border-[10px] border-[#fff] rounded-[50%] shadow-md overflow-hidden">
                      <Avatar pt={{image: {className: "object-contain"}}} color="white" image={credInfo.profile_picture} imageAlt="Profile Picture" icon="pi pi-user" size="xlarge" shape="square" style={{ backgroundColor: '#CDCECF', width:'130px', height:'130px' }} />
                    </div>
                    <div className="absolute bottom-[25px] right-[25px] transform translate-x-1/2 translate-y-1/2">
                      <label htmlFor="upload" className="flex items-center justify-center bg-[#E4E6EB] h-[36px] w-[36px] rounded-[100%] hover:bg-[#BDBDBD] cursor-pointer">
                        {IconMap('IoCamera')}
                      </label>
                      <input id="upload" type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-[3rem] mt-[5rem]">
                  <Inputs label={'First Name'} type={'text'} value={credInfo.firstname} onChange={InputHandler(setCredInfo, 'firstname')}  required invalid withValidation />
                  <Inputs label={'Last Name'} type={'text'} value={credInfo.lastname} onChange={InputHandler(setCredInfo, 'lastname')} required invalid  withValidation />
                  <Inputs label={'Email'} type={'email'} value={credInfo.email} onChange={InputHandler(setCredInfo, 'email')} required invalid withValidation />
                  <Inputs label={'Phone Number'} type={'phone'} value={credInfo.phone} onChange={(e:any)=>handlePhoneChange(e)} invalid  withValidation />
                </div>
                <div className="mt-[5rem] flex justify-end">
                  <Button label="Save" className="bg-purple rounded-lg border-purple ring-0 mr-2" onClick={confirmSubmit} />
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Password Settings" className="flex justify-center">
              <div className="relative p-3 mt-[5rem] w-full md:w-[50%]">
                <h1 className="mb-8 flex justify-center text-[32px] font-[600]">Set your Password</h1>
                <ul className="mb-8">
                  <li className="flex flex-row">{IconMap('FaCheckCircle',`me-2 font-[500] ${passwordValidation.newPasswordLengthValid ? 'text-[#75C4D0]' : 'text-[#E7E7E7]'} `)} <span>8 characters minimum </span> </li>
                  <li className="flex flex-row">{IconMap('FaCheckCircle',`me-2 font-[500] ${passwordValidation.newPasswordSpecialCharValid ? 'text-[#75C4D0]' : 'text-[#E7E7E7]'}`)} <span>At least one special character (!,@,#,$,&,%,+,-) </span> </li>
                  <li className="flex flex-row">{IconMap('FaCheckCircle',`me-2 font-[500] ${passwordValidation.newPasswordUpperCaseValid ? 'text-[#75C4D0]' : 'text-[#E7E7E7]'}`)} <span>At least one uppercase letter </span> </li>
                  <li className="flex flex-row">{IconMap('FaCheckCircle',`me-2 font-[500] ${passwordValidation.newPasswordNumberValid ? 'text-[#75C4D0]' : 'text-[#E7E7E7]'}`)} <span>At least one number </span> </li>
                </ul>
                <div>
                  <Inputs label={'Current Password'} type={'password'} value={credInfo.current_password} onChange={InputHandler(setCredInfo, 'current_password')} invalid  withValidation />
                  <Inputs label={'New Password'} type={'password'} value={credInfo.new_password} onChange={InputHandler(setCredInfo, 'new_password')} invalid  withValidation />
                  <Inputs label={'Confirm Password'} type={'password'} value={credInfo.password_confirmation} onChange={InputHandler(setCredInfo, 'password_confirmation')} invalid  withValidation/>
                </div>
                <div className="mt-[5rem] flex justify-end">
                  <Button label="Save" className="bg-purple rounded-lg border-purple ring-0 mr-2" onClick={confirmSubmit} />
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
      <ConfirmDialog/>
    </>
  );
};

export default ProfileSettings;
