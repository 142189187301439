import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import Inputs from "../../../components/forms/Inputs";
import { InputHandler } from "../../../services/EventHandlers";
import moment from "moment";
import { useToast } from "../../../contexts/ToastContext";
import { Card } from 'primereact/card';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { confirmPopup } from 'primereact/confirmpopup';

const EmailBlueprints:React.FC<any> = ({ axiosService, setLoadingState }) => {
  const [blueprintTemplates, setBlueprintTemplates] = useState([]);
  const [blueprintModal, setBlueprintModal] = useState(false);
  const [blueprintDetails, setBlueprintDetails] = useState({template: "", name: "Template " + moment().format('YYYY-MM-DD HH:mm:ss')});
  const {showToast} = useToast();
  const [defaultTemplate, setDefaultTemplate] = useState(0);
  const [refreshItems, setRefreshItems] = useState(false);

  const submitBlueprint = () => {
    setRefreshItems(true);
    setBlueprintModal(false);
    showToast({
      severity: "warn",
      summary: "Saving!",
      detail: "Blueprint is being processed"
    });
    axiosService.api.post('/api/templates/create/default', blueprintDetails)
      .then((response:any) => {
        showToast({
          severity: "success",
          summary: "Success!",
          detail: response.message
        });
      }).catch((error:any) => {
        setBlueprintModal(true);
        showToast({
          severity: "error",
          summary: "Error!",
          detail: error.response.data.message
        });
      });
  };

  const initTemplates = () => {
    axiosService.api.get('/api/templates/get-all')
      .then((response:any) => {
        if(response.data != null){
          const isDefault = response.data.find((k:any) => k.default == 1);

          if(isDefault != null){
            setDefaultTemplate(isDefault.id);
          }
          setBlueprintTemplates(response.data);
        }
      });
  };

  const setNewDefault = (id:number) => {
    setDefaultTemplate(id);

    axiosService.api.get(`/api/templates/default/${id}`)
      .then((response:any) => {
        showToast({
          severity: "success",
          summary: "Success!",
          detail: response.data.message
        });
      });
  };

  const acceptDelete = (id:any) => {
    axiosService.api.delete(`/api/templates/delete/${id}`)
      .then((response:any) => {
        setRefreshItems(true);
        showToast({
          severity: "success",
          summary: "Success!",
          detail: response.data.message
        });
      });
  };

  const cardHeader = (id:number) => {
    return (
      <>
        <div className="p-5 flex flex-row gap-3">
          <div>
            <Inputs 
              dataId={id} 
              value={defaultTemplate} 
              onChange={(e:any) => (setNewDefault(id))} 
              label={id} 
              type={"radio"} 
            />
          </div>
          <div onClick={(e:any) => {
            confirmPopup({
              target: e.currentTarget,
              message: 'Are you sure you want to proceed?',
              icon: 'pi pi-exclamation-triangle',
              defaultFocus: 'accept',
              accept: () => {
                acceptDelete(id);
              }
            })
          }}>
            <i className="pi pi-trash" style={{ color: 'red' }}></i>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    initTemplates();
  }, [])

  useEffect(() => {
    if(refreshItems){
      setRefreshItems(false);
      initTemplates();
    }
  }, [refreshItems])

  return (
    <>
      <ConfirmPopup />
      <Dialog header="" closable={true} visible={blueprintModal} onHide={() => {setBlueprintModal(false)}}
        pt={{header:{style:{borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}, 
        content:{style:{borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}}}} 
        dismissableMask={true}
        style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      >
        <div className="flex flex-col w-full justify-center">
          <h3 className="my-5 text-center text-[16px]">Create a blueprint to inspire your future email templates...</h3>
          <div className="flex flex-row justify-between mb-3">
            <Inputs value={blueprintDetails.name} onChange={(e:any) => (InputHandler(setBlueprintDetails, 'name')(e))} label={'Blueprint Alias'} type={"text"} />
          </div>
          <div className="flex flex-row justify-between mb-10">
            <Inputs value={blueprintDetails.template} onChange={(e:any) => (InputHandler(setBlueprintDetails, 'template')(e))} label={''} type={"editor"} />
          </div>
          <Button onClick={submitBlueprint} label="Save Blueprint and Set as Default" className="add-agent bg-purple rounded-lg border-purple ring-0" />
        </div>
      </Dialog>
      <div id="profile-settings" className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Email Blueprints</h1>
          <Button onClick={() => {setBlueprintModal(true); setBlueprintDetails({template: "", name: "Template " + moment().format('YYYY-MM-DD HH:mm:ss')})}} label="Create Blueprint" icon="pi pi-plus" className="add-agent bg-purple rounded-lg border-purple ring-0" />
        </div>
      </div>
      <div className="panel relative bg-white rounded-lg m-5 p-5 min-h-[50rem] y-scrollable">
        <div className="grid grid-cols-3 gap-4">
          {
            blueprintTemplates.map((item:any) => (
              <>
                <Card header={() => cardHeader(item.id)} subTitle={item.name} footer={undefined} className="md:w-25rem">
                  <Inputs value={item.blueprint} label={undefined} type={"editor"} />
                  <Button onClick={undefined} label="Update" className="add-agent bg-purple rounded-lg border-purple ring-0" />
                </Card>
              </>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default EmailBlueprints;