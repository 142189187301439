import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Slider } from 'primereact/slider';
import { InputNumber } from 'primereact/inputnumber';
import _ from 'lodash';
import { ProgressSpinner } from 'primereact/progressspinner';


interface AgentAddMoreLeadsModalProps {
  isOpen: boolean;
  onClose: () => void;
  agent_id: any;
  setLoadingState: any;
  axiosService: any;
  setRefreshTable: any;
  
}

const AgentAddMoreLeadsModal: React.FC<AgentAddMoreLeadsModalProps> = ({ axiosService, isOpen, onClose, agent_id, setLoadingState, setRefreshTable }) => {
  const [sliderValue, setSliderValue] = useState(60);
  let [inputValue, setInputValue] = useState(0);
  const [isOpen2, setIsOpen2] = useState(false);
  
  const [reviewInputs, setReviewInputs] = useState(false);
  const [isLoadingNewNumbers, setIsLoadingNewNumbers] = useState(false);
  const [outData, setOutData] = useState<any>({
    wordings: null,
    leads_available: 0,
    inputValue: 0,
    available_credit: 0,
    url_preview: null,
  });
  const [urlPreview, setUrlPreview] = useState<any>(null);
  const [errors, setErrors] = useState<any>({credits:null, confirm:null});
  const [isFinished, setIsFinished] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  
  const forSetOutData = async( mainDataIn?: any ) => {
      
      let userData = mainDataIn.userData;
      let agentData = mainDataIn.agentData;
      let ai_score = mainDataIn.ai_score;
      
      if( _.isEmpty( userData ) || _.isEmpty( agentData ) ){ return; }
      
      
      
      switch( agentData.status ){
        case 'Pending':
        case 'In-Progress':
          if(agentData.is_active){
            await withReactContent(Swal).fire({
              icon: "info",
              title: `Leads are Being Retrieved`,
            });
            setRefreshTable(true);
            return null;  
          }
      }
      
      const available_credit: any = userData.available_credit || 0;
      
      if( available_credit===0 ){
        withReactContent(Swal).fire({
          icon: "error",
          title: `Not Enough Credits`,
        });   
        return null;   
      }
      
      // let leads_available: any = agentData.ai_confident_leads_counts_discovered - agentData.leads_counts_processes;
      let leads_available: any = agentData.leads_available;
      
      if( leads_available<0 ){ leads_available=0; }
      
      
      const leads_available2: any = agentData.leads_counts_discovered - agentData.leads_counts_processes;
      
      if(  leads_available2<=0 ){
        withReactContent(Swal).fire({
          icon: "error",
          title: `No more available leads`,
        });   
        return null;       
      }
      
      let default_value: any = 50;
      if( available_credit<=49 ){
        default_value = available_credit;
      } 
      
      if( default_value>=leads_available ){
        default_value = leads_available;
      }
      
      if( default_value<0 ){ default_value=0; }
      
      const leads_wording2 = (leads_available>=2) ? 'leads' : 'lead';
      const are_is_wording2 = (leads_available>=2) ? 'are' : 'is';
      let note_message = <span className="text-sm text-gray-500"><i>(There {are_is_wording2} {leads_available} available {leads_wording2}.)</i></span>;      

      if( available_credit < leads_available ){
        const credits_wording2 = (available_credit>=2) ? 'credits' : 'credit';      
        note_message = <span className="text-sm text-gray-500"><i>(There {are_is_wording2} {leads_available} available {leads_wording2}. You have {available_credit} available {credits_wording2}.)</i></span>;       
      }
      
      

      
      
      const credits_wording = (default_value>=2) ? 'credits' : 'credit';
      const leads_wording = (default_value>=2) ? 'leads' : 'lead';
      
      const leads_wording3 = agentData.ai_confident_leads_counts_discovered>=2 ? 'leads' : 'lead';
    
      let wordings = <div className="mb-4"><span>Set the target to {default_value} {credits_wording} for {default_value} additional {leads_wording}.</span> {note_message}</div>;
      
      if( default_value==0 ){
         wordings = <div className="mb-4 text-gray-500">There is 0 available lead when AI Score is {ai_score}.</div>;
      }      
      
      setOutData({
        wordings,
        leads_available,
        inputValue,
        available_credit,
      });
      setUrlPreview( null );
      
      setInputValue( default_value );
  
  };
  
  
  useEffect(() => {
    (async() => {
      if( !isOpen ){ return; }
      const [userDataResponse,agentResponse] = await Promise.all([
        axiosService.api.get('/api/user/current'),
        axiosService.api.get(`/api/agents/get3/${agent_id}/60`),
      ]);
      setIsOpen2( true );
      setLoadingState(false);
      const userData = userDataResponse.data || {};
      const agentData = agentResponse.data || {};    
      forSetOutData({ userData, agentData, ai_score:60 });
    })();
  }, []);


  const sliderEnd = async(e:any)=>{
    setUrlPreview( null );
    setErrors({credits:null, confirm:null});
    if( !isOpen ){ return; }
    const ai_score = e.value;
    setIsLoadingNewNumbers(true);
    (async() => {
      const [userDataResponse,agentResponse] = await Promise.all([
        axiosService.api.get('/api/user/current'),
        axiosService.api.get(`/api/agents/get3/${agent_id}/${ai_score}`),
      ]);
      const userData = userDataResponse.data || {};
      const agentData = agentResponse.data || {};    
      setIsLoadingNewNumbers(false);
      forSetOutData({ userData, agentData, ai_score });
    })();
  };







  if (!isOpen){ return null; }
  if (!isOpen2){ return null; }
  
  
  const previewHandler = async()=>{
    let url_preview = (
      <div className="mb-4 leading-tight">
        <div>
          <strong>Preview: </strong>
          <small className="text-gray-500"><i>(Loading...)</i></small>
        </div>
      </div>  
    );
    setUrlPreview( url_preview ); 
  
  
  
    const ai_score = sliderValue;
    const res = await axiosService.api.get(`/api/agents/preview_urls/${agent_id}/${ai_score}`);
    const urls = res.data.urls || [];  
  
    url_preview = (
      <div className="mb-4 leading-tight">
        <div>
          <strong>Preview:</strong>
          { (outData.leads_available>=6) && <small className="text-gray-500"> <i>(5 Randomly Sampled URLs)</i></small> }
        </div>
        <div className="pl-3 pt-1 text-sm text-gray-800">
      
          {urls.map((url: any, k:any) => (
            <div key={k}>
              <p>&bull; {url}</p>
            </div>
          ))}          
        
        </div>
      </div>  
    );
    setUrlPreview( url_preview );  
  }
  
  
  const submitHandler = async()=>{
    const newErrors = { credits:'', confirm:'' };
    
    setErrors(newErrors);
    let isValid = true;
    
    if( inputValue===0 ){
      newErrors.credits='The target credits should not be zero.';
      isValid = false;
    }
    if( !reviewInputs ){
      newErrors.confirm='Please click the checkbox before proceeding.';
      isValid = false;
    }

    if( inputValue > outData.available_credit ){
      let credit_wording = (outData.available_credit>=2) ? 'credits' : 'credit';
      newErrors.credits=`You have ${outData.available_credit} available ${credit_wording}.`;
      isValid = false;    
    }else if( inputValue > outData.leads_available ){
      let leads_wording = (outData.leads_available>=2) ? 'leads' : 'lead';
      let are_is_wording = (outData.leads_available>=2) ? 'are' : 'is';
      newErrors.credits=`There ${are_is_wording} ${outData.leads_available} available ${leads_wording}.`;
      isValid = false;    
    }

    
    if( !isValid ){
      setErrors(newErrors);
      return;
    }
    
    
    setIsSending( true );
    

    const res_itledee0ya: any = await axiosService.api.post('/api/agents/add_more_leads_2', { agent_id, credits_to_spend:inputValue, ai_score:sliderValue  });
    if( res_itledee0ya.data.status ){
      setIsFinished( true );
      setRefreshTable( true ); 
      return;   
    }else{
      setIsApiError( true );
      withReactContent(Swal).fire({
        icon: "error",
        text: `Unable to send the request.`,
      });
      return;   
    }
    
  };  
  
  
  if( !(outData.available_credit>=1) ){ return null; }
  
  if( isApiError ){ return null; }

  
  if( isFinished ){
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center text-gray-800">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-3xl font-bold mb-4 text-center"></h2>
            
            <div className="mb-4 text-center">The request for more leads has been sent.</div>
            
            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                >
                OK
              </button>       
            </div>
          </div>
        </div>
      );  
  }
  
  if(isSending){
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center text-gray-800">
          <div className="bg-white rounded-lg p-6 w-96">
            
            <h2 className="text-3xl font-bold mb-4 text-center">Sending</h2>
            
            <div className="text-center">
              <ProgressSpinner />
            </div>
            
          </div>
        </div>
      );
  }  
  
  
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center text-gray-800">
      <div className="bg-white rounded-lg p-6 w-96">
      
      
        <h2 className="text-3xl font-bold mb-4 text-center">Get More Leads?</h2>
        
        {outData.wordings}
        
        
        <div className="mb-4">
          <label className="block mb-2"><span><strong>AI Score:</strong> {sliderValue}</span> <span className="text-sm text-gray-500">{ isLoadingNewNumbers && <i>Loading New Numbers...</i>  }</span></label>
          <Slider
            type="range"
            min={0}
            max={100}
            value={sliderValue}
            onChange={ (e) => setSliderValue(Number(e.value)) }
            onSlideEnd={sliderEnd}
            className="w-full"
          />
          
          { (sliderValue==0) && <p className="text-sm mt-2 text-gray-500"><i>An AI Score of 0 will include unrelated items such as <u>news outlets</u>, <u>government sites</u> and <u>social network sites</u> due to such keywords are also mentioned on these sites. The default AI score was 60.</i></p> }
          
        </div>
        
        <div className="mb-4">
          <label className="block mb-2"><strong>Credits:</strong></label>

          <InputNumber
              value={inputValue}
              onChange={(e:any) => setInputValue(Number(e.value))}
              className="w-full rounded px-2 py-1 hide-spinner swal2-input text-center hide-spinner"
          />
          {errors.credits && <small className="p-error ml-2">{errors.credits}</small>}
        </div>
        
        <div className="mb-4">
          <div className="leading-tight">
            <input
              type="checkbox"
              checked={reviewInputs}
              onChange={(e) => setReviewInputs(e.target.checked)}
              className="mr-2 border-gray-300 rounded"
            />
            <a 
              href="#"
              className="text-gray-500" 
              onClick={ ()=>{ setReviewInputs(!reviewInputs) } }
              >I already reviewed my inputs</a>     
          </div>       
          {errors.confirm && <small className="p-error ml-5">{errors.confirm}</small>}          
        </div>
        
        {urlPreview}
        
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded mr-2 text-gray-500"
            >
            Cancel
          </button>
          {
            ( outData.leads_available>=1  ) && 
            <button
              className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded mr-2 text-gray-500"
              onClick={previewHandler}
              >
              Preview 
            </button>
          }   
          {
            ( !(outData.leads_available>=1)  ) && 
            <button
              className="bg-gray-300 px-4 py-2 rounded mr-2 text-gray-500 line-through"
              >
              Preview 
            </button>
          }                   
          {
            ( inputValue>=1 && outData.leads_available>=1  ) && 
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              onClick={submitHandler}
              >
              Confirm 
            </button>
          }
          {
            (!( inputValue>=1 && outData.leads_available>=1 )) && 
            <button
              className="bg-gray-400 text-gray-300 px-4 py-2 rounded line-through font-normal"
              >
              Confirm 
            </button>
          }          
        </div>
        
      </div>
    </div>
  );
};

export default AgentAddMoreLeadsModal;
