import { useEffect, useState } from "react";
import { useAxios } from "../../../contexts/AxiosContext";
import LazyTable from "../../../components/tables/LazyTable";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Inputs from "../../../components/forms/Inputs";
import { InputHandler } from "../../../services/EventHandlers";
import { useToast } from "../../../contexts/ToastContext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { TabView, TabPanel } from 'primereact/tabview';
import { Divider } from "primereact/divider";
import { InputSwitch } from 'primereact/inputswitch';

const SuperAdminPrompts:React.FC<any> = ({ setLoadingState }) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [aiVisible, setAiVisible] = useState(false);
  const [aiButtonText, setAIButtonText] = useState('Save Personality');
  const [aiModalHeader, setAIModalHeader] = useState('Create AI Personality');
  const [modalHeader, setModalHeader] = useState('Add Prompt');
  const [buttonText, setButtonText] = useState('Save Prompt');
  const [visible, setVisible] = useState(false);
  const [seletedId, setSelectedId] = useState(0);
  const [filterValues, setFilterValues] = useState<any>({
    prompt: '',
  });
  const [promptData, setPromptData] = useState({
    prompt: ''
  });
  const api = '/api/superadmin/gpt-prompts';
  const apiAll = '/api/superadmin/gpt-prompts/select-all';
  const personalitiesApi = '/api/superadmin/ai-personality/paginate'
  const personalitiesApiAll = '/api/superadmin/ai-personality/select-all'
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const accountsTableColumn = [
    { field: 'prompt', header: 'GPT Prompt' }
  ];

  const setPersonalityActive = (checked:any, id:number) => {
    console.log("checked::", checked);
    const active = checked ? 1 : 0;
    axiosService.api.get(`/api/superadmin/ai-personality/active/${id}/${active}`)
      .then((reponse:any) => {
        setRefresh(true);
      });
  };

  const aiActiveTemplate = (data: any, rowData: any, field: any) => {
    return (
      <>
        <InputSwitch checked={rowData.active == 1 ? true : false} onChange={(e:any) => setPersonalityActive(e.value, rowData.id)} />
      </>
    );
  }
  const aiTableColumn = [
    { field: 'name', header: 'AI Name' },
    { field: 'type', header: 'Tempalate Type' },
    { field: 'tone', header: 'Tempalate Tone' },
    { field: 'active', header: 'Active', template: aiActiveTemplate, hasTemplate: true }
  ];
  const templateOptions = {
    types: [
      {value:'B2B Sales', label:'B2B Sales'}, {value:'B2C Outreach', label:'B2C Outreach'}, {value:'Soft Introduction', label:'Soft Introduction'}
    ],
    tones: [
      {value:'Company Introduction', label:'Company Introduction',}, {value:'Service/Product Introduction', label:'Service/Product Introduction'}
      // , {value:'Greeting/Appreciation', label:'Greeting/Appreciation'}
      // , {value:'Personal Introduction', label:'Personal Introduction'}
      , {value:'Networking', label:'Networking'}, {value:'Feedback Request', label:'Feedback Request'}
      , {value:'Case Study/Success Story', label:'Case Study/Success Story'}, {value:'Educational/Insight', label:'Educational/Insight'}
      , {value: 'Follow Up', label: 'Follow Up'}
    ]
  };
  const [personalityDetails, setPersonalityDetails] = useState<any>({
    name: "",
    template_type: templateOptions.types[0].value,
    template_tone: templateOptions.tones[0].value,
    gpt_prompt: null,
  });

  const resetPersonalityDetails = () => {
    console.log("here");

    setPersonalityDetails({
      name: "",
      template_type: templateOptions.types[0].value,
      template_tone: templateOptions.tones[0].value,
      gpt_prompt: null,
    });
  };

  const [gptExample, setGPTExample] = useState("");

  const generateExample = () => {
    axiosService.api.post('/api/ai_suggest/personality', personalityDetails)
      .then((response:any) => {
        setGPTExample(response.data.suggestion);
        setPersonalityDetails((prevValues:any) => (
          {
            ...prevValues,
            gpt_prompt: response.data.prompt
          }
        ))
      });
  };

  const handleDataConvert = (data: any, setTableData: Function) => {
    setTableData(data.map((e:any) => ({
      id: e.id,
      prompt: e.prompt
    })));
  };

  const handleAIDataConvert = (data: any, setTableData: Function) => {
    setTableData(data.map((e:any) => ({
      id: e.id,
      name: e.name,
      type: e.type,
      tone: e.tone,
      active: e.active
    })));
  };

  const handlePersonalitySubmit = () => {
    showToast({
      severity: 'warn',
      summary: 'Submitting Personality!',
      detail: "submission is in process."
    });

    if(personalityDetails.gpt_prompt == null){
      showToast({
        severity: 'warn',
        summary: 'Generate AI!',
        detail: "Please generate AI first or fill up a custom prompt."
      });

      return null;
    }

    axiosService.api.post('/api/superadmin/ai-personality/create', personalityDetails)
      .then((response:any) => {
        showToast({
          severity: 'success',
          summary: 'Success!',
          detail: response.data.message
        });
        resetPersonalityDetails();
      }).catch((error:any) => {
        showToast({
          severity: 'error',
          summary: 'Failed!',
          detail: error.response.data.message
        });
      });
  };

  const actionsClicked = (data: any, type: string) => {
    setPromptData({
      prompt: ''
    });
    setLoadingState(true);
    if(type === 'view'){
      setLoadingState(false);
    } else if(type=='trash'){
      setLoadingState(false);
      confirmPromptDelete(data);
    } else if(type == 'edit'){
      setSelectedId(data);
      setIsEdit(true);
      setButtonText('Update Prompt');
      setModalHeader('Edit Prompt');
      setVisible(true);

      axiosService.api.get(`/api/superadmin/gpt-prompt/${data}`)
        .then((response:any) => {
          setPromptData({
            prompt: response.data.prompt
          });
          setLoadingState(false);
        }).catch((error:any) => {
          setLoadingState(false);
          const errorMessage = error.response.data.message;
          showToast({
            severity: 'error',
            summary: 'Error!',
            detail: errorMessage
          });
        });
    }
  };

  const AIactionsClicked = (data: any, type: string) => {
    if(type=='trash'){
      setLoadingState(false);
      confirmAIDelete(data);
    }
  }

  const handlePromptModal = (type:any) => {
    if(type == 'add'){
      setModalHeader('Add Prompt');
      setButtonText('Save Prompt');
      setVisible(true);
      setIsEdit(false);
    }
  };

  const savePrompt = () => {
    setVisible(false);
    const saveDeatils = {
      method: (!isEdit) ? axiosService.api.post : axiosService.api.patch,
      endpoint: (!isEdit) ? '/api/superadmin/gpt-prompt' : `/api/superadmin/gpt-prompt/${seletedId}`,
      title: (!isEdit) ? 'New Prompt' : 'Updated Prompt!'
    }

    saveDeatils.method(saveDeatils.endpoint, promptData).then((promptResponse:any) => {
      console.log("promptResponse::", promptResponse);
      setPromptData({
        prompt: ''
      });
      setRefresh(true);
      showToast({
        severity: 'success',
        summary: saveDeatils.title,
        detail: promptResponse.data.message
      });
    }).catch((error:any) => {
      setLoadingState(false);
      const errorMessage = error.response.data.message;
      showToast({
        severity: 'error',
        summary: 'Error!',
        detail: errorMessage
      });
    });
  };

  const deletePrompt = (id:any) => {
    axiosService.api.delete(`/api/superadmin/gpt-prompt/${id}`)
      .then((deleteResponse:any) => {
        setRefresh(true);
        showToast({
          severity: 'success',
          summary: "Prompt Deleted",
          detail: deleteResponse.data.message
        });
      }).catch((error:any) => {
        setLoadingState(false);
        const errorMessage = error.response.data.message;
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: errorMessage
        });
      });
  };

  const confirmPromptDelete = (id: number) => {
    confirmDialog({
      message: 'Do you want to delete this prompt?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deletePrompt(id)
    });
  };

  const deleteAI = (id:any) => {
    axiosService.api.delete(`/api/superadmin/ai-personality/delete/${id}`)
      .then((deleteResponse:any) => {
        setRefresh(true);
        showToast({
          severity: 'success',
          summary: "AI Deleted",
          detail: deleteResponse.data.message
        });
      }).catch((error:any) => {
        setLoadingState(false);
        const errorMessage = error.response.data.message;
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: errorMessage
        });
      });
  };

  const confirmAIDelete = (id: number) => {
    confirmDialog({
      message: 'Do you want to delete this prompt?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteAI(id)
    });
  };

  const handleAIModal = (type:any) => {
    if(type == 'add'){

    }else if(type == 'edit'){

    }

    setAiVisible(true);
  }

  useEffect(() => {
    setLoadingState(false);
  }, []);
  return (
    <>
      <ConfirmDialog/>
      <Dialog 
        header={modalHeader} 
        visible={visible} 
        onHide={() => {setVisible(false)}}
        style={{ width: '50vw' }} 
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        footer = {
          <>
            <div >
              <Button label="Cancel" onClick={() => {setVisible(false)}} className="p-button-text text-red-500" />
              <Button label={buttonText} className="bg-purple rounded-lg border-purple ring-0" onClick={savePrompt} autoFocus />
            </div>
          </>
        }
        >
          <div>
            <Inputs value={promptData.prompt} onChange={(e:any) => (InputHandler(setPromptData, 'prompt')(e))} label={'GPT Prompt Example'} type={"textarea"} />
          </div>
      </Dialog>
      <Dialog 
        header={aiModalHeader} 
        visible={aiVisible} 
        onHide={() => {setAiVisible(false); resetPersonalityDetails();}}
        style={{ width: '50vw' }} 
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        footer = {
          <>
            <div >
              <Button label="Cancel" onClick={() => {setAiVisible(false); resetPersonalityDetails();}} className="p-button-text text-red-500" />
              <Button label={aiButtonText} className="bg-purple rounded-lg border-purple ring-0" onClick={handlePersonalitySubmit} autoFocus />
            </div>
          </>
        }
        >
          <div>
            <Inputs value={personalityDetails.name} onChange={(e:any) => (InputHandler(setPersonalityDetails, 'name')(e))} label={'AI Name'} type={"text"} />
          </div>
          <div>
            <Inputs value={personalityDetails.template_type} 
              onChange={(e:any) => 
                setPersonalityDetails((prevValues:any) => (
                  {
                    ...prevValues,
                    template_type: e.value
                  }
                ))
              } 
              options={templateOptions.types} 
              label={'Template Type'} 
              type={"dropdown"} 
            />
          </div>
          <div>
            <Inputs value={personalityDetails.template_tone}
              onChange={(e:any) => 
                setPersonalityDetails((prevValues:any) => (
                  {
                    ...prevValues,
                    template_tone: e.value
                  }
                ))
              }
              options={templateOptions.tones} 
              label={'Template Tone'} 
              type={"dropdown"} 
            />
          </div>
          <div>
            <Inputs 
              value={personalityDetails.gpt_prompt}
              onChange={(e:any) => {
                InputHandler(setPersonalityDetails, 'gpt_prompt')(e);
              }}
              label={"Custom GPT Prompt (optional)"}
              type={"text"}
            />
          </div>
          <div className="mt-3">
            <Divider />
            <div>
              Generated Example
            </div>
            <div className="mb-3">
              <sub>note: change tone and type to generate new examples</sub>
            </div>
            <div className="p-3">
              <div>
                {gptExample}
              </div>
            </div>
            <div>
              <Button label="Generate Example" onClick={generateExample} className="bg-purple rounded-lg border-purple ring-0" />
            </div>
          </div>
      </Dialog>
      <div className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Agents</h1>
        </div>
        <div className="panel bg-white rounded-lg p-5">
          <div>
            <TabView>
              <TabPanel header="AI Personalities">
                <div>
                  <div className="flex justify-end mb-3">
                    <Button onClick={() => handleAIModal('add')} label="Create AI Personality" icon="pi pi-plus" className="bg-purple rounded-lg border-purple ring-0" />
                  </div>
                  <div>
                      <LazyTable
                        api={personalitiesApi}
                        apiAll={personalitiesApiAll}
                        convertData={true} 
                        convertDataFunction={handleAIDataConvert} 
                        refreshTable={refresh} 
                        setRefreshTable={setRefresh}
                        hasOptions={true}
                        checkbox={true}
                        columns={aiTableColumn}
                        actions={true}
                        action_types={{ edit: false, delete: true }}
                        actionsClicked={AIactionsClicked}
                        expandableRow={false}
                        bulkOptions={[
                          // {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
                        ]}
                        filterValues={filterValues}
                      />
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="GPT Prompts">
                <div>
                  <div className="flex justify-end mb-3">
                    <Button onClick={() => handlePromptModal('add')} label="Add Prompt" icon="pi pi-plus" className="bg-purple rounded-lg border-purple ring-0" />
                  </div>
                  <div>
                    <LazyTable
                      api={api}
                      apiAll={apiAll}
                      convertData={true} 
                      convertDataFunction={handleDataConvert} 
                      refreshTable={refresh} 
                      setRefreshTable={setRefresh}
                      hasOptions={true}
                      checkbox={true}
                      columns={accountsTableColumn}
                      actions={true}
                      action_types={{ edit: true, delete: true }}
                      actionsClicked={actionsClicked}
                      expandableRow={false}
                      bulkOptions={[
                        // {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
                      ]}
                      filterValues={filterValues}
                    />
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdminPrompts;