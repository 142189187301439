import React, { useState,useEffect } from 'react';
import Inputs from '../../components/forms/Inputs';
import { Button } from 'primereact/button';
import { useAxios } from '../../contexts/AxiosContext';
import { InputHandler } from '../../services/EventHandlers';
import { useToast } from '../../contexts/ToastContext';
import { Dropdown } from 'primereact/dropdown';

interface Props {
  setLoadingState?: boolean
  setView?: any
  mailboxes?: any
  currentMailbox?: any
  data?: any
  callback?: any
  other_contacts?: any
}

const ComposeMail: React.FC<Props> = ({ 
  setLoadingState, setView, mailboxes, currentMailbox, data = null, callback = null, other_contacts = null
}) => {
  const axiosService = useAxios();
  const { showToast } = useToast();

  const mail = {
    to: data && data.to || [],
    subject: data && data.subject || '',
    message: data && data.body || '',
    mailbox_id: currentMailbox,
    lead_id: data && data.lead_id || null,
    type: data && data.type || null
  }
  const [mailData, setMailData] = useState<any>(mail);
  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  const sendEmail = () => {

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });
    
    if(!mailData.mailbox_id){
      showToast({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'Please select an active mailbox.',
      });  
      return ;
    }
    setSendButtonLoading(true);
    axiosService.api.post('/api/mail/send', mailData).then((response:any) => {
      console.log(response.data);
      setSendButtonLoading(false);

      if(response.data.status){
        showToast({
          severity: 'success',
          summary: 'Success!',
          detail: response.data.message,
        });
        setMailData(mail); // set to default
        if(callback){
          callback();
        }
      } else {
        showToast({
          severity: 'error',
          summary: 'Failed!',
          detail: response.data.message,
        });
      } 
      if(callback){
        callback();
      }
    }).catch((error:any) => {
      console.log('Error:', error);
      setSendButtonLoading(false);
    });
  };

  // const [mailboxes, setMailboxes] = useState([]);
  const getMailbox = () => {
    axiosService.api.get('/api/mailboxes/all')
    .then((response:any) => {
      // setMailboxes(response.data);
    })
    .catch((error:any) => {
      console.log('Error fetching data:', error);
    });
  }

  const handleDropdownOnChange = (e: any) => {
    setMailData({
      ...mailData,
      mailbox_id: e.value.id
    });
  }

  useEffect(() => {
    // getMailbox();
    // console.log("currentMailbox::currentMailbox::", currentMailbox);
    console.log(data);
  }, []);

  return(
  <>
    <div className={`${!data && 'p-3 gap-3'}`}>
      {!data && 
        <div className='flex flex-row items-center justify-start'>
          <button onClick={() => setView('Inbox')}  className='surface-border flex items-center justify-center rounded-md text-900 w-[2.5rem] h-[2.5rem] mr-3 py-[0.5rem] border border-[#dee2e6] bg-transparent text-[#607d8b]'>
            <span className=' pi pi-chevron-left'></span>
          </button>
          <span className="block text-900 font-bold text-xl">Compose Message</span>
        </div>
      }
      <div className={`${!data && 'pt-5'}`}>
        <label className='flex items-center text-gray-700 text-sm font-bold mb-2'>Mailbox</label>
        <Dropdown value={mailboxes.filter((e:any) => e.id === mailData.mailbox_id )[0]} onChange={handleDropdownOnChange} options={mailboxes} optionLabel="email" 
          placeholder="Select Mailbox" className="w-full md:w-14rem" />
      </div>
      <form className='surface-section grid mt-4 grid-nogutter formgrid md:surface-border md:border-1 border-round'>
        <Inputs label={'To:'} type={'chips'} pt={{container: { className: 'gap-1'}}} keyfilter={'email'} name={'to'} value={mailData.to} onChange={InputHandler(setMailData, 'to')} />
        <Inputs label={'Subject:'} type={'text'} name={'subject'} value={mailData.subject} onChange={InputHandler(setMailData, 'subject')} />
        <Inputs label={undefined} type={'editor'} name={'message'} value={`${mailData.message && mailData.message.replace(/\n/g, '<br>') || mailData.message }`} onChange={InputHandler(setMailData, 'message')} style={{ height: '320px' }}/>
      </form>
      <div className="col-12 flex column-gap-3 justify-end">
        <Button loading={sendButtonLoading} iconPos='right' className='rounded-lg border-none bg-purple' onClick={sendEmail} label="Send Message" icon="pi pi-send" />
      </div>
    </div>
  </>
  )
}

export default ComposeMail;