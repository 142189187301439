import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import IconMap from "../../components/Icons/IconMaps";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Chart } from 'primereact/chart';
import { useAxios } from "../../contexts/AxiosContext";
import { useToast } from "../../contexts/ToastContext";
import { Skeleton } from "primereact/skeleton";
import moment from 'moment';

const Campaigns: React.FC<any> = ({ setLoadingState }) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const textColorSecondary = "#546e7a";
  const surfaceBorder = "#dee2e6";

  const sortByOptions = [
    {name: 'Agent Name', value: 'name'},
    {name: 'Date Created', value: 'created_at'},
  ];

  const displayTrendOptions = [
    {name: 'Weekly', value: 'weekly'},
    {name: 'Monthly', value: 'monthly'},
    {name: 'Yearly', value: 'yearly'},
  ]

  const [selectedSortBy, setSelectedSortBy] = useState<any>('created_at');
  const [selectedDisplayTrend, setSelectedDisplayTrend] = useState<any>('weekly');

  const [campaigns, setCampaigns] = useState([]);
  const [campaignsLoading, setCampaignsLoading] = useState(false);

  const prepareChartData = (data: any) => {
    return {
      labels: data.chart_labels,
      datasets: [
        {
          label: 'Sent',
          data: data.sents_chart_data.data,
          fill: false,
          borderColor: "#DAB5FF",
          tension: 0.4,
          pointStyle: 'circle',
          pointRadius: 1,
        },
        {
          label: 'Opens',
          data: data.opens_chart_data.data,
          fill: false,
          borderColor: "#FB923C",
          tension: 0.4,
          pointStyle: 'circle',
          pointRadius: 1,
        },
        {
          label: 'Replies',
          data: data.replies_chart_data.data,
          fill: false,
          borderColor: "#609AF8",
          tension: 0.4,
          pointStyle: 'circle',
          pointRadius: 1,
        }
      ]
    };
  }

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: false
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: false
        }
      },
      y: {
        ticks: {
          color: textColorSecondary
        },
        suggestedMax: 20,
        grid: {
          color: surfaceBorder
        },
        min: 0,
      }
    }
  };

  

  const getCampaigns = () => {
    setCampaignsLoading(true);
    const filter = {
      trend_type: selectedDisplayTrend,
      sort: selectedSortBy
    }
    axiosService.api.post('/api/campaigns', filter)
    .then((response: any) => {
      setCampaigns(response.data);
      setCampaignsLoading(false);
    })
    .catch((error: any) => {
      console.log(error);
      setCampaignsLoading(false);
    });
  }

  const StatusTemplate = ({data} : any) => {
    const { status, is_active } = data
    let statusClass = "bg-orange-400"; // PENDING
    let newStatus = status;
    if(status.toLowerCase() === "completed"){ // COMPLETED
      statusClass = "bg-green-400"
    }
    if(status.toLowerCase() === "in-progress" && is_active === 0){ // PAUSED
      statusClass = "bg-gray-400";
      newStatus = "Paused"
    }
    
    if(is_active === 1){
      statusClass = "bg-blue-400";
      newStatus = "Active"
    }

    return <span className={`rounded-lg text-sm text-white px-4 py-1 ${statusClass}`}>{newStatus}</span>
  }

  const getStatusMessage = (data: any) => {
    const now = moment();
    let date;
    let message;
  
    if (data.status.toLowerCase() === "completed") {
      date = moment(data.updated_at);
      message = `Completed ${date.fromNow()}`;
    } else if (data.status.toLowerCase() === "in-progress" && data.is_active === 0) {
      date = moment(data.updated_at);
      message = `Paused ${date.fromNow()}`;
    } else if (data.is_active === 1) {
      date = moment(data.updated_at);
      message = `${date.fromNow()} playing`;
    } else {
      date = moment(data.created_at);
      message = `Created ${date.fromNow()}`;
    }
  
    return message;
  };

  useEffect(() => {
    getCampaigns();
  }, [selectedDisplayTrend, selectedSortBy]);

  return <>
    <div id="campaigns" className="flex flex-col gap-3">
      <div className="title flex justify-between items-center">
        <h1 className="text-2xl">Reporting</h1>
      </div>

      <div className="panel bg-white rounded-lg p-5 flex flex-col gap-5">
        <div className="flex gap-2 justify-between items-center">
          <div className="rounded-lg shadow border border-gray w-full md:w-1/4 flex items-center">
            <i className="pi pi-search p-2 text-label"></i>
            <InputText value={''} onChange={() => {}} onKeyUp={() => {}} placeholder="Search items..." className="border-none bg-transparent ring-0 w-full" />
            {IconMap('FaQuestionCircle','search ms-1 items-center text-[#585858] mx-2',undefined,16)}<Tooltip target=".search" content={"Input search then hit Enter"}/>
          </div>

          <div className="flex gap-5 items-center justify-end w-full">
            <div className="w-full lg:w-1/4 flex items-center gap-1">
              <label className="text-label w-[20%]">Sort by</label>
              <div className="rounded-lg border border-gray w-full">
                <Dropdown placeholder="Sort By"
                  value={selectedSortBy}
                  options={sortByOptions}
                  optionLabel="name" 
                  className="border-none ring-0 w-full bg-transparent" 
                  onChange={(e: any) => setSelectedSortBy(e.value)}
                />
              </div>
            </div>

            <div className="w-full lg:w-1/4 flex items-center gap-1">
              <label className="text-label w-[60%]">Display trend by</label>
              <div className="rounded-lg border border-gray w-full">
                <Dropdown placeholder="Display Trend" 
                  value={selectedDisplayTrend}
                  options={displayTrendOptions}
                  optionLabel="name" 
                  className="border-none ring-0 w-full bg-transparent"
                  onChange={(e: any) => setSelectedDisplayTrend(e.value)}

                />
              </div>
            </div>

            <Button label="Filter" iconPos="right" icon={IconMap('RiEqualizerLine')} className="border border-purple bg-transparent text-purple ring-0 rounded-lg hover:text-white hover:bg-purple gap-3" />
          </div>
        </div>

        <div className="flex gap-5 items-center">
          <h1 className="font-bold text-label">Trend Legend</h1>
          <div className="flex items-center gap-2">
            <div className="h-[20px] w-[20px] bg-purple-highlight rounded-md">&nbsp;</div>
            <p className="text-label">Sent</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-[20px] w-[20px] bg-orange-400 rounded-md">&nbsp;</div>
            <p className="text-label">Opens</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-[20px] w-[20px] bg-blue-400 rounded-md">&nbsp;</div>
            <p className="text-label">Replies</p>
          </div>
        </div>

        <div className="grid lg:grid-cols-3 gap-5">
          {
            campaignsLoading && <>
              <Skeleton width="100%" className="min-h-[300px] w-full rounded-lg" />
              <Skeleton width="100%" className="min-h-[300px] w-full rounded-lg" />
              <Skeleton width="100%" className="min-h-[300px] w-full rounded-lg" />
              <Skeleton width="100%" className="min-h-[300px] w-full rounded-lg" />
              <Skeleton width="100%" className="min-h-[300px] w-full rounded-lg" />
              <Skeleton width="100%" className="min-h-[300px] w-full rounded-lg" />
            </>
          }
          {
            campaigns && !campaignsLoading && campaigns.map((campaign: any, index: number) => (
              <div key={index} className="border rounded-lg shadow-sm min-h-[500px] w-full flex flex-col gap-4 p-5">
                <div className="flex items-center justify-between">
                  <div className="text-label text-lg font-bold">{campaign.name}</div>
                  <div className="flex items-center gap-2">
                    <StatusTemplate data={campaign}/>
                    <i className="pi pi-ellipsis-v text-label"></i>
                  </div>
                </div>
                <div className="rounded-lg text-label bg-gray-200 px-4 py-[3px]">
                  { getStatusMessage(campaign) }
                </div>

                <Chart type="line" data={prepareChartData(campaign)} options={options} />

                <div className="flex gap-1 justify-around">
                  <div className="flex items-center gap-3">
                    <div className="rounded-full bg-purple-highlight text-purple flex items-center w-[40px] h-[40px] text-center p-3">
                      <i className="pi pi-send"></i>
                    </div>
                    <div>
                      <p className="text-label text-[12px]">Sent</p>
                      <p className="text-label text-lg font-bold">{campaign.total_sent}</p>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="rounded-full bg-orange-100 text-orange-400 flex items-center w-[40px] h-[40px] text-center p-3">
                      <i className="pi pi-inbox"></i>
                    </div>
                    <div>
                      <p className="text-label text-[12px]">Opens</p>
                      <p className="text-label text-lg font-bold">{campaign.total_opens}</p>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="rounded-full bg-blue-100 text-blue-400 flex items-center w-[40px] h-[40px] text-center p-3">
                      <i className="pi pi-reply"></i>
                    </div>
                    <div>
                      <p className="text-label text-[12px]">Replies</p>
                      <p className="text-label text-lg font-bold">{campaign.total_replies}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          
        </div>
      </div>
    </div>
  </>
};

export default Campaigns;