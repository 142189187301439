import { Button } from "primereact/button";
import IconMap from "../Icons/IconMaps";
import { Tooltip } from "primereact/tooltip";
import { useAxios } from "../../contexts/AxiosContext";
import { useToast } from "../../contexts/ToastContext";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";

interface Props {
  onSuccess?: any;
  onError?: any;
  label: any;
  loading?: any;
  scopes?: any;
  className?: any;
  setLoading?: any;
}

const Office365Button: React.FC<Props> = ({
  onSuccess,
  onError,
  label = "Office 365",
  loading = false,
  setLoading,
  scopes = 'email openid profile User.Read Mail.Read Mail.Send',
  className = 'border-2 border-purple text-purple bg-transparent font-bold flex gap-2 items-center'
}) => {
  const { showToast } = useToast();
  const axiosService = useAxios();

  const handleClick = async () => {
    // showToast({
    //   summary: 'Warning!',
    //   severity: 'warn',
    //   detail: 'This button is still under development',
    // });

    // return;
    setLoading(true);
    // const tokens = await instance.acquireTokenPopup(loginRequest).then((response:any) => {
    //   // console.log('RESPONSE:::', response, instance.getAllAccounts()[0]);
    //   onSuccess(response);
    // }).catch(e => {
    //   onError(e);
    // });

    // console.log("tokens:::", tokens);
    axiosService.api.get('/api/auth/redirect/office365')
    .then((response:any) => {
      const url = response.data.url;
      Cookies.set('code_verifier', response.data.code_verifier);
      const popup = window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
      if (!popup) {
        setLoading(false);
        showToast({
          summary: 'Warning!',
          severity: 'warn',
          detail: "Popup failed to open. Ensure pop-ups aren't blocked.",
        });
        console.error("Popup failed to open. Ensure pop-ups aren't blocked.");
      } else {
        // Check if the popup is closed every 500ms
        const checkPopup = setInterval(() => {
          
          if (popup.closed) {
            setLoading(false);
            clearInterval(checkPopup); // Stop checking
          }
        }, 500); // Check every half second
      }
    });

  };

  const [receivedMessage, setReceivedMessage] = useState<any>();

  const handleMessage = useCallback((event: MessageEvent) => {
    const { data } = event;
    if ( data.response?.status) {
      setReceivedMessage(data.response);
    }
  }, []);
  
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (receivedMessage) {
      if(receivedMessage.status){
        // setReceivedMessage(null);
        onSuccess(receivedMessage);
      }
    }
  }, [receivedMessage]);

  return (<>
    <Button
      loading={loading}
      className={`button-office-365 select-none ${className}`}
      icon={IconMap('MicrosoftOffice')}
      label={label}
      onClick={handleClick}
    />
    <Tooltip target=".button-office-365" content={"Under development"}/>
  </>);
};

export default Office365Button;