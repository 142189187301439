import { Button } from "primereact/button";
import { HubSpot, SalesForce, Zoho } from "../../../components/Icons/ImgPath";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAxios } from "../../../contexts/AxiosContext";
import { Card } from "primereact/card";

const CRM: React.FC<any> = ({ setLoadingState }) => {
  const axiosService = useAxios();
  const buttonRef = useRef<any>(null);

  const [crms, setCrms] = useState<any>([]);
  const [connectedCrm, setConnectedCrm] = useState<any>({
    hubspot: false,
    zoho: false,
    salesforce: false
  });
  const updateCrmConnection = (crmType:string, isConnected: boolean) => {
    setConnectedCrm((prevCrm:any) => ({
      ...prevCrm, // Keep the previous state
      [crmType]: isConnected, // Update the specific CRM's status
    }));
  };

  const [activeUrl, setActiveUrl] = useState(null);
  const [currentCrmType, setCurrentCrmType] = useState<string | null>(null);

  const [receivedMessage, setReceivedMessage] = useState<any>(null);

  const openPopup = (url: string, crmType: string) => {
    const popup = window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    if (!popup) {
      console.error("Popup failed to open. Ensure pop-ups aren't blocked.");
    } else {
      // Check if the popup is closed every 500ms
      const checkPopup = setInterval(() => {
        
        if (popup.closed) {
          clearInterval(checkPopup); // Stop checking
          setCrms((prevCrms:any) =>
            prevCrms.map((crm:any) => 
              crm.type === crmType ? { ...crm, status: 'Connect' } : crm
            )
          );
          setActiveUrl(null);
          setCurrentCrmType(null);
          console.log("receivedMessage", receivedMessage);
        }
      }, 500); // Check every half second
    }
  };

  useEffect(()=>{
    setLoadingState(false)
  })

  useEffect(() => {
    if (activeUrl && currentCrmType) {
      openPopup(activeUrl, currentCrmType);
    }
  }, [activeUrl, currentCrmType]);
  
  const handleButtonOnClick = (type: any) => {
    setCrms((prevCrms:any) => prevCrms.map((crm:any) => crm.type === type ? { ...crm, status: 'Connecting' } : crm ));
  
    axiosService.api.get(`/api/linked-account/${type}`)
    .then((response: any) => {
      setActiveUrl(response.data);
      setCurrentCrmType(type);
    })
    .catch((error:any) => {
      console.log(error);
    });
  };

  const header = (step: any) => {
    return (
      <div className="p-3 flex flex-col justify-between items-center h-[185px]">
        {step.logo === SalesForce ? 
          <img style={{ height: '94px', width: '130px', objectFit: 'cover' }} alt="Salesforce" src={step.logo}/> 
          : 
          <img style={{ width: '200px',}} alt="Salesforce" src={step.logo} />
        }
        <Button 
          onClick={() => handleButtonOnClick(step.type)}
          className={`crm-button flex justify-center items-center border border-[2px] rounded-md p-3 ${connectedCrm[step.type] || step.status == 'Connected' ? 'border-[#1BA750] bg-[#1BA750]' :' border-[#3490dc]'}`}
          label={connectedCrm[step.type] ? 'Connected' : (step.status ? step.status : 'Connect')}
          loading={(step.status==="Connecting")}
        />
      </div>
    )
  };

  const handleMessage = useCallback((event: MessageEvent) => {
    const { data } = event;
    if (data?.type && data.response?.status) {
      setReceivedMessage(data.response);
    }
  }, []);
  
  useEffect(() => {
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (receivedMessage) {
      if(receivedMessage.status){
        updateCrmConnection(receivedMessage.type, true);
        setReceivedMessage(null);
      }
    }
  }, [receivedMessage]);

  const crmsList = [
    { 
      id: 1, 
      type: 'hubspot',
      logo: HubSpot, 
      status: 'Connect',
      instructions: [
        'Log into your HubSpot account.',
        'Visit the App Marketplace, choose an integration.',
        'Follow prompts to install/connect.',
        'Authorize the connection.',
        'Configure settings, run a test to ensure the connection.',
      ] 
    },
    { 
      id: 2, 
      type: 'zoho',
      logo: Zoho, 
      status: 'Connect',
      instructions: [
        'Log into your Zoho account.',
        'Visit Zoho Marketplace, pick an integration.',
        'Follow prompts to connect. Authorize access.',
        'Set preferences, run tests to ensure it works.',
      ] 
    },
    { 
      id: 3, 
      type: 'salesforce',
      logo: SalesForce, 
      status: 'Connect',
      instructions: [
        'Explore Salesforce AppExchange for integrations.',
        'Select and connect chosen integration.',
        'Set preferences within Salesforce or the tool.',
        'Run tests to ensure the connection works',
      ] 
    },
  ];

  const [linkedAccounts, setLinkedAccounts] = useState();
  const getConnectedAccounts = () => {
    axiosService.api.get('/api/linked-accounts').then((response: any) => {
      const connectedAccounts = response.data;

      const updatedCrms = crmsList.map((crm:any) => {
        const isConnected = response.data.some((account:any) => account.provider.name.toLowerCase() === crm.type);
        return {
          ...crm,
          status: isConnected ? 'Connected' : 'Connect'
        };
      });
      console.log("connectedAccounts::", response.data);

      setLinkedAccounts(connectedAccounts);
      setCrms(updatedCrms);

    }).catch((error: any) => {
      console.log(error);
    });
  }


  useEffect(() => {
    setCrms(crmsList);
    getConnectedAccounts();
  }, []);

  return (
    <>
      <div id="crm" className="flex flex-col gap-3">
        <div className="title flex justify-between items-center py-1">
          <h1 className="text-2xl">CRM</h1>
        </div>

        <div className="panel bg-white rounded-lg p-5">
          <div className="flex flex-col justify-center items-center mt-[3rem]">
            <h2 className="text-[24px] font-[700] mb-[.5rem]">Connect Your CRM</h2>
            <p className="text-[14px] text-center px-7">Integrate your Customer Relationship Management (CRM) system to streamline your sales and customer interactions.</p>
          </div>
          
          <div className="card flex flex-col lg:flex-row  justify-center items-center p-[3rem] gap-[3rem]">
            {crms.map((step:any, i:number) => (

              <Card key={i} header={header(step)} className="w-[365px] h-[475px] p-3 border rounded-xl shadow-xl">
                <h5 className="text-[14px] font-[700]">Instructions:</h5>
                <ul className="list-decimal pl-6">
                  {step.instructions.map((instruction: any, index: any) => (
                    <li key={index}>
                      <p className="text-[14px] font-[400] mb-0"> {instruction}</p>
                    </li>
                  ))}
                </ul>
              </Card>

            ))}
          </div>
        </div> 
      </div>
    </>
  );
}

export default CRM;