import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import IconMap from "../../Icons/IconMaps"
import GoogleButton from "../../buttons/GoogleButton"
import { useAxios } from "../../../contexts/AxiosContext"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import Office365Button from "../../buttons/Office365Button"
import { InputHandler } from "../../../services/EventHandlers"
import Inputs from "../../forms/Inputs"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Password } from "primereact/password"

interface Props {
  visible: boolean
  onHide?: any
  type?: string
  data?: any
  setData?: any
  onSubmit?: any
  onCallback?: any,
  showSmtpModal?: boolean
  setShowSmtpModal?: any
  smtpModalData?: any
}

const MailboxModal: React.FC<Props> = ({
  visible = false, showSmtpModal, setShowSmtpModal, onHide, type, data, setData, onSubmit, onCallback, smtpModalData
}) => {
  const axiosService = useAxios();
  const [loading, setLoading] = useState(false);
  const [loadingOffice365, setLoadingOffice365] = useState(false);

  const handleOnSuccess = (codeResponse: any) => {
    // authenticate on backend
    console.log("codeResponse", codeResponse);
    axiosService.api.post('/api/auth/google', {code: codeResponse.code})
    .then((response:any) => {
      console.log('response:::', response);
      onHide();
      onCallback(response);
      setLoading(false);
    });
  }

  const handleOnError = (errorResponse: any) => {
    console.log(errorResponse);
  }

  const handleOffice365OnSuccess = (codeResponse: any) => {
    const response = {
      data: codeResponse
    }
    onCallback(response);
    onHide();
    // console.log("codeResponse", codeResponse);
    // setLoadingOffice365(false);
    // axiosService.api.post('/api/auth/office365', {data: codeResponse})
    // .then((response:any) => {
    //   onHide();
    //   onCallback(response);
    //   setLoading(false);
    // });
  }

  const account = {
    id: null,
    server_address: '',
    port: 587,
    encryption_type: 'tls',
    username: '',
    password: '',
    from_address: ''
  }
  const [smtpSetting, setSmtpSetting] = useState(account);

  // const [showSmtpModal, setShowSmtpModal] = useState(smtpModal);
  const handleSmtpOnClick = () => {
    onHide();
    setShowSmtpModal(true);
    setSmtpSetting(account);
  }

  const onSMTPHide = () => {
    setShowSmtpModal(false);
    setSmtpSetting(account);
    onHide();
  }

  const onSmtpSubmit = () => {
    if(smtpSetting.id == null){
      axiosService.api.post('/api/auth/smtp', smtpSetting)
      .then((response:any) => {
        console.log(response);
        onSMTPHide();
        onCallback(response);
      }).catch((error: any) => {
        console.log(error);
        onCallback(error);
      });
    } else {
      axiosService.api.post('/api/mailboxes/update/'+smtpSetting.id, smtpSetting)
      .then((response:any) => {
        console.log(response);
        onSMTPHide();
        onCallback(response);
      }).catch((error: any) => {
        console.log(error);
        onCallback(error);
      });
    }
  }

  const footerContent = () => {
    return <div >
      <Button label={"Proceed"} className="bg-purple rounded-lg border-purple" onClick={onSmtpSubmit} autoFocus />
    </div>
  }

  useEffect(() => {
    if(smtpModalData){
      setSmtpSetting({
        id: smtpModalData && smtpModalData.id || null,
        server_address: smtpModalData && smtpModalData.data['server_address'] || '',
        port: smtpModalData && smtpModalData.data['port'] || 587,
        encryption_type: smtpModalData && smtpModalData.data['encryption_type'] || 'tls',
        username: smtpModalData && smtpModalData.data['username'] || '',
        password: smtpModalData && smtpModalData.data['password'] || '',
        from_address: smtpModalData && smtpModalData.profile_data['from_address'] || ''
      })
    }
  }, [smtpModalData]);

  return (
  <>
    <Dialog className="lg:w-1/3 w-[95%]" header="Connect A New Email Account" draggable={false} resizable={false} visible={visible} onHide={onHide}>
      <div className="text-center flex flex-col gap-5">
        <p className="text-sm">Mailbox functionality is only supported for Gmail, Microsoft 365 and SMTP accounts. Kindly ensure that you use one of these supported email providers for optimal performance.</p>
        <div className="flex gap-5 flex-wrap justify-center">
          <GoogleButton loading={loading} setLoading={setLoading} label={'Gmail'} onSuccess={handleOnSuccess} onError={handleOnError} />
          <Office365Button loading={loadingOffice365} setLoading={setLoadingOffice365} label={'Office 365'} onSuccess={handleOffice365OnSuccess} onError={handleOnError} />
          <Button onClick={handleSmtpOnClick} className="border-2 border-purple text-purple bg-transparent font-bold flex gap-2 items-center" icon={IconMap('IoMailOutline')} label="SMTP" />
        </div>
      </div>
    </Dialog>

    <Dialog footer={footerContent} className="lg:w-1/3 w-[95%]" header={`${smtpSetting.id != null ? 'Update' : 'Setup'} SMTP Account`} draggable={false} resizable={false} visible={showSmtpModal} onHide={onSMTPHide}>
      <div className="grid lg:grid-cols-6 gap-4">
        <div className="flex flex-col lg:col-span-2">
          <label className="text-label">Server Address</label>
          <InputText className="rounded-lg w-full" placeholder="smtp.domain.com" value={smtpSetting.server_address} onChange={(e:any) => InputHandler(setSmtpSetting, 'server_address')(e)} />
        </div>
        <div className="flex flex-col lg:col-span-1">
          <label className="text-label">Encryption</label>
          <Dropdown placeholder="tls or ssl" value={smtpSetting.encryption_type} options={[{value: 'tls'},{value:'ssl'}]} optionLabel="value"
            onChange={(e:any) => {
              InputHandler(setSmtpSetting, 'encryption_type')(e); 
              const newval = {
                value: e.value == 'tls' ? 587 : 465
              }
              InputHandler(setSmtpSetting, 'port')(newval)
            
            }} 
            className="rounded-lg"/>
        </div>
        {/* <div></div> */}
        <div className="flex flex-col lg:col-span-3">
          <label className="text-label">From Address</label>
          <InputText className="rounded-lg w-full" placeholder="SectorSift" value={smtpSetting.from_address} onChange={(e:any) => InputHandler(setSmtpSetting, 'from_address')(e)} />
        </div>
        <div className="flex flex-col lg:col-span-3">
          <label className="text-label">Username</label>
          <InputText autoComplete="new-email" className="rounded-lg w-full" placeholder="Username" value={smtpSetting.username} onChange={(e:any) => InputHandler(setSmtpSetting, 'username')(e)} />
        </div>
        <div className="flex flex-col lg:col-span-3">
          <label className="text-label">Password</label>
          <Password toggleMask={true} feedback={false} autoComplete="new-password" inputClassName="rounded-lg w-full" placeholder="Password" value={smtpSetting.password} onChange={(e:any) => InputHandler(setSmtpSetting, 'password')(e)} />
        </div>
      </div>
    </Dialog>
  </>
  );
}

export default MailboxModal;