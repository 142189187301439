import React, { Component } from 'react';
import { FaRegEyeSlash, FaRegEye, FaMicrophone, FaUserCog, FaUserEdit, FaChevronLeft,FaChevronRight,FaChevronDown,FaChevronUp,FaCheck,FaQuestionCircle,FaEnvelope,FaCheckCircle,FaUserLock } from "react-icons/fa";
import { FaCircle,FaRegCopy,FaArrowUpRightFromSquare,FaUsersGear, FaMoneyBillWave,FaAnglesLeft,FaAnglesRight,FaHubspot } from "react-icons/fa6";
import { VscDashboard } from "react-icons/vsc";
import { CiFilter,CiCircleCheck } from "react-icons/ci";
import { RiIndentDecrease,RiIndentIncrease,RiEqualizerLine } from "react-icons/ri";
import { SiZoho } from "react-icons/si";
import { LiaSalesforce } from "react-icons/lia";

import { FaColumns, FaTable, FaPlus,FaMinus, FaTrashAlt, FaRegHandshake,FaRegPlusSquare } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { PiUsersThreeLight } from "react-icons/pi";
import { IoMailOutline,IoCamera,IoKeyOutline,IoSettingsOutline } from "react-icons/io5";

import { HiOutlineCog6Tooth } from "react-icons/hi2";

import { FcGoogle } from "react-icons/fc";
import { TbMailCog } from "react-icons/tb";
import { LuSendHorizonal } from "react-icons/lu";
import { BsInbox,BsIncognito } from "react-icons/bs";
import { MdOutlineAlternateEmail, MdAutoAwesome, MdOutlineAccountTree } from "react-icons/md";
import { GiMagickTrick,GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import { GrSend, GrTemplate } from "react-icons/gr";

import { MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowDown  } from "react-icons/md";

import { BiMailSend } from "react-icons/bi";

import { LiaMoneyBillWaveSolid } from "react-icons/lia";

const IconMap = (iconName: any, classnames:any=null, iconStyle = {}, size = 20) => {
  let icon = null;
  switch(iconName) {
    case 'LiaSalesforce': 
      icon = <LiaSalesforce className={classnames} size={size} style={iconStyle} />
    break;
    case 'SiZoho': 
      icon = <SiZoho className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaRegPlusSquare': 
      icon = <FaRegPlusSquare className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaAnglesRight': 
      icon = <FaAnglesRight className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaAnglesLeft': 
      icon = <FaAnglesLeft className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaCheckCircle': 
      icon = <FaCheckCircle className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaArrowUpRightFromSquare': 
      icon = <FaArrowUpRightFromSquare className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoSettingsOutline': 
      icon = <IoSettingsOutline className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoCloseSharp': 
      icon = <IoCloseSharp className={classnames} size={size} style={iconStyle} />
    break;
    case 'GiHamburgerMenu': 
      icon = <GiHamburgerMenu className={classnames} size={size} style={iconStyle} />
    break;
    case 'BsIncognito': 
      icon = <BsIncognito className={classnames} size={size} style={iconStyle} />
    break;
    case 'FiEdit': 
      icon = <FiEdit className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoKeyOutline': 
      icon = <IoKeyOutline className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoCamera': 
      icon = <IoCamera className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaRegCopy': 
      icon = <FaRegCopy className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaQuestionCircle': 
      icon = <FaQuestionCircle className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaCheck': 
      icon = <FaCheck className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaCircle': 
      icon = <FaCircle className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaChevronDown': 
      icon = <FaChevronDown className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaChevronUp': 
      icon = <FaChevronUp className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaChevronLeft': 
      icon = <FaChevronLeft className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaChevronRight': 
      icon = <FaChevronRight className={classnames} size={size} style={iconStyle} />
    break;
    case 'RiIndentDecrease': 
      icon = <RiIndentDecrease className={classnames} size={size} style={iconStyle} />
    break;
    case 'RiIndentIncrease': 
      icon = <RiIndentIncrease className={classnames} size={size} style={iconStyle} />
    break;
    case 'CiCircleCheck': 
      icon = <CiCircleCheck className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaMicrophone': 
      icon = <FaMicrophone className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaRegEyeSlash': 
      icon = <FaRegEyeSlash className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaRegEye': 
      icon = <FaRegEye className={classnames} size={size} style={iconStyle} />
    break; 
    case 'VscDashboard': 
      icon = <VscDashboard className={classnames} size={size} style={iconStyle} />
    break;
    case 'CiFilter': 
      icon = <CiFilter className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaColumns': 
      icon = <FaColumns className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaTable': 
      icon = <FaTable className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaMinus': 
      icon = <FaMinus className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaPlus': 
      icon = <FaPlus className={classnames} size={size} style={iconStyle} />
    break;
    case 'PiUsersThreeLight':
      icon = <PiUsersThreeLight className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoMailOutline':
      icon = <IoMailOutline className={classnames} size={size} style={iconStyle} />
    break;
    case 'FcGoogle':
      icon = <FcGoogle className={classnames} size={size} style={iconStyle} />
    break;
    case 'MicrosoftOffice':
      icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 48 48`} width={size} height={size}><path fill="#e64a19" d="M7 12L29 4 41 7 41 41 29 44 7 36 29 39 29 10 15 13 15 33 7 36z"/></svg>
    break;
    case 'TbMailCog':
      icon = <TbMailCog className={classnames} size={size} style={iconStyle}/>
    break;
    case 'LuSendHorizonal':
      icon = <LuSendHorizonal className={classnames} size={size} style={iconStyle}/>
    break;
    case 'BsInbox':
      icon = <BsInbox className={classnames} size={size} style={iconStyle}/>
    break;
    case 'MdOutlineAlternateEmail':
      icon = <MdOutlineAlternateEmail className={classnames} size={size} style={iconStyle}/>
    break;
    case 'GiMagickTrick':
       icon = <GiMagickTrick className={classnames} size={size} style={iconStyle}/>
      break;
    case 'FaTrashAlt':
        icon = <FaTrashAlt className={classnames} size={size} style={iconStyle}/>
      break;
    case 'BiMailSend':
      icon = <BiMailSend className={classnames} size={size} style={iconStyle}/>
    break;
    case 'HiOutlineCog6Tooth': 
      icon = <HiOutlineCog6Tooth className={classnames} size={size} style={iconStyle}/>
    break;
    case 'FaUserEdit': 
      icon = <FaUserEdit className={classnames} size={size} style={iconStyle}/>
    break;
    case 'FaUsersGear': 
      icon = <FaUsersGear className={classnames} size={size} style={iconStyle}/>
    break;
    case 'MdAutoAwesome': 
      icon = <MdAutoAwesome className={classnames} size={size} style={iconStyle}/>
    break;
    case 'FaMoneyBillWave': 
      icon = <FaMoneyBillWave className={classnames} size={size} style={iconStyle}/>
    break;
    case 'FaUserCog': 
      icon = <FaUserCog className={classnames} size={size} style={iconStyle}/>
    break;
    case 'FaRegHandshake': 
      icon = <FaRegHandshake className={classnames} size={size} style={iconStyle}/>
    break;
    case 'MdKeyboardDoubleArrowRight':
      icon = <MdKeyboardDoubleArrowRight className={classnames} size={size} style={iconStyle}/>
    break;
    case 'MdKeyboardDoubleArrowDown':
      icon = <MdKeyboardDoubleArrowDown className={classnames} size={size} style={iconStyle}/>
    break;
    case 'MdOutlineAccountTree':
      icon = <MdOutlineAccountTree className={classnames} size={size} style={iconStyle}/>
    break;
    case 'FaHubspot':
      icon = <FaHubspot className={classnames} size={size} style={iconStyle}/>
    break;
    case 'GrSend':
      icon = <GrSend className={classnames} size={size} style={iconStyle}/>
    break;
    case 'RiEqualizerLine':
      icon = <RiEqualizerLine className={classnames} size={size} style={iconStyle}/>
    break;
    case 'LiaMoneyBillWaveSolid':
      icon = <LiaMoneyBillWaveSolid className={classnames} size={size} style={iconStyle}/>
    break;
    case 'FaUserLock': 
      icon = <FaUserLock className={classnames} size={size} style={iconStyle}/>
    break;
    case 'GrTemplate':
      icon = <GrTemplate className={classnames} size={size} style={iconStyle}/>
    break;
    case 'CheckBadge':
      icon = <svg width={size+1} height={size} viewBox="0 0 17 16"  fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 0C9.625 0 10.625 0.65625 11.125 1.625C12.1562 1.28125 13.3125 1.53125 14.1562 2.34375C14.9688 3.15625 15.1875 4.34375 14.875 5.375C15.8438 5.875 16.5 6.875 16.5 8C16.5 9.15625 15.8438 10.1562 14.875 10.6562C15.2188 11.6875 14.9688 12.8438 14.1562 13.6562C13.3125 14.4688 12.1562 14.7188 11.125 14.4062C10.625 15.375 9.625 16 8.5 16C7.34375 16 6.34375 15.375 5.84375 14.4062C4.8125 14.7188 3.65625 14.4688 2.8125 13.6562C2 12.8438 1.78125 11.6875 2.09375 10.6562C1.125 10.1562 0.5 9.15625 0.5 8C0.5 6.875 1.125 5.875 2.09375 5.375C1.75 4.34375 2 3.15625 2.8125 2.34375C3.65625 1.53125 4.8125 1.28125 5.84375 1.625C6.34375 0.65625 7.34375 0 8.5 0ZM11.5 7.03125C11.8125 6.75 11.8125 6.28125 11.5 5.96875C11.2188 5.6875 10.75 5.6875 10.4688 5.96875L7.5 8.96875L6.25 7.71875C5.96875 7.4375 5.5 7.4375 5.21875 7.71875C4.90625 8.03125 4.90625 8.5 5.21875 8.78125L6.96875 10.5312C7.25 10.8438 7.71875 10.8438 8 10.5312L11.5 7.03125Z" fill="#397BFF"/></svg>
    break;
    case 'CancelBadge':
      icon = <svg width={size+1} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 0C12.9062 0 16.5 3.59375 16.5 8C16.5 12.4375 12.9062 16 8.5 16C4.0625 16 0.5 12.4375 0.5 8C0.5 3.59375 4.0625 0 8.5 0ZM11 9.46875L9.53125 8.03125L11 6.5625C11.3125 6.25 11.3125 5.78125 11 5.5C10.7188 5.1875 10.25 5.1875 9.96875 5.5L8.5 6.96875L7 5.46875C6.71875 5.15625 6.25 5.15625 5.96875 5.46875C5.65625 5.75 5.65625 6.21875 5.96875 6.53125L7.4375 8L5.96875 9.46875C5.65625 9.78125 5.65625 10.25 5.96875 10.5312C6.25 10.8438 6.71875 10.8438 7 10.5312L8.5 9.0625L9.9375 10.5312C10.2188 10.8438 10.6875 10.8438 11 10.5312C11.2812 10.25 11.2812 9.78125 11 9.46875Z" fill="#D76076"/></svg>
    break;
  }
  return icon
};

export default IconMap;