import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { useEffect, useState } from "react"

interface Props {
  visible: boolean
  onHide?: any
  data?: any
  onSubmit?: any
  callback?: any
}

const LeadDealStatus: React.FC<Props> = ({ 
  visible = false, onHide, data, callback = null, onSubmit
}) => { 
  const [dealStatus, setDealStatus] = useState('');

  const handleOnSubmit = (e:any) => {
    e.preventDefault();
    const submitData = {
      deal_status: dealStatus,
      ids: data
    }
    onSubmit(submitData);
    handleHide();
  }

  const handleHide = () => {
    onHide();
    setDealStatus('');
  }

  return (
    <>
      <Dialog className="lg:w-1/4 w-[95%]" header="Update Deal Status" draggable={false} resizable={false} visible={visible} onHide={handleHide}>
        <form onSubmit={handleOnSubmit}>
          <div className="flex flex-col gap-5 ">
            <div className="flex flex-col justify-between gap-1">
              <label className="text-label flex">Deal Status</label>
              <Dropdown value={dealStatus} 
                optionLabel="value"
                placeholder="Select Deal Status"
                options={[
                  {value: 'New'}, 
                  {value: 'In Progress'}, 
                  {value: 'Won'}, 
                  {value: 'Lost'}
                ]}
                className="rounded-lg w-full bg-transparent" 
                onChange={(e) => setDealStatus(e.value)}
                required={true}
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button label="Update" className="bg-purple rounded-lg mr-0" autoFocus />
          </div>
        </form>
      </Dialog>
    </>
  );
}

export default LeadDealStatus;