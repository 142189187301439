import React, { ReactNode, useState, useEffect } from 'react';
import { useLocation,useNavigate } from "react-router-dom";
import Navbar from '../navigations/Navbar';
import { MenuItem } from 'primereact/menuitem';
import Sidebar from '../navigations/Sidebar';
import { BreadCrumb } from 'primereact/breadcrumb';
import SuperSidebar from '../navigations/SuperSidebar';

type LayoutProps = {
  children: ReactNode;
  axiosService?: any;
};

const SuperLayout: React.FC<LayoutProps> = ({ children, axiosService }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = new URLSearchParams(location.search).get("page") || "";

  const pageURLItems = (currentPage: any) => {
    const currentPageArray = currentPage.split('/');
    const labels = currentPageArray.map((item: any) => ({ label: item }));
    return labels;
  };

  const handleToggleSidebar = (collapsed: boolean) => {
    setSidebarCollapsed(collapsed); 
  };

  const navigateToPage = (page: string) => {
    navigate(`/main?page=${page}`);
  };

  const routeTemplate = (item:any, options:any) => {
    return (
      <>
        {item.icon && <span className={`p-menuitem-icon text-[#6c757d] cursor-pointer ${item.icon}`} onClick={()=>navigateToPage('dashboard')}></span>}

      </>
    );
  };

  useEffect(() => {
    const items = pageURLItems(currentPage).map((item:any, index:any) => ({
      label: item.label.charAt(0).toUpperCase() + item.label.slice(1).toLowerCase(),
    })).filter((item:any) => item.label !== 'dashboard');
    setMenuItems(items);
  }, [currentPage]);

  const dashboard = { icon: 'pi pi-home', url: 'https://www.primereact.org',  template:routeTemplate};
  
  return (
    <div className="w-full h-screen">
      <Navbar axiosService={axiosService} showCredits={false} />
      <div className="flex flex-no-wrap overflow-x-hidden pt-[80px] h-full">
        <div className={`overflow-y-auto ${sidebarCollapsed ? 'w-30' : 'w-30 md:w-64'} bg-purple-bg text-white`}>
          <SuperSidebar onToggleSidebar={handleToggleSidebar} /> 
        </div>
        <div id="main-content" className="overflow-y-auto w-full p-5 bg-[#eee]">
          <div className="breadcrumbs flex items-center gap-1">
            <BreadCrumb 
              className='bg-transparent border-0 p-0' 
              model={[...menuItems]} 
              home={dashboard} 
              />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SuperLayout;
