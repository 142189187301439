import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAxios } from "../../../contexts/AxiosContext";
import Inputs from "../../../components/forms/Inputs";
import { InputHandler } from "../../../services/EventHandlers";
import { Divider } from "primereact/divider";
import { RadioButton } from "primereact/radiobutton";
import IconMap from "../../../components/Icons/IconMaps";
import { Toast } from 'primereact/toast';
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { useToast } from "../../../contexts/ToastContext";
import { Button } from "primereact/button";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { AgentProps } from "../../../interface/datatypes";

const SummaryStep: React.FC<any> = ({ setEntInfo, entInfo, setLoadingState, countryOptions, setIsGenerated, isGenerated, defaultPrompt, closeOnboarding}) => {
  const { showToast } = useToast();
  const toast = useRef<Toast>(null);
  const axiosService = useAxios();
  const [aiPersonalities, setAIPersonalities] = useState<any>([]);
  const [selectedPersonalityId, setSelectedPersonalityId] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState<any[]>(entInfo.target_countries);
  const [miscLinks, setMiscLinks] = useState<any[]>(entInfo.miscellaneous_links.length > 0 ? entInfo.miscellaneous_links : [{
    description: "",
    link: ""
  }]);

  // const [miscLinks, setMiscLinks] = useState<any[]>([{
  //   description:"",
  //   link:""
  // }]);

  const [aiDesc, setAiDesc] = useState({
    type: '',
    tone: ''
  });
  const [languages, setLanguages] = useState([
    {name: "English", value: "English"},
    {name: "Spanish", value: "Spanish"},
    {name: "French", value: "French"},
    {name: "German", value: "German"},
    {name: "Chinese (Simplified)", value: "Chinese (Simplified)"},
    {name: "Chinese (Traditional)", value: "Chinese (Traditional)"},
    {name: "Japanese", value: "Japanese"},
    {name: "Korean", value: "Korean"},
    {name: "Russian", value: "Russian"},
    {name: "Portuguese", value: "Portuguese"},
    {name: "Italian", value: "Italian"},
    {name: "Dutch", value: "Dutch"},
    {name: "Arabic", value: "Arabic"},
    {name: "Hindi", value: "Hindi"},
    {name: "Bengali", value: "Bengali"},
    {name: "Punjabi", value: "Punjabi"},
    {name: "Turkish", value: "Turkish"},
    {name: "Vietnamese", value: "Vietnamese"},
    {name: "Thai", value: "Thai"},
  ]);

  const getAIPersonalities = () => {
    axiosService.api.get('/api/ai-personality/get-all')
      .then((response:any) => {
        setAIPersonalities(response.data);
        // console.log('getAIPersonalities ::: ', response)
        
        if(response.data.length != 0){
          setEntInfo((prevValues:AgentProps) => ({
            ...prevValues,
            ai_personality_id: 3//response.data[0].id
          }));

          setAiDesc({
            type: response.data[0].type,
            tone: response.data[0].tone
          });
        }
      });
  };

  const countriesTemplate = (option: any) => {
    return (
      <div className="flex items-center">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
          className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
          style={{ width: '18px' }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const countryTemplate = (option: any, props: any) => {
    if (option && option.name) {
      return (
        <div className="flex items-center">
          <img
            alt={option.name}
            src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
            className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
            style={{ width: '18px' }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const changePersonality = (value:any) => {
    setLoadingState(true);
  
    try {
      const personality = aiPersonalities.find((item:any) => item.id === value);
  
      if (!personality) {
        throw new Error('Personality not found');
      }
  
      setEntInfo((prevValues:AgentProps) => ({
        ...prevValues,
        ai_personality_id: value
      }));
  
      setAiDesc({
        type: personality.type,
        tone: personality.tone
      });
  
      setSelectedPersonalityId(value); 
  
      setTimeout(() => {
        showToast({ 
          severity: 'success', 
          summary: 'Success!', 
          detail: 'AI Personality has been changed.' 
        });
        setLoadingState(false); 
      }, 500);
    } catch (error:any) {
      setTimeout(() => {
        showToast({
          severity: 'error',
          summary: 'Error!',
          detail: error.message || 'Failed to change AI Personality.'
        });
        setLoadingState(false);
      }, 500);
    }
  };

  const handlePhoneChange = (newValue: any) => {
    setEntInfo((prevValues:AgentProps) => ({
      ...prevValues,
      phone: newValue
    }));
  };

  const handleInputsChange = (e: any) => {
    const newKeywords = e.target.value;
    // const removedKeywords = entInfo.target_keywords.filter((keyword: string) => !newKeywords.includes(keyword));
  
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      target_keywords: newKeywords
    }));
    
  };

  const handleNegativeInputsChange = (e: any) => {
    const newKeywords = e.target.value;
  
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      negative_keywords: newKeywords
    }));
  };

  const addMiscLink = () => {
    const newLink = { description: "", link: "" };
    setMiscLinks([...miscLinks, newLink]);
  };

  const removeMiscLink = (index: number) => {
    const newMiscLinks = (miscLinks||[]).filter((_, i) => i !== index);
    setMiscLinks(newMiscLinks);
    setEntInfo((prevValues: AgentProps) => ({
      ...prevValues,
      miscellaneous_links: prevValues.miscellaneous_links ? prevValues.miscellaneous_links.filter((_: any, i: any) => i !== index) : [],
    }));
  };

  const handleInputMiscLinks = (index:any, field:any) => (e:any) => {
    const newMiscLinks = miscLinks.slice();
    newMiscLinks[index][field] = e.target.value;
    setMiscLinks(newMiscLinks);
  };

  useEffect(() => {
    const validLinks = (miscLinks||[]).filter(link => link.description && link.link);

    setEntInfo((prev:AgentProps) => ({
      ...prev,
      miscellaneous_links: validLinks,
    }));
  }, [miscLinks, setEntInfo]);

  useEffect(() => {
    setEntInfo((prevValues:AgentProps) => (
      {
        ...prevValues,
        target_countries: selectedCountries
      }
    ));
    // console.log('selectedCountries ::: ', selectedCountries )
  }, [selectedCountries]);

  useEffect(() => {
    getAIPersonalities();
    setLoadingState(false);
  }, []);

  useEffect(() => {
    document.getElementById('top-summary')?.scrollIntoView({ behavior: 'smooth' });
  }, []);
 
  // useEffect(() => {
  //   console.log('miscLinks Info ::: ', miscLinks )
  //   // console.log('target_countries ::: ', entInfo.target_countries[0] )
  // }, []);

  return (
    <>    
    <div className="flex flex-col" id="top-summary" >
      <div className="w-full bg-[#fff] p-[20px] border-r border-[#ddd]" id="form-section">
        <div className="flex flex-row justify-between w-full">
        <h2 className="text-[24px] font-[700]">Summary Page</h2>
          <div className="flex flex-row  items-center hover:cursor-pointer icon-container group" onClick={()=>{closeOnboarding()}}>
            <span className="text-[16px] text-red-600 font-[600]">Exit Onboarding </span>
            {IconMap('FaAnglesRight', 'ml-2 transition transform duration-300 ease group-hover:translate-x-[5px] text-red-600',undefined,16)} 
          </div>
        </div>
        {/* <p className="text-[14px]">Please fill in the details of your company below. Providing comprehensive and accurate information helps in generating a precise AI profile. </p> */}
        <div className="mt-4">
          <h3 className="text-[18px] font-[600]">Set Campaign Credits</h3>
          <sub>Note: You could allocate credits for this agent, so you wouldn't be using up all your credits on a single agent. By default it uses your remaining available credit.</sub>
          <Inputs className={'mt-3'} value={entInfo.credits} label={"Credit Amount"} onChange={(e:any) => InputHandler(setEntInfo, 'credits')(e)} type={"number"} />
        </div>

        <Divider />

        <div className="mt-4">
          <h3 className="mt-[2rem] text-[18px] font-[600]">Agent Personality</h3>
        </div>
        <div className="grid lg:grid-cols-4 gap-5">
          {
            aiPersonalities.map((item:any, index: number) => {
              // console.log('item ::: ', item)
              return (
                <div className="flex flex-col justify-end lg:col-span-1" key={index}>
                  <label className="w-full text-gray-700 text-sm font-bold ml-2">{item.name != 'Ava' ? "Coming Soon" : ""}</label>
                  <div className="flex items-center">
                    <RadioButton 
                      inputId={item.id}
                      name={item.id} 
                      value={item.id} 
                      onChange={(e:any) => (changePersonality(e.target.value))} 
                      checked={entInfo.ai_personality_id === item.id} 
                      disabled={item.name != 'Ava'}
                    />
                    <label className={`flex items-center text-sm font-bold ml-2 mb-2 ${item.name !== 'Ava' ? 'text-gray-300' : 'text-gray-700'}`}>
                      {item.name}
                    </label>
                  </div>
                </div>
              );
            })
          }
          <div className="flex flex-col lg:col-span-5">
            <div className="flex flex-col-2 lg:col-span-2">
              Type: {aiDesc.type}
            </div>
            <div className="flex flex-col-2 lg:col-span-2">
              Tone: {aiDesc.tone}
            </div>
          </div>

        </div>
        {/* <div className="mt-[2rem] grid lg:grid-cols-3 gap-5">
        
        </div> */}

        <Divider />

        <h3 className="mt-[2rem] text-[18px] font-[600]">Agent Details</h3>

        <div>
          <div className="mt-[2rem] grid lg:grid-cols-3 gap-5">
            <Inputs label={<>Agent Name? {IconMap('FaQuestionCircle','agent-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".agent-name" content={"Your agent name helps you identify what the business information is for"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.agent_name} placeholder={'Example Agent Name'} onChange={(e:any) => InputHandler(setEntInfo, 'agent_name')(e)} className=""/>
          </div>
          <div className="mt-[2rem] grid lg:grid-cols-3 gap-5">
            <Inputs label={<>What is your website address? {IconMap('FaQuestionCircle','business-site ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-site" content={"Your company's website link"}/></>} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.website} placeholder={'example.com'} onChange={(e:any) => InputHandler(setEntInfo, 'website')(e)} className="" />
            <Inputs label={<>What is your Company Name? {IconMap('FaQuestionCircle','business-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-name" content={"The company name you represent as part of the signature/footer of the outgoing emails"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.company_name} placeholder={'Example Company Name'} onChange={(e:any) => InputHandler(setEntInfo, 'company_name')(e)} className=""/>
            <Inputs label={<>Your Full Name {IconMap('FaQuestionCircle','business-fullname ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-fullname" content={"The name you want to use for signing off the e-mail you will send to your leads"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.full_name} placeholder={'Full Name'} onChange={(e:any) => InputHandler(setEntInfo, 'full_name')(e)} className=""/>
            <Inputs label={<>Email Address {IconMap('FaQuestionCircle','business-emailaddress ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-emailaddress" content={"The Email address as part of the signature/footer of the outgoing emails"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'email'} value={entInfo.email} placeholder={'example@email.com'} onChange={(e:any) => InputHandler(setEntInfo, 'email')(e)} className=""/>
            <Inputs label={<>Calendly Link (Optional){IconMap('FaQuestionCircle','business-calendlylink ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-calendlylink" content={"A meeting link using Calendly to add as part of the e-mail generation"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.calendly_link} placeholder={'https://calendly.com/username'} onChange={(e:any) => InputHandler(setEntInfo, 'calendly_link')(e)} className=""/>
            <Inputs label={<>Phone Number (Optional){IconMap('FaQuestionCircle','business-phone ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-phone" content={"Your Phone Number your leads can contact you on"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'phone'} value={entInfo.phone} onChange={(e:any)=>handlePhoneChange(e)} invalid={true} withValidation={false} />
            <Inputs label={<>Role (Optional){IconMap('FaQuestionCircle','business-role ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-role" content={"Your role in the organization reaching out to the leads"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.role} placeholder={'Your Role'} onChange={(e:any) => InputHandler(setEntInfo, 'role')(e)} className=""/>
            <Inputs label={<>LinkedIn URL (Optional){IconMap('FaQuestionCircle','business-linkedin ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-linkedin" content={"Your LinkedIn Profile to be added as part of the outgoing email and/or signature"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={entInfo.linkedin_url} placeholder={'https://www.linkedin.com/in/username'} onChange={(e:any) => InputHandler(setEntInfo, 'linkedin_url')(e)} className=""/>
            <Inputs label={<>What language should we use? {IconMap('FaQuestionCircle','client-language ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-language" content={"Specify the language you need to use"} /></>} labelClass={'text-[16px] font-[500] mb-2'} type={'dropdown'} value={entInfo.target_language} options={languages} optionLabel={'value'} onChange={(e:any) => InputHandler(setEntInfo, 'target_language')(e)} className="" />
          </div>
          <Inputs label={<>Please provide a description of your business {IconMap('FaQuestionCircle','business-desc ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-desc" content={"What is your business for?"}/> </>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={entInfo.description} onChange={(e:any) => InputHandler(setEntInfo, 'description')(e)} className={"rounded-[8px] md:col-span-2 mt-4 mb-8"} />
          <Inputs label={<>Please provide a description of your target client, market and product {IconMap('FaQuestionCircle','business-target ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-target" content={"Define your ideal customer, target market, and product offering"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={entInfo.target_description} onChange={(e:any) => InputHandler(setEntInfo, 'target_description')(e)} className={"rounded-[8px] md:col-span-2 mb-8"} />
          <label className="text-label flex flex-row items-center">
            Miscellaneous Links (Optional)
            {IconMap('FaQuestionCircle', 'client-keywords ms-1 items-center text-[#585858]', undefined, 16)}
            <Tooltip target=".client-keywords" content={"Add and manage additional links here."} />
          </label>
          <div className="flex flex-col lg:col-span-4 pr-2">
            {(miscLinks||[]).map((link, index) => (
              <div key={index} className="w-full flex flex-row justify-center border-gray border rounded-lg pr-4 mt-3 relative">
                <div className={`absolute flex flex-col h-full top-0 right-[-18px] justify-center items-center `}>
                  {(miscLinks||[]).length > 1 && (
                    <Button className="bg-[#fff] flex z-1" icon="pi pi-minus" rounded outlined severity="danger" aria-label="minus" onClick={() => removeMiscLink(index)}/>
                  )}
                  {index === (miscLinks||[]).length - 1 && (
                    <Button className={`bg-[#fff] z-1 ${(index === (miscLinks||[]).length - 1) && (miscLinks||[]).length > 1 ? 'mt-1': ''}`} icon="pi pi-plus" rounded outlined severity="secondary" aria-label="plus" onClick={addMiscLink}/>
                  )}
                </div>
                <div className="flex grid grid-cols-1 md:grid-cols-2 gap-3 my-2 w-[98%]">
                  <div className="mt-2 w-full">
                    <FloatLabel>
                      <InputText id={`link-desc-${index}`} value={link.description} onChange={handleInputMiscLinks(index, 'description')} className="w-full text-[16px]" placeholder="Link Description" />
                      <label htmlFor={`link-desc-${index}`}>Link Description</label>
                    </FloatLabel>
                  </div>
                  <Inputs label={undefined} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={link.link} className="w-full text-[16px]" placeholder={'example.com'} onChange={handleInputMiscLinks(index, 'link')} />
                </div>
              </div>
            ))}
          </div>
          <div className={`flex flex-col mb-2 rounded-[8px]`}>
            <label className={`flex items-center text-[16px] font-[500] mb-2`}>What country should we search in {IconMap('FaQuestionCircle','client-countries ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".client-countries" content={"Specify the country you are targeting"} /></label>
            {/* <MultiSelect autoOptionFocus={false} focusOnHover={false} selectOnFocus={false} value={entInfo.target_countries} options={countryOptions} onChange={(e: MultiSelectChangeEvent) => setSelectedCountries(e.value)} optionLabel="name" 
              filter placeholder="Select Countries" itemTemplate={countryTemplate} panelFooterTemplate={panelFooterTemplate} className="w-full md:w-20rem" display="chip" /> */}
            <Dropdown value={entInfo.target_countries[0]}
              options={countryOptions} 
              onChange={(e: any) => 
                setSelectedCountries([e.value])
              } 
              placeholder="Select Country"
              optionLabel="name"
              optionValue="value"
              itemTemplate={countriesTemplate}
              valueTemplate={countryTemplate}
              filter
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Inputs 
              label={
                <>
                  <span className="flex items-center">
                    Please provide a list of keywords for your potential leads you are targeting 
                    {IconMap('FaQuestionCircle','client-targets ms-1 items-center text-[#585858]',undefined,16)}
                    <Tooltip target=".client-targets" content={"Enter keywords related to your target leads"}/>
                  </span>
                  <span>
                    <Button onClick={() => {
                      setEntInfo((prevValues: AgentProps) => ({
                        ...prevValues,
                        target_keywords: [],
                      }));
                    }} className="bg-purple text-white rounded-full px-[10px] py-[1px]" icon="pi pi-times-circle" label="Clear" />
                  </span>
                </>
              } 
              labelClass="text-[16px] font-[500] mb-2 flex items-center gap-2" 
              type="chips" 
              value={entInfo.target_keywords} 
              onChange={handleInputsChange}
              pt={{
                container: { className: 'overflow-auto' },
                token: { className: 'my-1' }
              }}
            />
            <Inputs 
              label={
                <>
                  <span className="flex items-center">
                    Please provide a list of negative keywords to exclude on your potential leads you are targeting 
                    {IconMap('FaQuestionCircle','client-targets ms-1 items-center text-[#585858]',undefined,16)}
                    <Tooltip target=".client-targets" content={"Enter keywords related to your target leads"}/>
                  </span>
                  <span>
                    <Button onClick={() => {
                      setEntInfo((prevValues: AgentProps) => ({
                        ...prevValues,
                        negative_keywords: [],
                      }));
                    }} className="bg-purple text-white rounded-full px-[10px] py-[1px]" icon="pi pi-times-circle" label="Clear" />
                  </span>
                </>
              } 
              labelClass="text-[16px] font-[500] mb-2 flex items-center gap-2" 
              type="chips" 
              value={entInfo.negative_keywords} 
              onChange={handleNegativeInputsChange}
              pt={{
                container: { className: 'overflow-auto' },
                token: { className: 'my-1' }
              }}
            />
            <Inputs 
              label={
                <>What offer / CTA do you want to provide these potential customers? 
                  {IconMap('FaQuestionCircle', 'client-customers ms-1 items-center text-[#585858]', undefined, 16)}
                  <Tooltip target=".client-customers" content={"Describe the products, services, or promotions you want to offer to potential customers"} />
                </>
              } 
              labelClass="text-[16px] font-[500] mb-2" 
              type="textarea" 
              rows={5} 
              value={entInfo.offers} 
              onChange={(e:any) => InputHandler(setEntInfo, 'offers')(e)} 
              className="rounded-[8px] md:col-span-2 mb-8" 
            />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default SummaryStep;
