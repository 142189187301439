import { useEffect, useState } from "react";
import { useAxios } from "../../../contexts/AxiosContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import RoleModal from "../../../components/modals/settings/roles/RoleModal";
import { useToast } from "../../../contexts/ToastContext";

const Roles: React.FC<any> = ({setLoadingState}) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [roles, setRoles] = useState([]);

  const [roleModal, setRoleModal] = useState(false);
  const [roleType, setRoleType] = useState('add');

  const getRoles = () => {
    axiosService.api.get('/api/roles').then((response: any) => {
      console.log(response.data);
      setRoles(response.data);
    }).catch((error: any) => {
      console.log(error);
    })
  }

  useEffect(()=>{
    getRoles();
  }, []);

  const handleCheckboxChange = (value: any, accessPage: any) => {
    setLoadingState(true);
    const postData = {
      has_access: value,
      id: accessPage.id,
      role_id: accessPage.role_id,
      page_id: accessPage.page_id,
    }

    axiosService.api.post('/api/roles/update-access', postData).then((response: any) => {
      showToast({
        severity: response.data.status ? 'success' : 'error',
        summary: response.data.status ? 'Success!' : 'Failed!',
        detail: response.data.message
      });
      getRoles();
      setLoadingState(false);

    }).catch((error: any) => {
      console.log(error);
      setLoadingState(false);

    });
  }

  return (
    <>
      <div id="roles" className="flex flex-col gap-3">
        <div className="title flex justify-between items-center py-1">
          <h1 className="text-2xl">Roles</h1>
          <div className="flex gap-2">
            <Button label="Add Role" icon="pi pi-plus" className="add-role bg-purple rounded-lg border-purple" onClick={() => {setRoleModal(true); setRoleType('add');}} />
            <Button disabled label="Add Page" icon="pi pi-plus" className="add-page bg-purple rounded-lg border-purple" />
          </div>
        </div>

        <div className="panel bg-white rounded-lg p-5 min-h-[700px]">

          {
            roles && roles.length > 0 && (
              <Accordion multiple activeIndex={[0]} className="flex flex-col gap-2">
                {
                  roles.map((role: any, i: number) => {
                    return <AccordionTab key={i} header={role.name.replace(/^./, role.name[0].toUpperCase())} pt={{
                        headerAction: {className: 'border-none bg-purple-light rounded-lg font-normal text-black'},
                        content: {className: 'rounded-bl-lg rounded-br-lg border-purple-light border-2'}
                      }}>
                        <div className="grid lg:grid-cols-4 gap-5">
                          {
                            role.role_access.map((accessPage: any, j: number) => {
                              return <div key={j} className="border rounded-lg flex items-center p-4 justify-between">
                                <p className="text-label select-none">{accessPage.page.parent ? accessPage.page.parent.name + " | " + accessPage.page.name : accessPage.page.name}</p>
                                <InputSwitch disabled={role.id == 2 || role.id == 3} onChange={(e) => handleCheckboxChange(e.value, accessPage)} checked={accessPage.has_access === 1 } />
                              </div>
                            })
                          }
                        </div>
                      </AccordionTab>
                  })
                }
                
              </Accordion>
            )
          }
          

        </div>

        <RoleModal visible={roleModal} type={roleType} refreshTables={getRoles} onHide={() => setRoleModal(false)} setLoadingState={setLoadingState}/>
      </div>
    </>
  );
}

export default Roles;
