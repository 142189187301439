import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from "primereact/avatar";
import IconMap from "../../components/Icons/IconMaps";
import { TabView, TabPanel } from 'primereact/tabview';
import Inputs from "../../components/forms/Inputs";
import { Tooltip } from "primereact/tooltip";

const ViewProfile: React.FC<any> = ({ setLoadingState, credInfo}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const navigateToPage = () => {
    if(activeIndex === 0){
      navigate(`/main?page=settings-profile`);
    }else{
      navigate(`/main?page=settings-company`);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 2000);
  }, []);
  return (
    <>
      <div className="panel relative bg-white rounded-lg m-5 p-5 min-h-[40rem] z-0">
        <div className="mb-[30px]">
          <div className="h-[109px] rounded-tr-lg bg-gradient-to-r from-[#774dff] to-[#43c8ff]"></div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col md:flex-row items-center justify-center ">
              <div className="relative ">
                <div className="flex items-center justify-center mt-[-40px] w-[130px] h-[130px] z-1 border-[10px] border-[#fff] rounded-[50%] shadow-md overflow-hidden">
                  <Avatar color="white" icon="pi pi-user" size="xlarge" shape="square" style={{ backgroundColor: '#CDCECF', width:'130px', height:'130px' }} />
                </div>
              </div>
              <div className="pl-[20px]">
                <h2 className="text-[18px] text-[#092c4c] font-[700]">{credInfo.firstname}</h2>
                <h4 className="text-[14px] text-[#777] font-[700]">You can update your photo and personal details.</h4>
              </div>
            </div>
            <div className=" relative flex flex-col md:flex-row items-center justify-center ">
              <div className="absolute top-[-4rem] right-[1rem]">
                <div className="flex items-center justify-center bg-[#fff] h-[36px] w-[36px] rounded-[100%] hover:bg-[#BDBDBD] shadow-md cursor-pointer " onClick={navigateToPage}>
                  {IconMap('FiEdit', undefined, undefined, 24)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabView  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Personal Profile">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10">
              <Inputs label={'First Name'} type={'text'} value={credInfo.firstname} onChange={undefined} disabled={true}  />
              <Inputs label={'Last Name'} type={'text'} value={credInfo.lastname} onChange={undefined} disabled={true}/>
              <Inputs label={'Email'} type={'email'} value={credInfo.email} onChange={undefined} disabled={true} />
              <Inputs label={'Phone Number'} type={'phone'} value={credInfo.phone} onChange={undefined} disabled={true}   />
            </div>
          </TabPanel>
          <TabPanel header="Company Profile">
            <div className="panel relative bg-white rounded-lg m-5 p-5 min-h-[40rem] z-0">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3rem">
                <Inputs label={<>Website Address {IconMap('FaQuestionCircle','business-site ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-site" content={"Your company's website link"}/></>} grouped={true} prefix={'https://'} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.website} placeholder={'example.com'} onChange={undefined} className="" disabled={true} />
                <Inputs label={<>Company Name {IconMap('FaQuestionCircle','business-name ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-name" content={"The company name you represent as part of the signature/footer of the outgoing emails"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.company_name} placeholder={'Example Company Name'} onChange={undefined} className="" disabled={true}/>
                <Inputs label={<>Calendly Link (Optional){IconMap('FaQuestionCircle','business-calendlylink ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-calendlylink" content={"A meeting link using Calendly to add as part of the e-mail generation"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.calendly_link} placeholder={'https://calendly.com/username'} onChange={undefined} className="" disabled={true}/>
                <Inputs label={<>Role (Optional){IconMap('FaQuestionCircle','business-role ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-role" content={"Your role in the organization reaching out to the leads"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.role} placeholder={'Your Role'} onChange={undefined} className="" disabled={true}/>
                <Inputs label={<>LinkedIn URL (Optional){IconMap('FaQuestionCircle','business-linkedin ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-linkedin" content={"Your LinkedIn Profile to be added as part of the outgoing email and/or signature"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'text'} value={credInfo.linkedin_url} placeholder={'https://www.linkedin.com/in/username'} onChange={undefined} className="" disabled={true}/>
              </div>
              <Inputs label={<>Business Description {IconMap('FaQuestionCircle','business-desc ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-desc" content={"What is your business for?"}/> </>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={credInfo.company_description} onChange={undefined} className={"rounded-[8px] md:col-span-2 mt-4 mb-8"} disabled={true}/>
              <Inputs label={<>Target Description {IconMap('FaQuestionCircle','business-target ms-1 items-center text-[#585858]',undefined,16)}<Tooltip target=".business-target" content={"Define your ideal customer, target market, and product offering"}/></>} labelClass={'text-[16px] font-[500] mb-2'} type={'textarea'} rows={5} value={credInfo.target_description} onChange={undefined} className={"rounded-[8px] md:col-span-2 mb-8"} disabled={true}/>
                

            </div>
            {/* Company Profile content */}
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default ViewProfile;
