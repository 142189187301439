import { useEffect, useState } from "react";
import { useAxios } from "../../../contexts/AxiosContext";
import LazyTable from "../../../components/tables/LazyTable";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useToast } from "../../../contexts/ToastContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import Inputs from "../../../components/forms/Inputs";
import { InputHandler } from "../../../services/EventHandlers";

const SuperAdminAccounts:React.FC<any> = ({ setLoadingState }) => {
  const axiosService = useAxios();
  const { showToast } = useToast();
  const [visible, setVisible] = useState(false);
  const [api, setApi] = useState<any>('/api/superadmin/accounts');
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [disabledDeac, setDisabledDeac] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [issSending, setIsSending] = useState<boolean>(false);
  const userPlaceholder = {
    firstname: '---',
    lastname: '---',
    email: '---',
    email_verfified_at: '---',
    phone: '---',
    deleted_at: '---',
    total_credits: '0'
  };
  const [user, setUser] = useState(userPlaceholder);
  const accountsTableColumn = [
    { field: 'firstname', header: 'Firstname' },
    { field: 'lastname', header: 'Lastname' },
    { field: 'email', header: 'Status'},
    { field: 'email_verified_at', header: 'Verified At'},
    { field: 'role_name', header: 'Role'},
    { field: 'phone', header: 'Phone'},
    { field: 'deleted_at', header: 'Deactivated At'},
  ];
  const [filterValues, setFilterValues] = useState<any>({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  });

  const handleDataConvert = (data: any, setTableData: Function) => {
    setTableData(data.map((e:any) => ({
      id: e.id,
      firstname: e.firstname,
      lastname: e.lastname,
      email: e.email,
      email_verified_at: e.email_verified_at,
      phone: e.phone,
      deleted_at: e.deleted_at,
      role_name: e.role_name
    })));
  };


  const [subscriptionApi, setSubscriptionApi] = useState<string>(`/api/superadmin/account/subscriptions/${selectedId}`);
  const [subRefresh, setSubRefresh] = useState<boolean>(false);
  const subsTableColumns = [
    { field: 'created_at', header: 'Date' },
    { field: 'stripe_price', header: 'Price' },
    { field: 'quantity', header: 'Credit Quantity'},
  ];
  const handleSubDataConvert = (data: any, setTableData: Function) => {
    setTableData(data.map((e:any) => ({
      id: e.id,
      created_at: e.created_at,
      stripe_price: e.stripe_price,
      quantity: e.quantity,
    })));
  };

  const isAccountActive = (account:any) => {
    return account.deleted_at != null;
  };

  const deleteUser = (id: number) => {
    setLoadingState(true);
    axiosService.api.delete('/api/superadmin/deactivate/' + id)
    .then((response:any) => {
      setLoadingState(false);
      setRefreshTable(true);
      showToast({
        severity: 'success',
        summary: 'Deactivated Account!',
        detail: response.data.message
      });
      setUser(response.data.details);
    }).catch((error:any) => {
      setLoadingState(false);
      const errorMessage = error.response.data.message;
      showToast({
        severity: 'error',
        summary: 'Error!',
        detail: errorMessage
      });
    });
  };

  const confirmUserDelete = (id: number) => {
    confirmDialog({
      message: 'Do you want to deactivate this user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteUser(id)
    });
  };

  const reactivateUser = (id: number) => {
    console.log("reactivate user");
    axiosService.api.get('/api/superadmin/reactivate/' + id)
    .then((response:any) => {
      setRefreshTable(true);
      showToast({
        severity: 'success',
        summary: 'Reactivated Account!',
        detail: response.data.message
      });
      setUser(response.data.details);
    }).catch((error:any) => {
      setLoadingState(false);
      const errorMessage = error.response.data.message;
      showToast({
        severity: 'error',
        summary: 'Error!',
        detail: errorMessage
      });
    });
  }

  const confirmUserReactivate = (id: number) => {
    confirmDialog({
      message: 'Do you want to reactivate this user?',
      header: 'Reactivation Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => reactivateUser(id)
    });
  };

  const actionsClicked = (data: any, type: string) => {
  
    setUser(userPlaceholder);
    setSelectedId(data);
    setSubscriptionApi(`/api/superadmin/account/subscriptions/${data}`);
    setDisabledDeac(false);
    setLoadingState(true);
    
    if(type === 'view'){
      setVisible(true);
      axiosService.api.get(`/api/superadmin/account/${data}`)
        .then((account:any) => {
          if(account.data){
            setUser(account.data);
            setDisabledDeac(isAccountActive(account.data));
          }
          setLoadingState(false);
          
        }).catch((error:any) => {
          setLoadingState(false);
          const errorMessage = error.response.data.message;
          showToast({
            severity: 'error',
            summary: 'Error!',
            detail: errorMessage
          });
        });
    } else if(type=='trash'){
      setLoadingState(false);
      // confirmUserDelete(data);
    } else if(type=='login'){
      setLoadingState(false);
      window.open(`/autologinlgzrrmgoz3/${data}`, '_blank')
    } 
    
  };

  const handleBulkDelete = (selectedData: any) => {
    setLoadingState(true);
    const ids = selectedData.map((e: any) => e.id);
  }

  const manageCredit = (type:any) => {
    const url = `/api/superadmin/manage-credit/${type}`;
    const creditDetails = {
      amount: creditAmount,
      user_id: selectedId
    };

    if(creditAmount == 0){
      showToast({
        severity: 'warn',
        summary: 'Invalid Amount!',
        detail: "Amount cannot be 0"
      });

      return null;
    }

    confirmDialog({
      message: `Do you want to ${type} ${creditAmount} credit/s to this user?`,
      header: 'Credit Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => {
        setIsSending(true);
        axiosService.api.post(url, creditDetails)
          .then((response:any) => {
            setIsSending(false);
            if(response.data.status){
              setCreditAmount(0);
              showToast({
                severity: 'warn',
                summary: 'Email Sent!',
                detail: response.data.message
              });
            }else{
              showToast({
                severity: 'error',
                summary: 'Invalid!',
                detail: response.data.message
              });
            }
          }).catch((error:any) => {
            setIsSending(false);
          });
      }
    });
  }

  useEffect(() => {
    const status = isAccountActive(user);
    setDisabledDeac(status);
  }, [user]);

  useEffect(() => {
    console.log("disabledDeac::", disabledDeac);
  }, [disabledDeac]);

  useEffect(() => {
    setLoadingState(false);
  }, []);

  useEffect(() => {
    console.log("subscriptionApi::", subscriptionApi);
  }, [subscriptionApi]);
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="title flex justify-between items-center">
          <h1 className="text-2xl">Accounts</h1>
          {/* <Button onClick={() => handleAgentModal('add')} label="Add Agent" icon="pi pi-plus" className="bg-purple rounded-lg border-purple ring-0" /> */}
        </div>
        <ConfirmDialog/>
        <Dialog header="View Account" visible={visible} onHide={() => setVisible(false)}
            style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div>
                <label htmlFor="">Firstname</label>
                <p>{user.firstname}</p>
              </div>
              <div>
                <label htmlFor="">Lastname</label>
                <p>{user.lastname}</p>
              </div>
              <div>
                <label htmlFor="">Email</label>
                <p>{user.email}</p>
              </div>
              <div>
                <label htmlFor="">Phone</label>
                <p>{user.phone}</p>
              </div>
              <div>
                <label htmlFor="">Verified At</label>
                <p>{user.email_verfified_at}</p>
              </div>
              <div>
                <label htmlFor="">Deactivated At</label>
                <p>{user.deleted_at}</p>
              </div>
              <div>
                <label htmlFor="">Total Credits</label>
                <p>{(user.total_credits != null) ? user.total_credits : 0}</p>
              </div>
            </div>
            <div>
              <Accordion>
                  <AccordionTab header="Subscriptions">
                    <LazyTable
                      api={subscriptionApi}
                      convertData={true} 
                      convertDataFunction={handleSubDataConvert} 
                      refreshTable={refreshTable} 
                      setRefreshTable={setRefreshTable}
                      // hasOptions={true}
                      // checkbox={true}
                      columns={subsTableColumns}
                      // actions={true}
                      action_types={{ }}
                      actionsClicked={actionsClicked}
                      expandableRow={false}
                      bulkOptions={[
                        // {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
                      ]}
                      // filterValues={filterValues}
                    />
                  </AccordionTab>
                  <AccordionTab header="Credit Management">
                    <div className="grid grid-cols-3 w-full">
                      <div className="justify-center col-span-1">
                          <Inputs value={creditAmount} 
                            onChange={(e:any) => {
                              setCreditAmount(e.value);
                            }} 
                            label={"Credit Amount"} 
                            type={"number"} />
                      </div>
                      <div className="grid grid-cols-4 col-span-2 pt-5">
                        <div className="col-span-1">
                          <Button 
                            label="Add Credit" 
                            className="bg-purple rounded-lg border-purple"
                            onClick={() => manageCredit('add')}
                            disabled={issSending}
                            icon={issSending ? "pi pi-spin pi-spinner" : null}
                            iconPos="right"
                          />
                        </div>
                        <div className="col-span-1">
                          <Button 
                            label="Deduct Credit"
                            className="bg-red-500 rounded-lg border-red-500"
                            onClick={() => manageCredit('deduct')}
                            disabled={issSending}
                            icon={issSending ? "pi pi-spin pi-spinner" : null}
                            iconPos="right"
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionTab>
              </Accordion>
            </div>
            <div className="mt-10 flex justify-center">
              {
                (disabledDeac) ? 
                  <Button label="Reactivate Account" 
                    onClick={(e:any) => confirmUserReactivate(e.target.dataset.id)} 
                    // disabled={disabledDeac} 
                    data-id={selectedId} 
                    className="w-[20%] bg-purple rounded-lg border-purple" 
                  />
                :
                  <Button label="Deactivate Account" 
                    onClick={(e:any) => confirmUserDelete(e.target.dataset.id)} 
                    // disabled={disabledDeac} 
                    data-id={selectedId} 
                    className="w-[20%] bg-red-500 rounded-lg border-red-500" 
                  />
              }
              
            </div>
        </Dialog>
        <div className="panel bg-white rounded-lg p-5">
          <LazyTable
            api={api}
            apiAll={`/api/superadmin/select-all`}
            convertData={true} 
            convertDataFunction={handleDataConvert} 
            refreshTable={subRefresh} 
            setRefreshTable={setSubRefresh}
            hasOptions={true}
            checkbox={true}
            columns={accountsTableColumn}
            actions={true}
            action_types={{ view: true, sign_in: true }}
            actionsClicked={actionsClicked}
            expandableRow={false}
            bulkOptions={[
              {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
            ]}
            filterValues={filterValues}
          />
        </div>
      </div>
    </>
  );
};

export default SuperAdminAccounts;
