import React, { useEffect, useState } from "react";
import IconMap from "../../components/Icons/IconMaps";
import { useNavigate } from "react-router-dom";
import BusinessStep from "./steps/BusinessStep";
import ClientsStep from "./steps/ClientsStep";
import SummaryStep from "./steps/SummaryStep";
import Navbar from "../../components/navigations/Navbar";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useAxios } from "../../contexts/AxiosContext";
import { Button } from "primereact/button";
import { useToast } from "../../contexts/ToastContext";
import { Sidebar } from "primereact/sidebar";
import { AgentProps } from "../../interface/datatypes";

const Onboarding: React.FC<any> = ({setLoadingState}) => {
  const { showToast } = useToast();
  const [isGenerated, setIsGenerated] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [buttonText, setButtonText]=useState('Submit Onboarding')
  const axiosService = useAxios();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<any>(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stepFinish, setStepFinish] = useState<any[]>([]);
  const [defaultPrompt, setDefaultPrompt] = useState<any>();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [blueprintTemplates, setBlueprintTemplates] = useState([]);
  const [allTemplates, setAllTemplates] = useState(null);
  const [checkKeys, setCheckKeys] = useState<any>([]);

  const steps = [
    { id: 1, title: "YOUR BUSINESS", },
    { id: 2, title: "YOUR CLIENTS",  },
    { id: 3, title: "SUMMARY" },
  ];

  const [entInfo, setEntInfo] = useState<any>({
    agent_name:'',
    website: '',
    company_name: '',
    full_name: '',
    email: '',
    calendly_link: '',
    phone: '',
    role: '',
    linkedin_url: '',
    description: '',
    target_description: '',
    target_keywords: [],
    target_countries: [],
    target_language: 'English',
    offers: '',
    tools: {
      google_search: false,
      google_places: false,
      linkedin: false,
    },
    suggested_sales_profile: '',
    suggested_pitch: '',
    suggested_keywords: [],
    credits: 0,
    ai_personality_id: 0,
    address: '',
    miscellaneous_links: [],
    negative_keywords: [],
    existing_agent:'',
    // target_contact_person_seniority_levels: [],
    target_contact_person_management_levels: [],
    target_contact_person_departments: [],
    outreach_template: ""
  });

  const initCountries = () => {
    axiosService.api.get(`/api/countries/get-countries`)
      .then((response: any) => {
        const countriesData = response.data;
        // console.log('countriesData :: ', countriesData)
        const countriesFormatted = countriesData.map((country: any) => ({
          name: country.name,
          value: country.id,
          flag: country.flag
        }));
        // console.log('countriesFormatted :: ', countriesFormatted)
        setCountryOptions(countriesFormatted);
      })
      .catch((error: any) => {
        console.error("Error fetching countries:", error);
      });
  };

  const initAgents = async () => {
    try {
      await axiosService.api.get('/api/agents/all')
      .then((response:any)=>{
        const agents = response.data; 
        const formattedAgents = agents.map((agent:any) => ({
          label: agent.name,
          value: agent.id,
        }));
        setAgentOptions(formattedAgents);
      })
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const initData = async () => {
    try {
      const [userDataResponse,userCompanyResponse, agentsLatestResponse] = await Promise.all([

        axiosService.api.get('/api/user/current'),
        axiosService.api.get('/api/company/get'),
        axiosService.api.get('/api/agents/latest'),
      ]);
  
      const userData = userDataResponse.data || {};
      const userCompanyData = userCompanyResponse.data.company || {};
      const agentsLatestData = agentsLatestResponse.data.latest || {};

      // console.log('userCompanyData ::: ', userCompanyData);
      // console.log('userData ::: ', userData);
      // console.log('agentsLatestData ::: ', agentsLatestData);
  
      setEntInfo((prevState:AgentProps) => ({
        ...prevState,
        website: checkValue(userCompanyData.website) || '',
        company_name: checkValue(userCompanyData.company_name) || '',
        full_name: checkValue(userData.name)|| '',
        email: checkValue(userData.email) || '',
        phone: checkValue(userData.phone) || '',
        calendly_link: checkValue(userCompanyData.calendly_link) || '',
        linkedin_url: checkValue(userCompanyData.linkedin_url) || '',
        role: checkValue(userCompanyData.role) || '',
        description: checkValue(userCompanyData.business_description) || '',
        target_description: checkValue(userCompanyData.target_description) || '',
        target_keywords: checkValue(agentsLatestData.target_keywords) || [],
        target_countries: checkValue(agentsLatestData.target_countries) || [],
        target_language: checkValue(agentsLatestData.target_language) || 'English',
        offers: checkValue(agentsLatestData.offers) || '',
        credits: agentsLatestData.credits || 0,
        ai_personality_id: agentsLatestData.ai_personality_id || 0,
        address: agentsLatestData.address || '',
        miscellaneous_links: agentsLatestData.miscellaneous_links || '',
        // target_contact_person_seniority_levels: agentsLatestData.target_contact_person_seniority_levels || [],
        target_contact_person_management_levels: agentsLatestData.target_contact_person_management_levels || [],
        target_contact_person_departments: agentsLatestData.target_contact_person_departments || [],
        search_scope: agentsLatestData.search_scope || [],
      }));

      setLoadingState(false);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOnHide = () => {
    setSummaryModal(false);
    setIsSubmitting(false);
    setButtonText('Submit Onboarding');
  }

  const handleOnSubmit = () => {
    showToast({      
      severity: 'info',      
      summary: 'Processing',      
      detail: 'Please wait...'    
    });

    setIsSubmitting(true);
    setButtonText('Submitting');

    // console.log("entInfo::", entInfo);
    setEntInfo((prevState: AgentProps) => ({
      ...prevState,
      website: 'https://' + entInfo.website,
      calendly_link: 'https://' + entInfo.calendly_link,
      linkedin_url: 'https://' + entInfo.linkedin_url,
    }));

    axiosService.api.post('/api/agents/onboarding', entInfo).then((postData:any) => {
      if(postData.data.status){
        setLoadingState(false);
        navigate('/main'); // Redirect to main temporarily
        setTimeout(() => {
          showToast({ severity: 'success', summary: 'Success!', detail: 'Successfully created a new agent.', });
        },500);
      }
    }).catch((error:any) => {
      setLoadingState(false);
      setIsSubmitting(false);
      showToast({
        severity: 'warn',
        summary: 'Credits are low!',
        detail: error.response.data.message
      });
    });
    return;
  }

  const checkFields = (keys:any) => {
    let emptyKeys:any = [];
    keys.forEach((key:any) => {
      if(entInfo[key] == null || entInfo[key] == "" || entInfo[key].length == 0){
        emptyKeys.push(key);
      }
    });

    return emptyKeys;
  };

  const nextStep = (e:any) => {
    let emptyKeys = [];
    if(activeStep == 1) emptyKeys = checkFields(['agent_name', 'company_name']);

    if(emptyKeys.length != 0){
      showToast({      
        severity: 'error',      
        summary: 'Empty Fields!',      
        detail: 'Please fill up all required fields.'    
      });

      setCheckKeys(emptyKeys);
      return null;
    }

    setIsGenerated(false);
    const initStep = activeStep + 1;
    if(initStep > 3){
      handleOnSubmit();
    } else {
      setCheckKeys([]);
      setActiveStep(initStep);
    }
    
  };

  const previousStep = (e:any) => {
    setLoadingState(true);
    setIsGenerated(true);
    const initStep = activeStep - 1;
    if(initStep < 1){
      return;
    }

    setActiveStep(initStep);

    setTimeout(() => {
      setLoadingState(false);
    }, 1000);
  };

  const getDefaultPrompt = () => {
    axiosService.api.get('/api/gpt-prompt/default-prompt')
      .then((response:any) => {
        setDefaultPrompt(response.data);
      });
  };

  const usableCredits = () => {
    axiosService.api.get('/api/agents/usable/credits')
      .then((response:any) => {
        setEntInfo((prevValues:AgentProps) => ({
          ...prevValues,
          credits: response.data.usable_credits
        }));
      });
  };

  const closeOnboarding = () => {
    confirmDialog({
      message: 'Your onboarding progress will be saved, and you can continue later.',
      // header: 'Submit Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => navigateToPage()
    });
  };

  const navigateToPage = () => {
    navigate(`/main?page=campaigns`);
  };
  
  const checkValue = (value:any, defaultValue = '') => (value !== undefined && value !== null) ? value : defaultValue;

  const getBluprints = () => {
    axiosService.api.get('/api/templates/get-all')
    .then((response:any) => {
      if(response.data != null){
        setAllTemplates(response.data);
      }
    });
  };

  useEffect(() => {
    getDefaultPrompt();
    usableCredits();
    initAgents();
    // initData();
    setLoadingState(false);
    initCountries();
    getBluprints();
  }, []);

  return (
    <>

    <div className="flex h-[100vh] overflow-hidden" >
      <Navbar axiosService={axiosService}/>

      <div className="flex flex-col items-start justify-between bg-[#0E1833] w-[17%]"> {/* STEP CONTAINER */}
        <div className="flex flex-col">
          <div className="flex flex-col text-white p-[20px] mt-[6rem]">{/* STEPS */}
            {steps.map((step:any, index: any)=>(
              <div key={index} className={`step relative flex items-center mb-[50px] ${step.id === activeStep ?'active':''} ${step.id < activeStep ?'done':''}`}>
                <div className={`step-number w-[40px] h-[40px] rounded-full bg-[#2E3C43] text-white text-center leading-[40px] mr-[15px] font-bold z-[10] flex justify-center items-center`}>{/* STEP NUMBER */}
                  {step.id < activeStep? IconMap('FaCheck'):step.id}
                </div>
                <div className="hidden sm:block text-white font-[900] text-[13px] leading-[28px]">{/* STEP TITLE */} {step.title} </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center mb-[30px] w-full"> {/* footer */}
          <span className="text-[12px] text-[#8D93A1] font-[400]">© SectorSift 2024</span>
        </div>
      </div>
      <div className="mt-[80px] overflow-hidden flex flex-col justify-between bg-[#F9F9F9] w-full "> {/* STEP BODY */}
        <div className="fixed right-0 top-[50vh]" style={{zIndex:'99'}}>
          {/* <Button className="flex flex-col bg-[#3023ae] border-[#3023ae] focus:b" pt={{label:{className:''}}} onClick={() => setSidebarVisible(true)}>
            <span>Need</span>
            <span>help?</span>
          </Button> */}
          <Button className="bg-[#3023ae] border-[#3023ae] rounded-none focus:b h-[50px] w-[35px] p-[0px]" label={`Need help?`} pt={{label:{className:'-rotate-90 leading-none text-[13px]', style: { lineHeight: '1' }}}} onClick={() => setSidebarVisible(true)} />
        </div>
        <div className="overflow-y-scroll relative h-full"> {/* BODY WRAPPER */}
          {activeStep === 1 && <BusinessStep checkKeys={checkKeys} setEntInfo={setEntInfo} entInfo={entInfo} defaultPrompt={defaultPrompt} setLoadingState={setLoadingState} setIsGenerated={setIsGenerated} closeOnboarding={closeOnboarding} countryOptions={countryOptions} agentOptions={agentOptions} checkValue={checkValue}/>}
          {activeStep === 2 && <ClientsStep setEntInfo={setEntInfo} entInfo={entInfo} defaultPrompt={defaultPrompt} setLoadingState={setLoadingState} setIsGenerated={setIsGenerated} isGenerated={isGenerated} countryOptions={countryOptions} closeOnboarding={closeOnboarding} allTemplates={allTemplates}/>}
          {activeStep === 3 && <SummaryStep setEntInfo={setEntInfo} entInfo={entInfo} defaultPrompt={defaultPrompt} setLoadingState={setLoadingState} setIsGenerated={setIsGenerated} isGenerated={isGenerated} countryOptions={countryOptions} checkValue={checkValue} agentOptions={agentOptions} closeOnboarding={closeOnboarding}/>}
          {/* {activeStep === 3 && <CRMStep  setLoadingState={setLoadingState} />} */}
        </div>

        <div className="p-3 flex flex-col md:flex-row justify-between items-center shadow-top z-10 sm:item-stretch"> {/* BODY FOOTER */}
          <div className="text-left md:w-3/4 mb-4 md:mb-0"> {/* ONBOARDING PROGRESS  */}
            <span className="text-lg md:text-xl font-bold"> Complete your onboarding setup</span>
            <div className="text-base md:text-lg text-[#333]"> {/* PROGRESS INDICATOR  */}
              {steps.map((step:any, index: any) => (
                <span key={index} className={`next-step mr-0 md:mr-2 text-[#C0C7CE] text-[14px] font-bold leading-6 md:leading-7 tracking-tight ${step.id === activeStep ? 'active':''}`}>{step.title}</span>
              ))}
            </div>
          </div>
          <div className="flex w-full md:w-1/4 md:m-2 justify-between md:justify-around"> {/* PROGRESS BUTTONS  */}
            <Button tooltip={`${activeStep === 1 ? '':'Go back to previous page'}`} tooltipOptions={{ showOnDisabled: true, position: 'left' }}  onClick={previousStep} className={`flex items-center mr-2 md:mr-0 mb-2 md:mb-0 py-2 px-3 text-[15px] text-white font-bold leading-6 md:leading-7 rounded-md bg-[#3C35A7] ${activeStep === 1 ? 'invisible' : ''}`} id="stepper-prev">
              {IconMap('FaChevronLeft')}PREVIOUS
            </Button>

            <Button tooltip={activeStep === 3 ? 'Finish onboarding' : (isGenerated ? '' : (activeStep === 1 ? 'Must generate Ai Profile' : 'Must generate keywords & pitch'))} tooltipOptions={{ showOnDisabled: true, position: 'left' }} disabled={!isGenerated && activeStep!==3} onClick={nextStep} className="flex items-center mr-2 md:mr-0 mb-2 md:mb-0 py-2 px-3 text-[15px] text-white font-bold leading-6 md:leading-7 rounded-md bg-[#3C35A7] disabled:opacity-50 " id="stepper-next">
              {activeStep === 3 ? 'SUBMIT' : <>{'NEXT '} {IconMap('FaChevronRight')}</>}
            </Button>
          </div>
        </div>
      </div>
      <Sidebar className="w-[30rem] sm:w-[45rem]" visible={sidebarVisible} position="right" onHide={() => setSidebarVisible(false)}>
        {/* <div className="absolute left-[-37px] top-[50vh]">
          <Button className="bg-[#3023ae] border-[#3023ae]" icon="pi pi-arrow-right" onClick={() => setSidebarVisible(false)} />
        </div> */}
        {activeStep == 1 && 
          <div>
            <h2>Busines Step Video</h2>
          </div>
        }
        {activeStep == 2 && 
          <div>
            <h2>Client Step Video</h2>
          </div>
        }
        {activeStep == 3 && 
          <div>
            <h2>CRM Step Video</h2>
          </div>
        }
      </Sidebar>
      {/* <OnboardingSummaryModal setData={setEntInfo} data={entInfo} visible={summaryModal} isSubmitting={isSubmitting} buttonText={buttonText}  onSubmit={handleModalOnSubmit} onHide={handleOnHide} setLoadingState={setLoadingState} /> */}
    </div>
    <ConfirmDialog/>
    </>
  );
};

export default Onboarding;
