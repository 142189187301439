import { useEffect } from "react";
import { useAxios } from "../../contexts/AxiosContext";
import { Logo } from "../../components/Icons/ImgPath";
import { useToast } from "../../contexts/ToastContext";

// credit verification step
const ViC95burK4ziV0H4zG1mavuHm:React.FC<any> = ({setLoadingState}) => {
  const { showToast } = useToast();
  const axiosService = useAxios();

  const verification = (ipAddress:any) => {
    const params = new URLSearchParams(window.location.search);
    const serial = params.get("serial");
    const uid = params.get("uid");
    const action:any = params.get("action");
    const amount:any = params.get("amount");
    const url = (action == 'add') ? 
      `/api/ViC95burK4ziV0H4zG1mavuHm/BkBiZNdF7AD0F09bt27dPpOmR/${serial}/${uid}?ip=${ipAddress}` 
      : `/api/BkBiZNdF7AD0F09bt27dPpOmR/ViC95burK4ziV0H4zG1mavuHm/${serial}/${uid}/${amount}?ip=${ipAddress}`  ;

    axiosService.api.get(url)
      .then((response:any) => {
        console.log("data::", response.data);
        if(response.data.status){
          window.close();
        }else{
          showToast({
            severity: 'error',
            summary: 'Invalid!',
            detail: response.data.message
          });

          setTimeout(() => {
            window.close();
          }, 2000)
        }
      });
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        // console.log('Your IP address is: ', data.ip);
        verification(data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }, []);
  return (
    <>
      <div className="flex flex-col items-center h-[100vh] justify-between overflow-hidden">
        <div className="my-8"> <img className="object-contain" src={Logo} width={175}/></div>
        <div className="flex flex-col justify-center items-center p-5 ">
          <h1 className="text-[50px] text-[#888] inline-block pr-[12px] text-center leading-[1] animate-type">Verifying...</h1>
          <p className="text-center mt-2">This window will close once process is completed.</p>
        </div>
        <div className="my-[2rem]">
          <p className="text-[13px]">Make a suggestion or contact us at <a className="text-[#8D2CFE] hover:underline" href="mailto:support@sectorsift.com"> support@sectorsift.com</a></p>
        </div>
      </div>
    </>
  );
};

export default ViC95burK4ziV0H4zG1mavuHm;