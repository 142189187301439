import React, { useState,useEffect } from 'react';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { useAxios } from '../../contexts/AxiosContext';
import SkeletonTable from '../../components/tables/SkeletonTable';
import LazyTable from '../../components/tables/LazyTable';
import { InputSwitch } from 'primereact/inputswitch';

const Dashboard: React.FC<any> = ({setLoadingState, axiosService }) => {
  // const axiosService = useAxios();
  const [barGraphData, setBarGraphData] = useState({});
  const [barGraphOptions, setBarGraphOptions] = useState({});
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const colors = ['#64b5f6', '#7986cb', '#4db6ac', '#4dd0e1'];
  const icons = ['pi-users', 'pi-filter', 'pi-user', 'pi-envelope'];
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [cardsData, setCardsData] = useState([
    {
      title: "Agents",
      status: [
        { label: "Pending", value: 0 },
        { label: "Completed", value: 0 }
      ]
    },
    {
      title: "Leads",
      status: [
        { label: "Found", value: 0 },
      ]
    },
    {
      title: "Contacts",
      status: [
        { label: "Found", value: 0 },
      ]
    },
    {
      title: "Email",
      status: [
        { label: "Sent", value: 0 },
        { label: "Delivered", value: 0 }
      ]
    },
  ]);

  const [emailCount, setEmailCount] = useState<any>();

  const initAnalytics = () => {
    const promises = [
      axiosService.api.get('/api/analytics/agent-status'),
      axiosService.api.get('/api/analytics/leads-count'),
      axiosService.api.get('/api/analytics/contacts-count'),
      axiosService.api.get('/api/analytics/emails-count')
    ];

    Promise.all(promises).then((promiseValues:any) => {
      let cardsCopy = JSON.parse(JSON.stringify(cardsData));

      promiseValues[0].data.forEach((leadsStatus:any) => {
        let index = null;

        switch(leadsStatus.status){
          case 'Pending': index = 0;
            break;
          case 'Completed': index = 1
            break;
        }

        if(index != null){
          cardsCopy[0].status[index].value = leadsStatus.count;
        }
      });
      // cardsCopy[0].status[0].value = promiseValues[0].data[1].count;
      // cardsCopy[0].status[1].value = promiseValues[0].data[2].count;

      cardsCopy[1].status[0].value = promiseValues[1].data;
      cardsCopy[2].status[0].value = promiseValues[2].data;
      cardsCopy[3].status[0].value = promiseValues[3].data.total_count;

      setEmailCount(promiseValues[3].data);
      setCardsData(cardsCopy);
    });
  };

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = "#546e7a";
    const surfaceBorder = "#dee2e6";

    const data = {
      labels: emailCount && emailCount.chart_labels,
      datasets: [
        {
          label: 'Sents',
          data: emailCount && emailCount.sents_chart_data.data,
          fill: false,
          borderColor: "#DAB5FF",
          tension: 0.4,
          pointStyle: 'circle',
          pointRadius: 1,
        },
        {
          label: 'Opens',
          data: emailCount && emailCount.opens_chart_data.data,
          fill: false,
          borderColor: "#FB923C",
          tension: 0.4,
          pointStyle: 'circle',
          pointRadius: 1,
        },
        {
          label: 'Replies',
          data: emailCount && emailCount.replies_chart_data.data,
          fill: false,
          borderColor: "#609AF8",
          tension: 0.4,
          pointStyle: 'circle',
          pointRadius: 1,
        }
      ]
    };
    let delayed: boolean;
    const highestCount = Math.max(...data.datasets.map((dataset:any) => dataset.data?.reduce((acc:any, curr:any) => Math.max(acc, curr), 0)));
    const maxY = highestCount + 10;
    const options = {
      animation: {
        onComplete: () => {
          delayed = true;
        },
        delay: (context: any) => {
          let delay = 0;
          if (context.type === 'data' && context.mode === 'default' && !delayed) {
            delay = context.dataIndex * 2 + context.datasetIndex * 100;
          }
          return delay;
        },
      },
      responsive: true, 
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        legend: false
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          suggestedMax: 20,
          grid: {
            color: surfaceBorder
          },
          min: 0,
        }
      }
    };

    setChartData(data);
    setChartOptions(options);
  }, [emailCount]);

  const statusTemplate = (status: string) => {
    return <span className={`${status.toLowerCase() == 'completed' ? 'text-green' : 'text-orange-500'} w-[130px] justify-center bg-lightgray py-1 px-2 rounded-md flex items-center gap-2`}>
      <i className="pi pi-circle-fill"></i>{status}
    </span>
  };

  const listTemplate = (keywords: string[], show_all: boolean) => {
    if (show_all) {
      return (
        <span className="">{keywords.join('; ')}</span>
      );
    } else {
      const displayedKeywords = keywords.slice(0, 4).join('; ');
      return (
        <span className="">
          {displayedKeywords}
          {keywords.length > 4 && '...'} 
        </span>
      );
    }
  }

  const handleDataConvert = (data: any, setTableData: Function) => {
    setTableData(data.map((e:any) => ({
      id: e.id,
      name: e.name,
      company_name: e.company_name,
      website: e.website,
      offers: e.offers,
      description: e.description,
      target_description: e.target_description,
      short_target_keywords: e.target_keywords.slice(0, 4).join('; ') + (e.target_keywords.length > 4 ? '...' : ''),
      target_keywords: e.target_keywords,
      target_countries: e.target_countries,
      status: e.status,
      total_leads_discovered: e.leads_counts_discovered,
      total_leads_processes: e.leads_counts_processes,
      email: e.email,
      phone: e.phone,
      full_name: e.full_name,
      calendly_link: e.calendly_link,
      role: e.role,
      linkedin_url: e.linkedin_url,
      customer_profile: e.customer_profile ?? '---',
      is_active: e.is_active,
      created_at: new Date(e.created_at).toLocaleDateString('en-GB'),
    })));
  };
  

  const activeTemplate = (active: any, rowData:any) => {
    const complementActive = active == 1 ? 0 : 1;
    return <InputSwitch checked={active == 1} 
      onChange={() => {
        axiosService.api.get(`/api/agents/active/${rowData.id}/${complementActive}`).then((retValue:any) => {
          setRefreshTable(true);
        });
      }} />
  };

  const agentsTableColumn = [
    { field: 'name', header: 'Agent Name'},
    { field: 'short_target_keywords', header: 'Target Keywords' },
    { field: 'target_countries', header: 'Target Countries', template: listTemplate, hasTemplate: true },
    { field: 'status', header: 'Status', template: statusTemplate, hasTemplate: true  },
    { field: 'total_leads_discovered', header: 'Total Leads Discovered' },
    { field: 'total_leads_processes', header: 'Total Leads Processes' },
    { field: 'customer_profile', header: 'Customer Profile' },
    { field: 'created_at', header: 'Date' },
    { field: 'is_active', header: 'Active', template: activeTemplate, hasTemplate: true },
  ];

  useEffect(() => {
    initAnalytics();
  }, []);

  return(
  <>
    <div id="dashboard" className="flex flex-col gap-3">
      <div className="title flex justify-between items-center">
        <h1 className="text-2xl">Dashboard</h1>
      </div>

      <div className="panel bg-white rounded-lg p-5 flex flex-col gap-5">

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {cardsData.map((card, index) => (
            <div key={index} className='border rounded-lg p-4 border-lightgray shadow-lg border-[1px]'>
              <div className='flex justify-between mb-3'>
                <div className='w-full'>
                  <span className='text-500 font-medium mb-3' style={{color:colors[index]}}>{card.title}</span>
                  <div className='flex gap-4 w-full justify-center px-2'>
                    {card.status.map((item, idx) => (
                      <div key={idx}>
                        {idx == 1 && (<div className='w-[1px]' style={{background:colors[index]}}></div>)}
                        <div key={idx} className={`flex flex-col items-center`}>
                          <p className="text-4xl text-center"> {item.value}</p>
                          <span className="text-sm min-w-[60px] text-center font-bold" style={{color:colors[index]}}> {item.label}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div className={`flex flex-row items-center justify-center rounded-lg p-3 border-[2px]`} style={{borderColor:colors[index], background:colors[index]+'0d', color:colors[index]}}>
                    <i className={`pi ${icons[index]} text-xl`}></i>
                  </div>
                </div>
               
              </div>
            </div>

          ))}

          <div className='md:col-span-2 lg:col-span-4'>
            <div className='border rounded-lg p-4 border-lightgray shadow-lg border-[1px] flex flex-col gap-5'>
              <div className="flex gap-5 items-center">
                <h1 className="font-bold text-label">Trend Legend</h1>
                <div className="flex items-center gap-2">
                  <div className="h-[20px] w-[20px] bg-purple-highlight rounded-md">&nbsp;</div>
                  <p className="text-label">Sent</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-[20px] w-[20px] bg-orange-400 rounded-md">&nbsp;</div>
                  <p className="text-label">Opens</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-[20px] w-[20px] bg-blue-400 rounded-md">&nbsp;</div>
                  <p className="text-label">Replies</p>
                </div>
              </div>
              <Chart type="line" data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
                    
        <div className='mt-4'>
          <div className='text-xl mb-3'>Pending Agents</div>
          <LazyTable
            api={'/api/agents/paginate'} apiAll={`/api/agents/select-all`} convertData={true} convertDataFunction={handleDataConvert} refreshTable={refreshTable} setRefreshTable={setRefreshTable}
            checkbox={false}
            columns={agentsTableColumn}
            actions={false}
          />
        </div>
      </div>
    </div>
    
  </>
  )
}

export default Dashboard;